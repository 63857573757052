<template>
<customer-page>
  <template v-slot:page-subline>
    {{ $t(`Passwort vergessen`) }}
  </template>
  <template v-slot:page-content>
    <Form ref="form">
      <div class="fw-bold">{{ $t(`Sie haben Ihr Passwort vergessen?`) }}</div>
      <div class="mt-3">{{ $t(`Bitte geben Sie die hinterlegte Kundennummer an`) }}:</div>
      <div class="form-floating">
        <Field type="text" class="form-control" id="floatingInput" placeholder="Kundennummer" v-model="customerNumber" name="customerNumber" :rules="customerNumberValidation" />
        <label for="floatingInput">{{ $t(`Kundennummer`) }}</label>
      </div>
      <ErrorMessage name="customerNumber" />

      <div class="mt-3">{{ $t(`Bitte geben Sie die hinterlegte E-Mail-Adresse an`) }}:</div>
      <div class="form-floating">
        <Field type="email" class="form-control" id="floatingInput2" placeholder="name@domain.de" v-model="emailAddress" name="emailAddress" :rules="isValidEmailAddress" />
        <label for="floatingInput2">{{ $t(`E-Mail-Adresse`) }}</label>
      </div>
      <ErrorMessage name="emailAddress" />

      <button class="w-100 btn btn-sm btn-primary" type="submit" @click="sendPassword" :disabled="isLoading">> {{ $t(`Zugangsdaten zusenden`) }}</button>
    </Form>

    <div class="mt-5">
      <div class="fw-bold mb-4">{{ $t(`Sie haben Ihre Zugangsdaten vergessen?`) }}</div>
      <a class="w-100 btn btn-sm btn-primary" :href="`mailto:vertragsmanagement@eko-punkt.de?subject=${mailToSubject}&body=${mailToBody}`">&gt; {{ $tc('Kontaktieren Sie uns') }}</a>
    </div>
  </template>
</customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import { Form, Field, ErrorMessage } from "vee-validate";
import {validateEmail} from "../../../utils/utils";

export default {
  name: "password-forgotten",
  components: {CustomerPage, Form, Field, ErrorMessage},
  data() {
    return {
      customerNumber: '',
      emailAddress: '',
      isLoading: false
    }
  },
  computed: {
    mailToSubject() {
      return encodeURIComponent(this.$t('EASY-LIZE // Kunden Login // Zugangsdaten vergessen'));
    },
    mailToBody() {
      return encodeURIComponent(this.$t('Um Ihre Kundendaten zuzuordnen benötigen wir bitte Ihre LUCID Nummer. Diese finden Sie im Dashboard nach dem Login in Ihrem LUCID-Konto.\n\nDie Registrierungsnummer von LUCID besteht normalerweise aus einem vorangestellten länderspezifischen Kürzel und einer folgenden 13-stelligen Ziffernfolge (Beispiel: DE1234567890123)\n\n\nViele Grüße, Ihr Team von EASY-LIZE'));
    }
  },
  methods: {
    customerNumberValidation(value) {
      if(value === "") {
        return this.$t("Dieses Feld ist ein Pflichtfeld");
      }
      return true;
    },
    isValidEmailAddress(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      if(!validateEmail(value)) {
        return this.$t('Bitte geben Sie eine gültige E-Mail ein');
      }
      return true;
    },
    sendPassword(e) {
      e.preventDefault();
      this.$refs.form.validate().then((result) => {
        if(!result.valid) {
          return;
        }
        easyLizeApp.apiClient.sendPasswordForgottenMail((result, request) => {
          this.$refs.form.resetForm();
          if(request.status === 404) {
            this.$messenger.pushMessage('danger', this.$t('Wir konnten keinen Kunden zu der Kundennummer und E-Mail-Adresse finden.'));
          } else if(typeof result.errors !== "undefined") {
            this.$messenger.pushShopwareErrors(result.errors);
          } else {
            this.$messenger.pushMessage('success', this.$t('Sie erhalten eine E-Mail mit einem Link zum zurücksetzen Ihres Passwortes. Sollten Sie keine E-Mail erhalten, prüfen Sie Ihren Spam-Ordner'), 'customer_login');
            this.$router.replace({ name: 'customer_login' });
          }
          this.isLoading = false;
        }, this.customerNumber, this.emailAddress);
      });
    }
  }
}
</script>

<style scoped>

</style>