import { createRouter, createWebHistory } from 'vue-router';
import home from "./pages/home";
import impressum from "./pages/static/impressum";
import login from "./pages/customer/login";
import wizard from "./pages/easy-lize/wizard";
import paymentComplete from "./pages/payment/payment-complete";
import registerOptin from "./pages/customer/register-optin";
import contractList from "./pages/contract/contract-list";
import contractView from "./pages/contract/contract-view";
import contractPeriodFinal from "./pages/contract/contract-period-final";
import contractPeriodQuantityAdd from "./pages/contract/contract-period-quantity-add";
import contractPeriodCheckout from "./pages/contract/contract-period-checkout";
import passwordForgotten from "./pages/customer/password-forgotten";
import passwordRecover from "./pages/customer/password-recover";
import contractNew from "./pages/contract/contract-new";
import contractNewCheckout from "./pages/contract/contract-new-checkout";
import customerAccount from "./pages/customer/customer-account";
import customerAccountEmail from "./pages/customer/customer-account-email";
import customerAccountPassword from "./pages/customer/customer-account-password";
import customerAccountEdit from "./pages/customer/customer-account-edit";
import customerMessagesList from "./pages/customer-messages/customer-messages-list";
import customerMessageView from "./pages/customer-messages/customer-message-view";
import customerMessageCreate from "./pages/customer-messages/customer-message-create";
import contractCancel from "./pages/contract/contract-cancel";
import paymentStart from "./pages/payment/payment-start";
import datenschutzhinweise from "./pages/static/datenschutzhinweise";

const routes = [
    { name: 'home', path: '/:whiteLabelKey?', component: home },
    { name: 'easylize_wizard', path: '/:whiteLabelKey?/guide', component: wizard },
    { name: 'static_impressum', path: '/:whiteLabelKey?/impressum', component: impressum },
    //{ name: 'about', path: '/about', component: About },

    //Customer
    { name: 'customer_register_optin', path: '/:whiteLabelKey?/register/optin', component: registerOptin },
    { name: 'customer_login', path: '/:whiteLabelKey?/login', component: login },
    { name: 'customer_login_backoffice', path: '/:whiteLabelKey?/login/:id', component: login, props: { backofficeLogin: true } },
    { name: 'customer_password_forgotten', path: '/:whiteLabelKey?/password', component: passwordForgotten },
    { name: 'customer_password_recover', path: '/:whiteLabelKey?/password/recover', component: passwordRecover },

    { name: 'customer_account', path: '/:whiteLabelKey?/customer', component: customerAccount },
    { name: 'customer_account_edit', path: '/:whiteLabelKey?/customer/edit', component: customerAccountEdit },
    { name: 'customer_account_email', path: '/:whiteLabelKey?/customer/email', component: customerAccountEmail },
    { name: 'customer_account_password', path: '/:whiteLabelKey?/customer/password', component: customerAccountPassword },

    //customer messages
    { name: 'customer_messages_list', path: '/:whiteLabelKey?/messages', component: customerMessagesList },
    { name: 'customer_messages_create', path: '/:whiteLabelKey?/messages/create', component: customerMessageCreate },
    { name: 'customer_message_view', path: '/:whiteLabelKey?/messages/:id', component: customerMessageView },

    //Contracts
    { name: 'contract_new', path: '/:whiteLabelKey?/contract/new', component: contractNew },
    { name: 'contract_new_period', path: '/:whiteLabelKey?/contract/:contractId/:contractYear/new', component: contractNew },
    { name: 'contract_new_checkout', path: '/:whiteLabelKey?/contract/:contractYear/checkout', component: contractNewCheckout },
    { name: 'contract_list', path: '/:whiteLabelKey?/contracts', component: contractList, props: (route) => ({
            ...route.params
        }) },
    { name: 'contract_view', path: '/:whiteLabelKey?/contract/:contractId/:contractYear', component: contractView },
    { name: 'contract_period_final', path: '/:whiteLabelKey?/contract/:contractPeriodId/final', component: contractPeriodFinal },
    { name: 'contract_period_quantity_add', path: '/:whiteLabelKey?/contract/:contractPeriodId/add', component: contractPeriodQuantityAdd },
    { name: 'contract_cancel', path: '/:whiteLabelKey?/contract/:contractPeriodId/cancel', component: contractCancel },

    { name: 'contract_period_checkout', path: '/:whiteLabelKey?/contract/:contractPeriodId/:quantityType/checkout', component: contractPeriodCheckout },

    //Payment
    { name: 'payment_complete', path: '/:whiteLabelKey?/payment/:contractId/:contractPeriodQuantityId/complete', component: paymentComplete },
    { name: 'payment_failed', path: '/:whiteLabelKey?/payment/:contractId/:contractPeriodQuantityId/failed', component: paymentStart, props: { showError: true } },
    { name: 'payment_start', path: '/:whiteLabelKey?/payment/:contractId/:contractPeriodQuantityId/start', component: paymentStart },


    //Static
    //{ name: 'static_agb', path: '/agb', component: agb }
    { name: 'static_datenschutz', path: '/datenschutzhinweise', component: datenschutzhinweise }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

export default router;