<template>
  <Form ref="form">
  <h4 class="my-4">{{ $t(`Unternehmen`) }}</h4>
  <div class="form-group mb-3 row border-top border-lg-0" v-if="showCustomerNumber">
    <label for="customer-company" class="col-md-5 col-form-label">{{ $t(`Kundennummer`) }}</label>
    <div class="col-md-7">
      {{ customer.customerNumber }}
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="customer-company" class="col-md-5 col-form-label">{{ $t(`Firma`) }}</label>
    <div class="col-md-7">
      <Field type="text" name="company" class="form-control form-control-lg company-validation" maxlength="100"
             id="customer-company" v-model="customer.company" :rules="isRequired"/>
      <ErrorMessage name="company" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="lucid-nr3" class="col-md-5 col-form-label">{{ $t(`LUCID Nr.`) }}</label>
    <div class="col-md-7">
      <Field type="text" name="lucidNumber" class="form-control form-control-lg lucid-number-validation" @change="$emit('update:lucidNumber', $event.target.value)"
             id="lucid-nr3" v-model="customer.lucidNumber" :rules="isValidLudicNumber" />
      <ErrorMessage name="lucidNumber" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="umsatzsteuer-id-nr4" class="col-md-5 col-form-label">{{ $t(`Umsatzsteuer ID-Nr.`) }}</label>
    <div class="col-md-7">
      <Field type="text" name="vatId" class="form-control form-control-lg vat-id-validation" maxlength="30"
             id="umsatzsteuer-id-nr4" v-model="customer.vatId" :rules="isValidVatId" />
      <ErrorMessage name="vatId" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="steuer-nr5" class="col-md-5 col-form-label">{{ $t(`Steuer-Nr.`) }}</label>
    <div class="col-md-7">
      <Field type="text" name="taxNumber" class="form-control form-control-lg tax-number-validation" maxlength="30"
             data-msg-required="Die Umsatzsteuer ID-Nr. oder die Steuer-Nr. ist ein Pflichtfeld"
             id="steuer-nr5" v-model="customer.taxNumber" :rules="isValidTaxNumber" />
      <ErrorMessage name="taxNumber" />
    </div>
  </div>
  <hr class="my-4">
  <h4 class="my-4">{{ $t('Kontakt') }}</h4>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="anrede7" class="col-md-5 col-form-label">{{ $t(`Anrede`) }}</label>
    <div class="col-md-7">
      <salutation-select name="salutationId" v-model="customer.salutationId" :rules="isRequired"/>
      <ErrorMessage name="salutationId" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="vorname8" class="col-md-5 col-form-label">{{ $t(`Vorname`) }}</label>
    <div class="col-md-7">
      <Field type="text" name="firstName" class="form-control form-control-lg firstname-validation" maxlength="40"
             id="vorname8" v-model="customer.firstName" :rules="isRequired" />
      <ErrorMessage name="firstName" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="nachname9" class="col-md-5 col-form-label">{{ $t(`Nachname`) }}</label>
    <div class="col-md-7">
      <Field type="text" name="lastName" class="form-control form-control-lg lastname-validation" maxlength="39"
             id="nachname9" v-model="customer.lastName" :rules="isRequired" />
      <ErrorMessage name="lastName" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="e-mail10" class="col-md-5 col-form-label">{{ $t(`E-Mail`) }}</label>
    <div class="col-md-7">
      <Field type="email" name="emailAddress" class="form-control form-control-lg" :disabled="!showEmailAddress2" maxlength="254"
             id="e-mail10" v-model="customer.emailAddress" :rules="isValidEmailAddress" />
      <ErrorMessage name="emailAddress" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0" v-if="showEmailAddress2">
    <label for="e-mail-bestatigen12" class="col-md-5 col-form-label">{{ $t(`E-Mail bestätigen`) }}</label>
    <div class="col-md-7">
      <Field type="email" name="emailAddress2" class="form-control form-control-lg" maxlength="254"
             id="e-mail-bestatigen12" v-model="customer.emailAddress2" :rules="repeatEmailAddress" />
      <ErrorMessage name="emailAddress2" />
    </div>
  </div>
  <div class="form-group mb-3 row border-top border-lg-0">
    <label for="telefon-nr13" class="col-md-5 col-form-label">{{ $t(`Telefon-Nr.`) }}</label>
    <div class="col-md-7">
      <Field type="text" name="phoneNumber" class="form-control form-control-lg" :rules="isRequired" maxlength="30"
             id="telefon-nr13" v-model="customer.phoneNumber" />
      <ErrorMessage name="phoneNumber" />
    </div>
  </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import SalutationSelect from "../salutation-select";
import {validateEmail} from "../../../../utils/utils";
import {VatIdValidator} from "../../../../utils/vatid-validator";

export default {
  name: "checkout-form-company",
  components: { SalutationSelect, Form, Field, ErrorMessage },
  emits: [ 'update:lucidNumber' ],
  props: {
    customer: {
      type: Object,
      required: true
    },
    selectedCompanyCountry: {
      type: [ Object, null ],
      required: true
    },
    showEmailAddress2: {
      type: Boolean,
      required: false,
      default: true
    },
    showCustomerNumber: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
  },
  methods: {
    _getVatIdAttributes(vatId) {
      const country = this.selectedCompanyCountry;
      const vatIdCountry = vatId.trim().substring(0, 2);
      return {
        isEmpty: vatId === '',
        isDeVatId: vatIdCountry.toLowerCase() === 'de',
        isDeCountry: country && country.iso === 'DE',
        isEuMemberState: country ? country.euMemberState : false,
        vatIdCountry: vatIdCountry,
        countryIso: country ? country.iso : '',
        vatIdPattern: country ? (country.vatIdPattern || null) : null
      };
    },
    isRequired(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    isValidEmailAddress(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      if(!validateEmail(value)) {
        return this.$t('Bitte geben Sie eine gültige E-Mail ein');
      }
      return true;
    },
    isValidLudicNumber(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      if(!/^DE(\d{13})$/.test(value)) {
        return this.$t('Ihre LUCID-Nummer hat das falsche Format. Die LUCID-Nummer beginnt mit DE gefolgt von einer 13-stelligen Zahl');
      }
      return true;
    },
    isValidVatId(value) {
      const vatIdAttributes = this._getVatIdAttributes(value);
      if(!vatIdAttributes.isDeCountry && vatIdAttributes.isEuMemberState && this.customer.vatId === "") {
        return this.$t('Die Umsatzsteuer ID-Nr. ist ein Pflichtfeld');
      }
      if(vatIdAttributes.isDeVatId || vatIdAttributes.isEuMemberState && !vatIdAttributes.isDeVatId) {
        const vatIdValidator = new VatIdValidator();
        if(this.customer.vatId !== "" && !vatIdValidator.isValid(this.customer.vatId)) {
          return this.$t('Die Umsatzsteuer ID-Nr. hat ein falsches Format');
        }
      }
      if(vatIdAttributes.isEuMemberState && !vatIdAttributes.isDeCountry && vatIdAttributes.countryIso !== vatIdAttributes.vatIdCountry) {
        return this.$t('Die Umsatzsteuer ID-Nr. passt nicht zum Land des Firmenstandortes');
      }
      if(this.customer.vatId === "" && this.customer.taxNumber === "") {
        return this.$t('Die Umsatzsteuer ID-Nr. oder die Steuer-Nr. ist ein Pflichtfeld');
      }
      return true;
    },
    isValidTaxNumber(value) {
      const vatIdAttributes = this._getVatIdAttributes(value);
      if(!vatIdAttributes.isDeCountry && vatIdAttributes.isEuMemberState) {
        return true;
      }
      if(this.customer.vatId === "" && this.customer.taxNumber === "") {
        return this.$t('Die Umsatzsteuer ID-Nr. oder die Steuer-Nr. ist ein Pflichtfeld');
      }
      return true;
    },
    repeatEmailAddress(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      if(!validateEmail(value)) {
        return this.$t('Bitte geben Sie eine gültige E-Mail ein');
      }
      if(value !== this.customer.emailAddress) {
        return this.$t('Bitte bestätigen Sie Ihre E-Mail');
      }
      return true;
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
}
</script>

<style scoped>

</style>