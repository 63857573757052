<template>
  <slot v-bind="{ product }">
    <tr class="contract-form-item" v-if="product.type == 'material'">
      <td>
        <div class="d-flex flex-row flex-wrap justify-content-between mt-3 mt-lg-0">
          <div class="d-block d-lg-none"><b>{{ $t(`Material`) }}:</b></div>
          <div><b>{{ $t(product.name) }}</b>
          </div>
        </div>
      </td>
      <td v-if="contractPeriodId && isUpdateFinalQuantity">
        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div class="d-block d-lg-none"><b>{{ $t(`Jahresplanmenge in kg`) }}:</b></div>
          <div>
            {{$filters.formatNumber(product.planedAmountWeight, 4)}}
          </div>
        </div>
      </td>
      <td v-else-if="contractPeriodId">
        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div class="d-block d-lg-none"><b>{{ $t(`Jahresmenge in kg`) }}:</b></div>
          <div>
            {{$filters.formatNumber(product.calculatedWeight, 4)}}
          </div>
        </div>
      </td>
      <td v-if="contractPeriodId && isUpdateFinalQuantity">
        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div class="d-block d-lg-none"><b>{{ $t(`Jahresabschlussmenge in kg`) }}:</b></div>
          <div>
            {{$filters.formatNumber(product.finalAmountWeight, 4)}}
          </div>
        </div>
      </td>
      <td>
        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div v-if="contractPeriodId" class="d-block d-lg-none"><b>{{ $t(`neue Jahresmenge in kg`) }}:</b></div>
          <div v-else class="d-block d-lg-none"><b>{{ $t(`Jahresmenge in kg`) }}:</b></div>
          <div>
            <span v-if="viewOnly">{{ $filters.formatNumber(product.inputWeight ?? 0.0, 4) }}</span>
            <weight-input v-if="!viewOnly" v-model="product.inputWeight" @update:modelValue="onWeightInputChanged" :disabled="disabled" class="text-end" :decimal-places="weightInputDecimalPlaces" />
          </div>
        </div>
      </td>
      <td v-if="contractPeriodId">
        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div class="d-block d-lg-none"><b>{{ $t(`erweiterung in kg`) }}:</b></div>
          <div>
            {{$filters.formatNumber(product.inputWeight - product.calculatedWeight, 4)}}
          </div>
        </div>
      </td>
      <td v-if="showKgPrice && showPrices">

        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div class="d-block d-lg-none"><b>{{ $t(`Preis € pro kg`) }}:</b></div>
          <div>
            {{ $filters.formatNumber(product.unitPrice ?? 0.0, 4) }}
          </div>
        </div>

      </td>
      <td v-if="showPrices">
        

        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div class="d-block d-lg-none"><b>{{ $t(`Gesamtpreis`) }} &euro;:</b></div>
          <div>
            {{ $filters.formatNumber(product.unitPrice * product.inputWeight, 4) }}
          </div>
        </div>

      </td>

      <td v-if="contractPeriodId && showPrices">

        <div class="d-flex flex-row flex-wrap justify-content-between justify-content-lg-end">
          <div class="d-block d-lg-none"><b>{{ $t(`noch zu zahlen €`) }}:</b></div>
          <div>
            {{ $filters.formatNumber((product.totalPrice ?? 0.0), 2) }}
          </div>
        </div>

      </td>
    </tr>
  </slot>
</template>

<script>
import WeightInput from "./weight-input";
export default {
  name: "cart-item",
  emits: [ 'weightInputChanged' ],
  components: { WeightInput },
  props: {
    product: {
      type: Object,
      require: true
    },
    contractPeriodId: {
      type: String,
      required: false,
      default: null
    },
    showKgPrice: {
      type: Boolean,
      required: false,
      default: false
    },
    showPrices: {
      type: Boolean,
      required: false,
      default: true
    },
    isUpdateFinalQuantity: {
      type: Boolean,
      required: false,
      default: false
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    weightInputDecimalPlaces: {
      type: Number,
      required: false,
      default: 4
    }
  },
  methods: {
    onWeightInputChanged() {

      this.$emit('weightInputChanged');
    }
  }
}
</script>
