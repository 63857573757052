<template>
<customer-page size="full">
  <template v-slot:page-headline>
    {{ $t('Datenschutzhinweise') }}
  </template>
  <template v-slot:page-content>
    <datenschutzhinweise-de v-if="locale === 'de-DE'" />
    <datenschutzhinweise-en v-if="locale === 'en-GB'" />
  </template>
</customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import config from "../../../config/apiClient";
import DatenschutzhinweiseDe from "./datenschutzhinweise/datenschutzhinweise-de";
import DatenschutzhinweiseEn from "./datenschutzhinweise/datenschutzhinweise-en";


export default {
  mounted() {
    const cookiebotScript = document.createElement("script");
    cookiebotScript.setAttribute('type', 'text/javascript');
    cookiebotScript.setAttribute('id', 'CookieDeclaration');
    cookiebotScript.setAttribute('src', 'https://consent.cookiebot.com/3bd2fb55-8f40-42a3-b230-1dea053ca955/cd.js');
    cookiebotScript.setAttribute('async', '');
    document.getElementById("cookiebotscript").appendChild(cookiebotScript);
  },


  name: "agb",
  components: {DatenschutzhinweiseEn, DatenschutzhinweiseDe, CustomerPage},
  data() {
    return {
      locale: easyLizeApp.locale
    };
  },
  created() {

  }
}
</script>

<style scoped>

</style>