<template>
<customer-page>
  <template v-slot:page-headline>{{ $t(`Ihre Stammdaten`) }}</template>
  <template v-slot:page-content v-if="customer">
    <hr class="my-4">
    <h4 class="my-4">{{ $t(`Unternehmen`) }}</h4>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="customerNumber" class="col-md-5 col-form-label">{{ $t(`Kundennummer`) }}</label>
      <div class="col-md-7">
        {{ customer.customerNumber }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="firma2" class="col-md-5 col-form-label">{{ $t(`Firma`) }}</label>
      <div class="col-md-7">
        {{ customer.company }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="lucid-nr3" class="col-md-5 col-form-label">{{ $t(`LUCID Nr.`) }}</label>
      <div class="col-md-7">
        {{ customer.lucidNumber }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="umsatzsteuer-id-nr4" class="col-md-5 col-form-label">{{ $t(`Umsatzsteuer ID-Nr.`) }}</label>
      <div class="col-md-7">
        {{ customer.vatId }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="steuer-nr5" class="col-md-5 col-form-label">{{ $t(`Steuer-Nr.`) }}</label>
      <div class="col-md-7">
        {{ customer.taxNumber }}
      </div>
    </div>
    <hr class="my-4">
    <h4 class="my-4">{{ $t(`Kontakt`) }}</h4>

    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="anrede7" class="col-md-5 col-form-label">{{ $t(`Anrede`) }}</label>
      <div class="col-md-7">
        {{ customer.salutation.displayName }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="vorname8" class="col-md-5 col-form-label">{{ $t(`Vorname`) }}</label>
      <div class="col-md-7">
        {{ customer.firstName }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="nachname9" class="col-md-5 col-form-label">{{ $t(`Nachname`) }}</label>
      <div class="col-md-7">
        {{ customer.lastName }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="e-mail10" class="col-md-5 col-form-label">{{ $t(`E-Mail`) }}</label>
      <div class="col-md-7">
        {{ customer.emailAddress }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="telefon-nr13" class="col-md-5 col-form-label">{{ $t(`Telefon-Nr.`) }}</label>
      <div class="col-md-7">
        {{ customer.phoneNumber }}
      </div>
    </div>
    <hr class="my-4">
    <h4 class="my-4">{{ $t(`Adresse`) }}</h4>

    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="plz16" class="col-md-5 col-form-label">{{ $t(`PLZ / Ort`) }}</label>
      <div class="col-md-2">
        {{ customer.companyAddress.zipcode }}
      </div>
      <div class="col-md-5">
        {{ customer.companyAddress.city }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="land18" class="col-md-5 col-form-label">{{ $t(`Land`) }}</label>
      <div class="col-md-7">
        {{ customer.companyAddress.country.name }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="strae-hausnr19" class="col-md-5 col-form-label">{{ $t(`Straße Haus-Nr`) }}</label>
      <div class="col-md-7">
        {{ customer.companyAddress.street }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="adresszeile-120" class="col-md-5 col-form-label">{{ $t(`Adresszeile 1`) }}</label>
      <div class="col-md-7">
        {{ customer.companyAddress.additionalAddressLine1 }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="adresszeile-221" class="col-md-5 col-form-label">{{ $t(`Adresszeile 2`) }}</label>
      <div class="col-md-7">
        {{ customer.companyAddress.additionalAddressLine2 }}
      </div>
    </div>
    <hr class="my-4">
    <h4 class="my-4">{{ $t(`Rechnungsadresse`) }}</h4>

    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="firma24" class="col-md-5 col-form-label">{{ $t(`Firma`) }}</label>
      <div class="col-md-7">
        {{ customer.billingAddress.company }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="plz25" class="col-md-5 col-form-label">{{ $t(`PLZ / Ort`) }}</label>
      <div class="col-md-2">
        {{ customer.billingAddress.zipcode }}
      </div>
      <div class="col-md-5">
        {{ customer.billingAddress.city }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="land27" class="col-md-5 col-form-label">{{ $t(`Land`) }}</label>
      <div class="col-md-7">
        {{ customer.billingAddress.country.name }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="strae-hausnr28" class="col-md-5 col-form-label">{{ $t(`Straße Haus-Nr`) }}</label>
      <div class="col-md-7">
        {{ customer.billingAddress.street }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="adresszeile-129" class="col-md-5 col-form-label">{{ $t(`Adresszeile 1`) }}</label>
      <div class="col-md-7">
        {{ customer.billingAddress.additionalAddressLine1 }}
      </div>
    </div>
    <div class="form-group mb-3 row border-top border-lg-0">
      <label for="adresszeile-230" class="col-md-5 col-form-label">{{ $t(`Adresszeile 2`) }}</label>
      <div class="col-md-7">
        {{ customer.billingAddress.additionalAddressLine2 }}
      </div>
    </div>
    <hr class="my-4">
    <div class="w-100 d-flex justify-content-between pt-5 flex-wrap">
      <a :href="$getUrl($route, '/customer/email')" class="btn btn-primary mb-2">&gt; {{ $t(`E-Mail ändern`) }}</a>
      <a :href="$getUrl($route, '/customer/password')" class="btn btn-primary mb-2">&gt; {{ $t(`Passwort ändern`) }}</a>
      <a :href="$getUrl($route, '/customer/edit')" class="btn btn-primary mb-2">&gt; {{ $t(`Stammdaten ändern`) }}</a>
    </div>
  </template>
</customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
export default {
  name: "customer-account",
  components: {CustomerPage},
  data() {
    return {
      isLoading: false,
      customer: null
    };
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContext((context) => {
      this.customer = context.customer;
      this.isLoading = false;
    });
  }
}
</script>

<style scoped>

</style>