<template>
  <Form ref="form">
    <div class="card card-full mt-5 mb-5 response">
      <div class="card-body">
        <div class="mb-3">
          <Field type="text" class="form-control" name="subject" placeholder="Betreff" v-model="subject" :rules="isRequired" />
          <ErrorMessage name="subject" />
        </div>
        <div class="mb-3">
          <Field as="textarea" class="form-control" placeholder="Nachricht schreiben" rows="8" cols="60" name="message" v-model="message" :rules="isRequired"></Field>
          <ErrorMessage name="message" />
        </div>
      </div>
      <div class="card-footer">
        <div class="footer-wrapper d-flex justify-content-end">
          <button class="btn btn-primary" @click="onCreateMessage" :disabled="isCreatingMessage">> {{ $t(`absenden`) }}</button>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "customer-message-create-form",
  emits: [ 'message:created' ],
  components: { Form, Field, ErrorMessage },
  props: {
    replyMessage: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isCreatingMessage: false,
      subject: '',
      message: ''
    };
  },
  created() {
    if(this.replyMessage) {
      this.subject = 'Re: ' + this.replyMessage.subject;
    }
  },
  methods: {
    isRequired(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    onCreateMessage(e) {
      e.preventDefault();
      this.$refs.form.validate().then((result) => {
        if(!result.valid) {
          return;
        }
        this.isCreatingMessage = true;
        if(this.replyMessage) {
          easyLizeApp.apiClient.replyMessage(() => {
            this.subject = 'Re: ' + this.subject;
            this.message = '';
            this.$emit('message:created');
            this.isCreatingMessage = false;
          }, this.replyMessage.id, this.subject, this.message)
        } else {
          easyLizeApp.apiClient.sendMessage(() => {
            this.$refs.form.resetForm();
            this.$emit('message:created');
            this.isCreatingMessage = false;
          }, this.subject, this.message)
        }
      });
    }
  }
}
</script>

<style scoped>

</style>