<template>

  <div class="bg-red-circle">
    <div class="circle"></div>
  </div> 


  <section id="step-1" class="easy-step step-1" data-step="1">

    <div class="container-fluid container-xxl p-0">
      <div class="row g-0 position-relative">
        <div class="col-center">
<!--
          <div class="bg-red-circle">
            <div class="circle"></div>
          </div>
-->          
          <div class="col-12 easy-container omg-layout">

            <div class="row">
              <div class="col-12">
                <div class="section-header mt-3 mt-lg-5 text-center">
                  <h1 class="display-3">{{ $t("Auswahl Verpackungssortiment") }}</h1>
                  <h2>{{ $tc('Welche Verpackungen sollen lizenziert werden – um welche Produkte geht es?') }}</h2>
                </div>
              </div>
              <div class="col-12">
                <form id="form-bereiche" class="form mt-3 w-100">
                  <div class="d-flex mt-lg-5 flex-wrap justify-content-center">
                    <div class="section-area mb-5 me-5" v-for="(section, s) in branchen">
                      <h3 class="mb-4">{{ getBrancheLabel(section.label) }}</h3>
                      <div class="form-check" v-for="(product, p) in section.branchen">
                        <input class="form-check-input" type="checkbox" :id="'section-' + s + '-product-' + p" v-model="wizard.data.branchen[p]" value="true">
                        <label class="form-check-label" :for="'section-' + s + '-product-' + p">
                          {{ getBrancheLabel(product.label) }}
                        </label>
                      </div>
                    </div>
                  </div>

                </form>
              
              </div>
            </div>
          </div>

          <div class="step-nav mt-5">
            <!--<router-link to="/" class="btn btn-primary me-4 demo-only">&lt; Zurück</router-link>-->
            <button id="auswahl-select-yes" class="btn btn-primary demo-only" v-if="hasSelectedOptions" @click="wizard.showStep('step2')">&gt; {{ $t(`Auswahl übernehmen und weiter`) }}</button>
            <button id="auswahl-select-no" class="btn btn-primary demo-only" v-if="!hasSelectedOptions" @click="wizard.showStep('step2')">&gt; {{ $t(`Auswahl überspringen`) }}</button>
          </div>

        </div><!-- /col-center -->

        <wizard-circle-bar :show-calculation-help="showCalculationHelp" />
      </div>
    </div>
  </section>



</template>

<script>
import 'bootstrap/js/src/collapse';
import WizardCircleBar from "./wizard-circle-bar";
import branchen from "../../../config/branchen";
import { scrollTop } from '../../../utils/utils';
export default {
  name: "wizard-step1",
  components: {WizardCircleBar},
  props: {
    wizard: {
      type: Object,
      required: true
    },
    showCalculationHelp: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  created() {
    scrollTop();
  },
  computed: {
    branchen() {
      return branchen;
    },
    hasSelectedOptions() {
      if(typeof this.wizard.data.branchen === 'undefined') {
        return false;
      }
      for(let b in this.wizard.data.branchen) {
        const branche = this.wizard.data.branchen[b];
        if(branche) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    getBrancheLabel(translations) {
      return translations[easyLizeApp.locale] || translations['de-DE'];
    }
  }
}
</script>
