<template>
  <CustomerPage :show-messages="false">
    <template v-slot:page-headline>{{ $t(`Login Kunden`) }}</template>
    <template v-slot:page-subline><h2>{{ $t(`Alles auf einen Blick – im EASY-LIZE Kundenkonto`) }}</h2></template>
    <template v-slot:page-content>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3>{{ $t(`Anmeldung`) }}</h3>
        <p class="lead">{{ $t(`Sie sind bereits registrierter Kunde?`) }}<br>{{ $t(`Bitte melden Sie sich hier an.`) }}</p>
      </div>
      <messages-container />
      <div class="form-signin">
        <Form ref="form">
          <div class="form-floating">
            <Field type="text" class="form-control" id="floatingInput" name="login" placeholder="Kundennummer" v-model.trim="customerNumber" :rules="customerNumberRequired" />
            <ErrorMessage name="login" />
            <label for="floatingInput">{{ $t(`Kundennummer`) }}</label>
          </div>
          <div class="form-floating">
            <Field type="password" class="form-control" id="floatingPassword" name="password" placeholder="Passwort" v-model.trim="password" :rules="passwordRequired" />
            <ErrorMessage name="password" />
            <label for="floatingPassword">{{ $t(`Passwort`) }}</label>
          </div>
          <button class="w-100 btn btn-lg btn-primary" :disabled="isLoading" @click="onLogin">> {{ $t(`Anmelden`) }}</button>
        </Form>
        <a :href="$getUrl($route, '/password')" class="mt-3 d-block w-100">{{ $t(`Sie haben Ihre Zugangsdaten vergessen?`) }}</a>
      </div>
    </template>
  </CustomerPage>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import CustomerPage from "../customer-page";
import MessagesContainer from "../../component/utils/messages-container";
import LoaderOverlay from "../../component/utils/loader-overlay";
import {useHead} from "@vueuse/head";

export default {
  components: {LoaderOverlay, MessagesContainer, CustomerPage, Form, Field, ErrorMessage },
  data() {
    return {
      isLoading: false,
      customerNumber: '',
      password: ''
    };
  },
  props: {
    backofficeLogin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  beforeCreate() {
    useHead({
      title: this.$t('Kundenportal | EASY-LIZE Verpackungslizenzierung'),
      meta: [
        { name: 'description', content: this.$t('In Ihrem EASY-LIZE Kundenkonto Daten und Mengen einsehen und anpassen – rechtskonforme Lizenzierungspflicht nach VerpackG leicht gemacht.') },
      ],
    });
  },
  created() {
    if(this.backofficeLogin) {
      this.isLoading = true;
      easyLizeApp.apiClient.backofficeLogin((result, request) => {
        if (request.status === 401) {
          this.$messenger.pushMessage('danger', this.$t('Es konnte kein Kunde mit den angegebenen Zugangsdaten gefunden werden.'));
        } else if (typeof result.errors !== "undefined") {
          this.$messenger.pushShopwareErrors(result.errors);
        } else {
          window.location.href = this.$getUrl(this.$route, "/");
        }
        this.isLoading = false;
      }, this.$route.params.id, this.$route.query.token);
    }
  },
  methods: {
    customerNumberRequired() {
      if(this.customerNumber === '') {
        return this.$t('Dieses Feld ist ein Pflichtfeld.');
      }
      return true;
    },
    passwordRequired() {
      if(this.password === '') {
        return this.$t('Dieses Feld ist ein Pflichtfeld.');
      }
      return true;
    },
    onLogin(e) {
      e.preventDefault();
      this.isLoading = true;
      this.$refs.form.validate().then((result) => {
        if (!result.valid) {
          this.isLoading = false;
          return;
        }
        easyLizeApp.apiClient.login((result, request) => {
          this.$refs.form.resetForm();
          if(request.status === 401) {
            this.$messenger.pushMessage('danger', this.$t('Es konnte kein Kunde mit den angegebenen Zugangsdaten gefunden werden.'));
          } else if(typeof result.errors !== "undefined") {
            this.$messenger.pushShopwareErrors(result.errors);
          } else {
            window.location.href = this.$getUrl(this.$route, "/");
          }
          this.isLoading = false;
        }, this.customerNumber, this.password);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
</style>