<template>
<div class="cart">
  <slot name="cart-modal" v-bind="{ products, params, isLoading, isRecalculating, cart, calculateCart, minimumValueItem, isZeroInput }">

  </slot>
  <slot name="cart-header" v-bind="{ products, params, isLoading, isRecalculating, cart, calculateCart, minimumValueItem }">

  </slot>
  <slot v-bind="{ products, params, isLoading, isRecalculating, cart, calculateCart, minimumValueItem, getTotalItemsNetPrice, isZeroInput }">
    <table class="table contract-items-table easy-table">
      <thead>
      <tr>
        <th>{{ $t(`Material`) }}</th>
        <th v-if="isUpdateFinalQuantity">{{ $t(`Jahresplanmenge in kg`) }}</th>
        <th v-else>{{ $t(`Jahresmenge in kg`) }}</th>

        <th v-if="isUpdateFinalQuantity">{{ $t(`Jahresabschlussmenge in kg`) }}</th>

        <th v-if="contractPeriodId">{{ $t(`neue Jahresmenge in kg`) }}</th>

        <th v-if="contractPeriodId">{{ $t(`erweiterung in kg`) }}</th>
        <th v-if="_showKgPrice && showPrices">{{ $t(`Preis € pro kg`) }}</th>
        <th v-if="showPrices">{{ $t(`Gesamtpreis`) }} &euro;</th>
        <th v-if="contractPeriodId && showPrices">{{ $t(`noch zu zahlen €`) }}</th>
      </tr>
      </thead>
      <tbody v-if="products && !isLoading">
      <template v-for="product in products" v-bind="{showPosition}">
        <cart-item :product="product" @weightInputChanged="onWeightInputChanged" :contractPeriodId="contractPeriodId" :showKgPrice="_showKgPrice" :showPrices="showPrices" :view-only="viewOnly" :is-update-final-quantity="isUpdateFinalQuantity" :weight-input-decimal-places="params.weightInputDecimalPlaces || 4" />
      </template>
      </tbody>
      <tfoot class="fw-bold" v-if="cart && !isLoading">
      <tr v-if="promotionItems && showPrices" v-for="promotionItem in promotionItems">
        <td :colspan="cartSumColspan" class="text-end">{{ $t(`Gutscheincode`) }}: {{ promotionItem.label }}<br/></td>
        <td class="pe-3 text-end">- &euro; {{ $filters.formatNumber(promotionItem.price.totalPrice || 0.0) }} (<a href="#" class="text-danger" @click="_removeVoucherItem($event, promotionItem.id)">{{ $t(`entfernen`) }}</a>)</td>
      </tr>
      <!--tr v-if="minimumValueItem">
        <td :colspan="minimumValueColpan" class="text-end d-none d-lg-table-cell">{{ minimumValueItem.name }}</td>
        <td v-if="contractPeriodId" class="pe-3 text-end"><span class="d-inline-block d-lg-none">{{ minimumValueItem.name }}: &nbsp;</span>&euro; {{ $filters.formatNumber((minimumValueItem.totalPrice ?? 0.0) + (minimumValueItem.calculatedPrice ?? 0.0), 2) }}</td>
        <td v-if="contractPeriodId" class="pe-3 text-end"><span class="d-inline-block d-lg-none">{{ $t(`bereits beauftragt`) }}: &nbsp;</span>&euro; {{ $filters.formatNumber(minimumValueItem.calculatedPrice ?? 0.0, 2) }}</td>
        <td class="pe-3 text-end"><span class="d-inline-block d-lg-none">{{ $t(`noch zu zahlen`) }}: &nbsp;</span>&euro; {{ $filters.formatNumber(minimumValueItem.totalPrice || 0.0) }}</td>
      </tr-->
      <tr v-if="showPrices">
        <td :colspan="cartSumColspan" class="text-end d-none d-lg-table-cell">{{ $t(`Nettopreis`) }}</td>
        <td class="pe-3 text-end"><span class="d-inline-block d-lg-none">{{ $t(`Nettopreis`) }}: &nbsp;</span>&euro; {{ $filters.formatNumber(cart.price.netPrice || 0.0) }}</td>
      </tr>
      </tfoot>
    </table>
    <div class="cart-loading-placeholder" v-if="isLoading">
      <p class="placeholder-glow" v-for="index in 9">
        <span class="placeholder col-12"></span>
      </p>
    </div>
  </slot>
  <slot name="cart-footer" v-bind="{ products, params, isLoading, isRecalculating, cart, calculateCart, minimumValueItem }">

  </slot>
</div>
</template>

<script>
import LoaderOverlay from "../utils/loader-overlay";
import NumberParser from "../../../utils/number-parser";
import CartItem from "./cart-item";
import Co2BilanzCalculator from "../../../utils/products/co2BilanzCalculator";

export default {
  name: "cart",
  emits: [ 'cart:recalculated', 'cart:loaded', 'cart:changed' ],
  components: {CartItem, LoaderOverlay},
  props: {
    contractYear: {
      type: Number,
      required: false,
      default: null
    },
    contractPeriodId: {
      type: String,
      required: false,
      default: null
    },
    isUpdateFinalQuantity: {
      type: Boolean,
      required: false,
      default: false
    },
    quantityType: {
      type: String,
      required: false,
      default: 'new',
      validator(val) {
        return val === 'new' || val === 'add' || val === 'final';
      }
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    showKgPrice: {
      type: Boolean,
      required: false,
      default: false
    },
    clearCart: {
      type: Boolean,
      required: false,
      default: false
    },
    recalculateOnLoad: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    contractYear(oldContractYear, newContractYear) {
      if(newContractYear && newContractYear != oldContractYear) {
        this.calculateCart();
      }
    }
  },
  data() {
    return {
      _calculateCartRequest: null,
      isLoading: false,
      isRecalculating: false,
      _showKgPrice: false,
      products: null,
      params: {},
      valid: true,
      cart: null
    }
  },
  created() {
    this.loadCart();
  },
  computed: {
    minimumValueItem() {
      if(this.products) {
        for(let productId in this.products) {
          if(this.products[productId].type === 'minimumValue' && this.products[productId].totalPrice !== 0) {
            return this.products[productId];
          }
        }
      }
      return null;
    },
    promotionItems() {
      if(!this.cart) {
        return [];
      }
      return this.cart.lineItems.filter((item) => {
        return item.type === 'promotion';
      });
    },
    cartSumColspan() {
      let colspan = 2;
      if(this.contractPeriodId) {
        colspan+= 3;
      }
      if(this._showKgPrice) {
        colspan+= 1;
      }
      if(this.isUpdateFinalQuantity) {
        colspan+= 1;
      }
      return colspan;
    },
    minimumValueColpan() {
      let colspan = 2;
      if(this.contractPeriodId) {
        colspan+= 1;
      }
      if(this._showKgPrice) {
        colspan+= 1;
      }
      return colspan;
    },
    showPrices() {
      return true;
      if(this.quantityType !== 'add') {
        return true;
      }
      if(!this.params) {
        return false;
      }
      if(!('addPeriodQuantityDisableInvoice' in this.params)) {
        return false;
      }
      return this.params.addPeriodQuantityDisableInvoice !== true;
    }
  },
  methods: {
    _loadProducts(callback) {
      window.easyLizeApp.apiClient.getProducts(callback, this.contractYear, this.contractPeriodId, this.quantityType);
    },
    _loadContractYearParams(callback) {
      easyLizeApp.apiClient.getContractYearParams(callback, this.contractYear, this.contractPeriodId);
    },
    _removeVoucherItem(e, itemId) {
      e.preventDefault();
      this.removeVoucher(itemId);
    },
    _updateProducts() {
      const productInputWeights = {};
      const productTotalPrices = {};
      const productUnitPrices = {};
      for(let i in this.cart.lineItems) {
        const lineItem = this.cart.lineItems[i];
        if(lineItem.type == 'promotion') {
          continue;
        }
        if(typeof productInputWeights[lineItem.referencedId] === "undefined") {
          productInputWeights[lineItem.referencedId] = 0;
        }
        if(typeof productTotalPrices[lineItem.referencedId] === "undefined") {
          productTotalPrices[lineItem.referencedId] = 0;
        }
        if(typeof productUnitPrices[lineItem.referencedId] === "undefined") {
          productUnitPrices[lineItem.referencedId] = 0;
        }
        if(lineItem.extensions['cart-item-weight'] && !lineItem.extensions['penalty-line-item']) {
          productInputWeights[lineItem.referencedId]+= lineItem.extensions['cart-item-weight'].weight || 0.0;
        }
        if(lineItem.extensions['rounded-cart-item-price']) {
          productTotalPrices[lineItem.referencedId]+= lineItem.extensions['rounded-cart-item-price'].roundedPrice;
        } else {
          productTotalPrices[lineItem.referencedId]+= lineItem.price.totalPrice;
        }
        productUnitPrices[lineItem.referencedId]+= lineItem.price.unitPrice;
      }
      for(let i in this.cart.lineItems) {
        const lineItem = this.cart.lineItems[i];
        const product = this.products[lineItem.referencedId] || null;
        if(!product) {
          continue;
        }
        //if(this.viewOnly) {
          //product.inputWeight = productInputWeights[lineItem.referencedId] || 0.0;
        //}
        product.totalPrice = productTotalPrices[lineItem.referencedId] || 0.0;
        product.totalPrice = productTotalPrices[lineItem.referencedId] || 0.0;
        product.unitPrice = productUnitPrices[lineItem.referencedId] || 0.0;
      }
    },
    _clearCart(callback) {
      easyLizeApp.apiClient.clearCart(callback);
    },

    onWeightInputChanged() {
      this.isRecalculating = true;
      this.calculateCart((cart) => {
        this.isRecalculating = false;
      });
    },

    isEmpty() {
      if(!this.cart) {
        return true;
      }
      return this.cart.lineItems.filter((item) => {
        return item.type === 'product';
      }).length === 0;
    },

    isZeroInput() {
      return Object.values(this.products).filter((product) => product.inputWeight > 0).length === 0;
    },

    getCart() {
      return this.cart;
    },

    isMaxOrderVolumeReached() {
      return new Promise((resolve, reject) => {
        easyLizeApp.apiClient.getContext((context) => {
          if(context?.customer?.active) {
            resolve(false);
            return;
          }
          this.calculateCart(() => {
            const maxOrderVolume = parseFloat(this.getParams().maxOrderVolume);
            resolve(maxOrderVolume > 0 && this.cart?.price?.netPrice && this.cart.price.netPrice >= maxOrderVolume);
          });
        });
      });
    },

    loadCart() {
      this.isLoading = true;
      const getCart = () => {
        easyLizeApp.apiClient.getCart((cart) => {
          this.cart = cart;
          this._updateProducts();
          this.$emit('cart:loaded', cart, this.products);
          this.$emit('cart:changed', { cart, cartComponent: this });
          if(this.recalculateOnLoad) {
            this.calculateCart();
          }
          this.isLoading = false;
        });
      };
      const loadCart = () => {
        this._loadContractYearParams((params) => {
          this.params = params;
          this._showKgPrice = params.addPeriodQuantityShowUnitPrice || this.showKgPrice;
          if(this.products) {
            getCart();
          } else {
            this._loadProducts((products) => {
              this.products = products;
              getCart();
            });
          }
        });
      };
      if(this.clearCart) {
        this._clearCart(() => {
          loadCart();
        });
      } else {
        loadCart();
      }
    },

    calculateCart(callback = null) {
      const productQuantities = [];
      for(let productId in this.products) {
        if(this.products[productId].type == 'minimumValue') {
          continue;
        }
        productQuantities.push({
          productId: productId,
          inputWeight: this.products[productId].inputWeight ?? 0.0
        });
      }
      this.isRecalculating = true;
      const recalculateCart = (callback) => {
        if(this._calculateCartRequest) {
          this._calculateCartRequest.abort();
          this._calculateCartRequest = null;
        }
        this._calculateCartRequest = easyLizeApp.apiClient.calculateCart((cart, request) => {
          this._calculateCartRequest = null;
          this.cart = cart;
          this._updateProducts();
          this.$emit('cart:recalculated', { cart, products: this.products });
          this.$emit('cart:changed', { cart, cartComponent: this });
          this.isRecalculating = false;
          if(typeof callback === 'function') {
            callback(cart, request);
          }
        }, productQuantities, this.contractPeriodId, this.contractYear, this.quantityType );
      };
      recalculateCart(() => {
        easyLizeApp.apiClient.updateCustomerGroup((result) => {
          if(result.changed) {
            recalculateCart(() => {});
          } else {
            if(typeof callback === 'function') {
              callback(this.cart);
            }
          }
        });
      });
    },

    applyVoucher(voucherCode, changeCustomerGroup = true) {
      this.isRecalculating = true;
      return new Promise((resolve) => {
        const applyCartVoucher = () => {
          easyLizeApp.apiClient.applyCartVoucher((result) => {
            this.cart = result.cart;
            this.isRecalculating = false;
            resolve(result);
          }, voucherCode);
        };
        if(changeCustomerGroup) {
          easyLizeApp.apiClient.changeCustomerGroup((result) => {
            if(result.success) {
              this.loadCart();
              /*easyLizeApp.apiClient.recalculateCart((cart) => {
                this.cart = cart;
                this._updateProducts();
                this.$emit('cart:recalculated', cart, this.products);
                this.$emit('cart:changed', cart, this);
                this.$emit('cart:customer-group-changed', result.context, cart, this);
                this.isRecalculating = false;
              });*/
              resolve({ success: true });
            } else if(!result.success && result.error) {
              this.valid = false;
            } else {
              applyCartVoucher();
            }
          }, voucherCode, this.contractYear);
        } else {
          applyCartVoucher()
        }
      });
    },

    removeVoucher(itemId) {
      this.isRecalculating = true;
      return new Promise((resolve, reject) => {
        easyLizeApp.apiClient.removeCartVoucher((cart) => {
          this.isRecalculating = false;
          this.cart = cart;
          resolve(this.cart);
        }, itemId);
      });
    },

    getVoucherItem() {
      return this.promotionItems;
    },

    getTotalItemsNetPrice() {
      let totalItemsPrice = 0.0;
      for(let i in this.cart.lineItems) {
        const lineItem = this.cart.lineItems[i];
        if(lineItem.type === 'promotion') {
          continue;
        }
        totalItemsPrice+= lineItem.price.totalPrice;
      }
      return totalItemsPrice;
    },

    getParams() {
      return this.params;
    },

    calculateCo2Bilanz() {
      const co2BilanzCalculator = new Co2BilanzCalculator();
      return co2BilanzCalculator.calculateFromProducts(this.products);
    },

    getInputWeights() {
      const inputWeights = [];
      for(let p in this.products) {
        const product = this.products[p];
        if(product.type !== 'material')  {
          continue;
        }
        inputWeights.push({
          productId: product.id,
          inputWeight: product.inputWeight || 0
        });
      }
      return inputWeights;
    },

    isValid() {
      return this.valid;
    }
  }
}
</script>
