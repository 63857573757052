<template>
  <modal :auto-open="true" :show-close-btn="false" class="modal-lg zero-quantity-input-modal" ref="modal">
    <template #content>
      <div class="alert alert-warning" role="alert">
        <p>{{ $t(`Ihr Warenkorb ist leer – sind Sie sicher, dass Sie mit einer Nullmeldung fortfahren möchten?`) }}</p>

        <p v-if="params.finalPeriodQuantityCalculateCredit">{{ $t(`Im Fall einer Nullmeldung wird Ihnen der aktuell gültige Mindestumsatz in Rechnung gestellt und keine Mengen beteiligt und somit auch keine Mengen an LUCID übermittelt.`) }}</p>
        <p v-else>{{ $t('Im Fall einer Nullmeldung werden keine Mengen beteiligt und somit auch keine Mengen an LUCID übermittelt. Laut den gültigen AGB werden Mengenreduzierungen nicht gutgeschrieben, daher erfolgt keine Stornorechnung bzw. keine Gutschrift.') }}</p>
      </div>
    </template>
    <template #footer>
      <div class="w-100 d-flex justify-content-between">
        <button type="button" class="btn btn-primary" @click="onBackBtnClicked">&lt; {{ $t('zurück zur Mengeneingabe') }}</button>
        <button type="button" class="btn btn-primary" @click="onSubmitZeroQuantityInputClicked">
          <slot name="submit-btn-text">&gt; {{ $t(`Nullmeldung abschließen`) }}</slot>
        </button>
      </div>
    </template>
  </modal>
</template>
<script>
import Modal from "./modal.vue";
export default {
  name: 'zero-quantity-input-modal',
  components: { Modal },
  emits: [ 'submit-zero-quantity-input', 'back-btn-clicked' ],

  props: {
    params: {
      type: [ Object, null ],
      required: false,
      default: null
    }
  },

  methods: {
    onBackBtnClicked() {
      this.$refs.modal.hide();
      this.$emit('back-btn-clicked');
    },
    onSubmitZeroQuantityInputClicked() {
      this.$refs.modal.hide();
      this.$emit('submit-zero-quantity-input');
    }
  }
}
</script>