<template>
  <div v-if="isLoading" class="loader-overlay">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader-overlay",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
