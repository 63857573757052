<template>
  <customer-page>
    <template v-slot:page-headline>{{ $t(`Ihr Passwort ändern`) }}</template>
    <template v-slot:page-content>
      <password-control ref="passwordControl" :password-change="true" />
      <hr>
      <div class="w-100 d-flex justify-content-between pt-5">
        <div><a class="btn btn-primary btn-back" :href="$getUrl($route, '/customer')">&lt; {{ $t(`zurück`) }}</a></div>
        <button class="btn btn-primary" @click="onSavePassword" :disabled="isSavingPassword">> {{ $t(`Passwort speichern`) }}</button>
      </div>
    </template>
  </customer-page>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import CustomerPage from "../customer-page";
import PasswordControl from "../../component/form/password-form";
export default {
  name: "customer-account-password",
  components: {PasswordControl, CustomerPage, Form, Field, ErrorMessage },
  data() {
    return {
      isSavingPassword: false
    };
  },
  methods: {
    onSavePassword(e) {
      e.preventDefault();
      this.$refs.passwordControl.validate().then((result) => {
        this.isSavingPassword = true;
        easyLizeApp.apiClient.updateCustomerPassword((result, request, success) => {
          this.$refs.passwordControl.reset();
          if(success) {
            this.$messenger.pushMessage('success', this.$t('Ihr Passwort wurde gespeichert'));
          }
          this.isSavingPassword = false;
        }, result.oldPassword, result.password);
      });
    }
  }
}
</script>

<style scoped>

</style>