<template>
  <cleave ref="cleaveInput" v-model="value" :options="options" @click="onFocusIn" @keyup="onKeyUp" @focusout="updateValue" />
  <!--input
      type="tel"
      ref="autoNumericRef"
      @keyup="onChange"
      @focusout="onFocusOut"
  /-->
</template>

<script>
import AutoNumeric from 'autonumeric/dist/autoNumeric.min';
import Cleave from 'vue-cleave-component';

export default {
  name: "weight-input",
  components: { Cleave },
  emits: [ 'update:modelValue' ],
  props: {
    modelValue: { required: true, default: 0.0 },
    decimalPlaces: {
      type: Number,
      default: 4
    }
  },

  computed: {
    decimalCharacter() {
      return easyLizeApp.locale == 'de-DE' ? ',' : '.';
    },
    digitGroupSeparator() {
      return easyLizeApp.locale == 'de-DE' ? '.' : ',';
    },
    options() {
      return {
        numeral: true,
        numeralDecimalMark: this.decimalCharacter,
        delimiter: this.digitGroupSeparator,
        numeralDecimalScale: this.decimalPlaces,
        numeralIntegerScale: 25,
        numeralPositiveOnly: true,
        stripLeadingZeroes: true,
        rawValueTrimPrefix: true,
        numeralThousandsGroupStyle: 'thousand'
      }
    }
  },

  data: () => ({
    value: 0.0,
    useTimer: true,
    changeTimerTimeout: 2500,
    changeTimer: null
  }),

  created() {
    this.value = this.modelValue.toLocaleString(easyLizeApp.locale, { minimumFractionDigits: this.decimalPlaces });
  },

  mounted() {
    if(this.$refs?.cleaveInput?.cleave?.element) {
      this.$refs.cleaveInput.cleave.element.type = 'tel';
    }
  },

  methods: {
    updateValue() {
      if(this.value == '') {
        this.value = 0.0;
      }
      this.$emit('update:modelValue', this.value);
    },
    onKeyUp () {
      if(this.useTimer) {
        if (this.changeTimer) {
          clearTimeout(this.changeTimer);
        }
        this.changeTimer = setTimeout(() => {
          this.updateValue();
          this.changeTimer = null;
        }, this.changeTimerTimeout);
      }
    },
    onFocusIn() {
      if(this.$refs?.cleaveInput?.cleave?.element) {
        this.$refs.cleaveInput.cleave.element.select();
      }
    }
  }

  /*watch: {
    modelValue () {
      this.updateValue()
    },
    decimalPlaces() {
      this._createAutoNumeric();
    }
  },

  data: () => ({
    anElement: null,
    useTimer: true,
    changeTimerTimeout: 2500,
    changeTimer: null
  }),

  mounted() {
    this._createAutoNumeric();
  },
  methods: {
    _createAutoNumeric() {
      let decimalCharacter = '.';
      let digitGroupSeparator = ',';
      if(easyLizeApp.locale == 'de-DE') {
        decimalCharacter = ',';
        digitGroupSeparator = '.';
      }
      this.anElement = new AutoNumeric(this.$el, {
        leadingZero: 'deny',
        decimalCharacter: decimalCharacter,
        digitGroupSeparator: digitGroupSeparator,
        decimalPlaces: this.decimalPlaces,
        modifyValueOnWheel: false,
        unformatOnHover: false,
        minimumValue: 0
      });
      this.updateValue();
    },
    _updateValue() {
      const value = this.anElement.rawValue !== '' ? this.anElement.getNumber() : null
      this.$emit('update:modelValue', value);
    },
    onChange () {
      if (!this.anElement) return
      if(this.useTimer) {
        if (this.changeTimer) {
          clearTimeout(this.changeTimer);
        }
        this.changeTimer = setTimeout(() => {
          this._updateValue();
          this.changeTimer = null;
        }, this.changeTimerTimeout);
      }
    },
    onFocusOut() {
      if (!this.anElement) return
      if(this.useTimer && this.changeTimer) {
        clearTimeout(this.changeTimer);
        this.changeTimer = null;
      }
      this._updateValue();
    },
    updateOptions () {
      this.anElement.update(this.options)
    },
    updateValue() {
      const value = this.modelValue !== null ? this.modelValue : ''
      this.anElement.set(value)
    }
  }*/
}
</script>
