<template>
  <contract-new v-if="!isLoading && !customerIsAuthorized" />
  <contract-list v-if="!isLoading && customerIsAuthorized" />
  <!--section id="step-0" class="easy-step step-0 show" data-step="0">
    <div class="container-fluid container-xxl p-0">
      <div class="row g-0">
        <div class="col-left">

          <div class="circle-bg">

            <div class="inner-circle">
              <div class="inner-circle-content">
                <div class="heading text-center">
                  Für<br>Erstlizenzierer
                </div>
                <ul class="benefits my-2 my-xl-4">
                  <li>Umfassende Unterstützungsfunktionen</li>
                  <li>Mengeneingabe über Stück oder Gewicht</li>
                </ul>
                <div class="circle-action text-center pt-3 pt-lg-1">
                  <router-link to="/wizard" class="btn btn-primary demo-only">
                    &gt; <span class="d-none d-md-inline-block">Lizenzierung </span> starten
                  </router-link>
                </div>
              </div>
            </div>
            <div class="text-box">
              <strong>Bitte bereithalten:</strong>
              <ul>
                <li>Umsatzsteuer bzw. Steuernummer</li>
                <li>Registernummer Zentrale Stelle Verpackungsregister (ZSVR)</li>
              </ul>
              <div>
                Noch nicht beim Verpackungsregister LUCID gemeldet?
                Dann jetzt auf der <a href="#" target="_blank" title="ZSVR-Website"> ZSVR-Website</a> nachholen und
                erst danach bei uns lizenzieren.
              </div>
            </div>
          </div>

        </div>

        <div class="col-right">

          <div class="circle-bg">

            <div class="inner-circle">
              <div class="inner-circle-content">
                <div class="heading text-center">
                  Für<br>Profis
                </div>
                <ul class="benefits my-2 my-xl-4">
                  <li>Abschluss mit minimal wenigen Klicks</li>
                  <li>Direkteingabe nach Gewicht</li>
                </ul>
                <div class="circle-action text-center pt-3 pt-lg-1">
                  <a class="btn btn-primary" href="/contract/new">> <span class="d-none d-md-inline-block">Lizenzierung </span> starten</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section-->
</template>
<script>
import ContractNew from "./contract/contract-new";
import ContractList from "./contract/contract-list";
export default {
  name: 'home-page',
  components: {ContractList, ContractNew},
  computed: {
    customerIsAuthorized() {
      return this.customer && this.customer.active;
    }
  },
  data() {
    return {
      isLoading: false,
      customer: null
    };
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContext((context) => {
      this.customer = context.customer;
      this.isLoading = false;
    });
  }
}
</script>