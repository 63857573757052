<template>
  <customer-page size="full" :show-circle="false" :is-loading="isLoading">
    <template v-slot:page-headline>
      {{ $t('Auftragsbestätigung') }}
    </template>
    <template v-slot:page-subline>
      <!--<span v-if="!isLoading">{{ $replacePlaceholders($t('Vertrag Nr. {contractNumber} - Vertragsjahr {contractYear}'), paymentResult) }}</span>-->
      <span v-if="!isLoading">{{ $t('Vertrags-Nr.') }} {{ paymentResult.contractNumber }} - {{ $t('Vertragsjahr') }} {{ paymentResult.contractYear }}</span>
    </template>
    <template v-slot:page-content>
      <div class="container" v-if="!isLoading">
        <div class="row">
          <div class="col-12 col-lg-4 offset-lg-2">
            <h2>{{ $t('Auftragsnummer') }} <br> {{ paymentResult.contractPeriodQuantityNumber }}</h2>
            <div class="lead">
              <p>{{$t('Vielen Dank für Ihren Auftrag!', paymentResult)}}</p>
              <!--
              <p v-if="paymentResult.invoiceNumber">{{$replacePlaceholders($t('Die Auftragsbestätigung inklusive Rechnung wurde Ihnen per E-Mail an die Adresse {customerEmail} gesendet.'), paymentResult)}}</p>
              <p v-else>{{$replacePlaceholders($t('Die Auftragsbestätigung wurde Ihnen per E-Mail an die Adresse {customerEmail} gesendet.'), paymentResult)}}</p>
              -->

              <p v-if="paymentResult.invoiceNumber">{{ $t('Die Auftragsbestätigung inklusive Rechnung wurde an die folgende E-Mail Adresse gesendet') }}: <span class="fw-bold">{{ paymentResult.customerEmail }}</span> </p>
              <p v-else>{{ $t('Die Auftragsbestätigung wurde Ihnen and die folgende E-Mail gesendet') }}: <span class="fw-bold">{{ paymentResult.customerEmail }}</span></p>


            </div>
          </div>
          <div class="col-12 col-lg-4 offset-lg-2" v-if="paymentResult.newCustomer">
            <h2>{{ $t('Kundenregistrierung') }}</h2>
            <div class="lead">
              <p><span class="fw-bold">{{ $t('Ihre Kundennummer') }}: {{ paymentResult.customerNumber }}</span></p>
              <!--<p v-html="$replacePlaceholders(customerRegisterMailHint, paymentResult)"></p>-->
              <p>{{ $t('Zur Bestätigung Ihrer Registrierung senden wir eine E-Mail an die Adresse') }}: <span class="fw-bold">{{ paymentResult.customerEmail }}</span></p>
              <p>{{ $t('Bitte öffnen Sie diese E-Mail (ggf. im Spam-Ordner) und klicken Sie auf den Link.') }}</p>
            </div>
          </div>
          <div class="col-12 col-lg-4 offset-lg-2" v-if="paymentResult.invoiceNumber">
            <h2>{{$t('Download Rechnung', paymentResult)}}</h2>
            <div class="lead">
              <p><a :href="paymentResult.invoiceLink" target="_blank"><i class="bi bi-download me-2"></i><i class="bi bi-file-earmark-pdf me-2"></i>{{$t('laden Sie hier Ihre Rechnung herunter', paymentResult)}}</a></p>
              <p>{{$t('Sie können Ihre Rechnung auch jederzeit im Bereich Verträge herunterladen.', paymentResult)}}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
export default {
  name: "payment-complete",
  components: {CustomerPage},
  data() {
    return {
      isLoading: false,
      paymentResult: {}
    }
  },
  computed: {
    customerRegisterMailHint() {
      return this.$t('Zur Bestätigung Ihrer Registrierung senden wir eine E-Mail an die Adresse<br/>');
    }
  },
  created() {
    this.isLoading = true;
    if(typeof gtag === "function") {
      gtag('event', 'conversion', {
        'send_to': 'AW-11002817606/mmFdCNeSoIsYEMbYxv4o',
        'value': 1.0,
        'currency': 'EUR',
        'transaction_id': '',
        'event_callback': () => {
        }
      });
    }
    easyLizeApp.apiClient.getPaymentResult((paymentResult) => {
      this.paymentResult = paymentResult;
      easyLizeApp.apiClient.sendContractPeriodQuantityDocuments(() => {
        this.isLoading = false;
      }, this.$route.params.contractPeriodQuantityId);
    }, this.$route.params.contractId, this.$route.params.contractPeriodQuantityId);
  }
}
</script>
