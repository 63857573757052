<template>
  <h2>Cookies </h2>

  <span id="cookiebotscript"></span>

  <hr>

  <h1>Datenschutzhinweise</h1>
  <h2>A Allgemeine Informationen</h2>
  <h3>1. Verantwortliche Stelle und Kontaktdaten</h3>

  <p>Verantwortlich für diese Internetseiten ist</p>

  <p>EKO-PUNKT GmbH &amp; Co. KG<br>
    Waltherstraße 49–51<br>
    51069 Köln</p>

  <p>Weitere Angaben zu unserem Unternehmen sowie Kontaktmöglichkeiten können Sie unserem Impressum entnehmen.</p>
  <p>Kontaktdaten unseres Datenschutzbeauftragten:</p>
  <p>REMONDIS SE &amp; Co.KG</p>
  <p>Brunnenstraße 138</p>
  <p>44536 Lünen</p>
  <p><a href="mailto:datenschutz@remondis.de">datenschutz@remondis.de</a></p>
  <p>Die REMONDIS SE &amp; CO. KG fungiert als einheitliche Anlaufstelle für die betroffenen Personen und ist für die
    Informationspflichten gemäß Art. 13 und 14 DSGVO, Datenschutzfolgenabschätzungen gem. Art. 24, 32 DSGVO
    verantwortlich.</p>

  <h3>2. Erklärung zur gemeinsamen Verantwortung</h3>
  <p>Die REMONDIS SE &amp; Co. KG sowie ihre Tochterunternehmen und verbundene Unternehmen üben in vielen Bereichen eine
    gemeinsame Verantwortung gem. § 26 DSGVO aus. Die Dienstleistungen und Lieferungen werden in der Regel durch die
    Tochterunternehmen unseres Konzerns erbracht, die hierzu auf die zentrale Datenverarbeitung in unserem Konzern
    zurückgreifen. Die gemeinsame Verantwortung erstreckt sich insbesondere auf folgende Bereiche:</p>
  <p>– Kundendatenbanken</p>
  <p>– Bewerbungsmanagement &amp; Recruiting</p>
  <p>Im Rahmen der gemeinsamen Verantwortlichkeit haben die REMONDIS SE &amp; CO KG sowie ihre Tochterunternehmen und
    verbundene Unternehmen vereinbart, wer von ihnen welche datenschutzrechtlichen Pflichten (insbesondere
    Informationspflichten gem. Art. 13, 14 DSGVO) wahrnimmt. Im Rahmen der gemeinsamen Verantwortlichkeit sind die
    REMONDIS SE &amp; Co. KG sowie das betroffene Tochterunternehmen oder verbundene Unternehmen gemeinsam verpflichtet,
    die gem. Art. 13, 14 DSGVO erforderlichen Informationen zugänglich zu machen. Die Unternehmen lassen dem jeweils
    angefragten Unternehmen die notwendigen Informationen aus ihrem Wirkbereich zukommen. Die Unternehmen informieren
    sich unverzüglich gegenseitig über die von Betroffenen geltend gemachten Rechtspositionen und stellen einander alle
    für die Beantwortung von Auskunftsersuchen notwendigen Informationen zur Verfügung. Datenschutzrechte können
    gegenüber allen beteiligten Unternehmen geltend gemacht werden.</p>
  <p>Darüber hinaus kann es bei einigen Verarbeitungen zu einer gemeinsamen Verantwortlichkeit von REMONDIS-Unternehmen
    untereinander oder mit Dritten kommen. Auf diese wird im Rahmen der jeweiligen Verarbeitung (Teil B)
    eingegangen.</p>

  <h3>3. Datenkategorien, Zwecke und Rechtsgrundlagen der Datenverarbeitungen</h3>
  <p>Wir verarbeiten Ihre personenbezogenen Daten nach den Vorschriften der Datenschutz-Grundverordnung (DSGVO) und dem
    Bundesdatenschutzgesetz (BDSG) sowie allen weiteren geltenden Rechtsvorschriften. Die Erhebung und Verarbeitung der
    Daten bestimmen sich nach der von Ihnen gewählten Leistung bzw. Information.</p>
  <p>Allgemein erheben wir folgende Informationen:</p>
  <ul>
    <li>Name, Vorname, Anrede</li>
    <li>E-Mail-Adresse</li>
    <li>Kommunikationsdaten (IP-Adresse, Geräte-Informationen)</li>
    <li>Nutzungsdaten (besuchte Webseiten, Zugriffszeiten, Interesse an Inhalten)</li>
    <li>Anschrift</li>
    <li>Telefonnummer</li>
  </ul>
  <p>Die spezifischen Datenerhebungen und Verarbeitungen können Sie den einzelnen Verarbeitungen (Teil B) sowie den
    jeweiligen Bestimmungen unserer Vertragsunterlagen entnehmen.</p>

  <h3>4. Datenempfänger</h3>
  <p>Wir übermitteln Ihre personenbezogene nur in folgenden Fällen an Dritte:</p>
  <ul>
    <li>Die Übermittlung erfolgt in Erfüllung einer gesetzlichen Vorgabe, nach der wir zur Meldung oder Weitergabe der
      Daten verpflichtet sind.
    </li>
    <li>Die Übermittlung der Daten erfolgt an externe Unternehmen, die in unserem Auftrag aus Auftragsverarbeiter tätig
      sind oder die in unserem Auftrag tätig werden (dies betrifft z.B. Kurier- und Logistikunternehmen, Call-Center,
      externe Rechenzentren, EDV- und IT-Anwendungen, Webseitenmanagement, Wirtschaftsprüfungsdienstleistung,
      Kreditinstitute, Datenentsorgung, etc.)
    </li>
    <li>Die Übermittlung der Daten an Dritte erfolgt aufgrund einer Einwilligung durch Sie</li>
    <li>Die Übermittlung der Daten erfolgt zur Abwicklung eines Vertragsverhältnisses mit Ihnen gem. Art. 6 Abs. 1 S. 1
      lit. b DSGVO (z.B. an ein verbundenes Unternehmen im Konzern (vgl. Ziffer 2) oder einen Unterauftragsnehmer oder
      an ein Logistikunternehmen).
    </li>
  </ul>

  <h3>5. Löschung der Daten und Dauer der Speicherung</h3>
  <p>Grundsätzlich verarbeiten und speichern wir Ihre Daten nur für die Dauer unserer Geschäftsbeziehung einschließlich
    der Anbahnung und Abwicklung des Vertrages sowie der gesetzlichen Aufbewahrungsfristen.</p>
  <p>Sind die Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten und Rechte nicht mehr erforderlich,
    werden diese regelmäßig gelöscht, es sei denn, deren – befristete – Weiterverarbeitung ist zur Erfüllung der
    vorstehend aufgeführten Zwecke aus einem überwiegenden berechtigten Interesse erforderlich.</p>
  <p>Soweit einzelne Verarbeitungen personenbezogener Daten andere Löschfristen erforderlich machen, werden diese bei
    der Beschreibung der einzelnen Verarbeitungen unter Ziffer B erwähnt.</p>

  <h3>6. Verarbeitung Ihrer Daten in einem Drittland</h3>
  <p>Eine Datenübermittlung an Stellen in Drittländern (Staaten außerhalb der Europäischen Union (EU) bzw. des
    Europäischen Wirtschaftsraums (EWR) erfolgt nur dann, wenn es zur Ausführung eines Auftrages/Vertrags von bzw. mit
    Ihnen erforderlich sein sollte, es gesetzlich vorgeschrieben ist (z.B. steuerrechtliche Meldepflichten), angemessene
    Datenschutzniveaus vorliegen oder Sie uns eine Einwilligung erteilt haben.</p>
  <p>Die Verarbeitung Ihrer Daten in einem Drittland kann auch im Zusammenhang mit der Einschaltung von Dienstleistern
    im Rahmen der Auftragsverarbeitung erfolgen. Soweit für das betreffende Land kein Beschluss der EU-Kommission über
    ein dort vorliegendes angemessenes Datenschutzniveau vorliegen sollte, stellen wir durch entsprechende Verträge
    sicher, dass ihre Rechte und Freiheiten angemessen geschützt und garantiert werden. Entsprechende
    Detailinformationen entnehmen Sie den Einzelbeschreibungen der Verarbeitungen unter Ziffer B.</p>
  <p>Informationen zu den geeigneten oder angemessenen Garantien und zu der Möglichkeit, eine Kopie von Ihnen zu
    erhalten, können auf Anfrage beim Datenschutzbeauftragten angefordert werden.</p>

  <h3>7. Ihre Rechte als betroffene Person</h3>
  <p>Auf Anfrage werden wir Ihnen schriftlich oder elektronisch darüber Auskunft erteilen, ob und welche Daten zu Ihrer
    Person bei uns gespeichert sind (Art. 15 DSGVO) sowie Ihre Eingaben zur Löschung (Art. 17 DSGVO), Berichtigung (Art.
    16 DSGVO), Einschränkung der Verarbeitung (Art. 18 DSGVO) sowie Übermittlung (Art. 20 DSGVO) Ihrer personenbezogenen
    Daten prüfen und bei Vorliegen der Voraussetzungen durchführen.</p>
  <p>Bitte wenden Sie sich hierzu an:</p>
  <p>REMONDIS SE &amp; Co. KG<br>
    – Datenschutzbeauftragter –<br>
    Brunnenstr. 138<br>
    44536 Lünen<br>
    <a href="mailto:datenschutz@remondis.de">datenschutz@remondis.de</a></p>
  <p>Soweit Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten rechtswidrig erfolgt, können Sie
    sich bei einer Aufsichtsbehörde beschweren (§ 77 DSGVO). Eine Liste der Datenschutzbeauftragten sowie deren
    Kontaktdaten können Sie folgendem Link entnehmen:
    https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html</p>

  <h3>8. Belehrung zum Widerspruchsrecht</h3>
  <p><strong>Soweit die Verarbeitung Ihrer personenbezogener Daten auf Grund von Art 6 Abs 1 S 1 lit. f DSGVO
    (Datenverarbeitung aufgrund einer Interessenabwägung) oder aufgrund Art. 6 Abs 1 S 1 lit. e DSGVO (Datenverarbeitung
    im öffentlichen Interesse) erfolgt, haben Sie das Recht, jederzeit gegen die Verarbeitung Widerspruch
    einzulegen. </strong></p>
  <p><strong>Wir verarbeiten die personenbezogenen Daten dann in jedem Fall nicht mehr zu Zwecken des Direktmarketings
    oder eines damit in Verbindung stehenden Profilings und darüber hinaus auch nicht für andere Zwecke, es sei denn,
    wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und
    Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
    Rechtsansprüchen. In diesem Fall müssen Sie für den Widerspruch Gründe darlegen, die sich aus Ihrer besonderen
    Situation ergeben.</strong></p>
  <p><strong>Ihren Widerspruch sollten Sie möglichst an folgende Kontaktadresse senden: </strong></p>
  <p><a href="mailto:datenschutz@remondis.de"><strong>datenschutz@remondis.de</strong></a></p>
  <p><strong>Das Gleiche gilt, falls Sie eine Einwilligung zur Erhebung oder Verwendung personenbezogener Daten erteilt
    haben und diese widerrufen möchten. Eine solche Einwilligung können Sie jederzeit mit Wirkung für die Zukunft per
    E-Mail oder Brief widerrufen.</strong></p>

  <h2>B. Einzelne Verarbeitungen</h2>
  <h3>1. Protokollierungen</h3>
  <p>Bei der Nutzung der Website werden vorübergehend bestimmte Verbindungsdaten und von Ihrem Internetbrowser
    bereitgestellte Daten gespeichert. Zum Betrieb der Internetseite werden folgende Daten protokolliert:</p>
  <ul>
    <li>IP-Adresse des aufrufenden Rechners</li>
    <li>Betriebssystem des aufrufenden Rechners</li>
    <li>Browser-Version des aufrufenden Rechners</li>
    <li>Name der abgerufenen Datei</li>
    <li>Datum und Uhrzeit des Abrufs</li>
    <li>Übertragene Datenmenge</li>
    <li>Verweisende URL</li>
    <li>Verbindungsprotokollierung</li>
  </ul>
  <p>Die Datenerhebung und Verarbeitung der Logfile-Informationen erfolgt auf Grundlage unseres berechtigten Interesses
    und zum Zweck der Sicherstellung unseres Online-Angebotes. Die Logfile-Informationen werden nach dem Beenden der
    Sitzung anonymisiert gespeichert.</p>

  <h3>2. Kontaktformular/Infobestellung</h3>
  <p>Im Interesse der Kommunikation mit Kunden und Interessenten bieten wir auf unserer Internetseite ein
    Kontaktformular an, über das Sie Informationen zu unseren Produkten anfordern oder allgemein Kontakt aufnehmen
    können. Neben den freiwilligen Angaben und Ihrem Nachrichteninhalt verlangen wir die Angabe folgender Informationen
    von Ihnen:</p>
  <p>Anrede<br>
    Name<br>
    Vorname<br>
    E-Mail-Adresse</p>
  <p>Wir benötigen diese Angaben, um Ihre Anfrage zu bearbeiten, Sie korrekt anzusprechen und Ihnen eine Antwort
    zukommen zu lassen. Anfragen, die über das Kontaktformular eingehen, werden als Email gespeichert und regelmäßig
    dahingehend überprüft, ob Daten gelöscht werden können. Sollten Daten im Rahmen einer Kunden- oder
    Interessentenbeziehung nicht weiter erforderlich sein oder ein entgegenstehendes Interesse des Kunden überwiegen,
    werden wir die betreffenden Daten nach spätestens 180 Tagen löschen, sofern dem keine gesetzlichen
    Aufbewahrungspflichten entgegenstehen. Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO.
    Soweit die Kontaktaufnahme zur Anbahnung von Vertragsbeziehungen erfolgt, ist die Rechtsgrundlage Art. 6 Abs.1 lit.
    b DSGVO.</p>

  <h3>3. Newsletter-Abonnement</h3>
  <p>Auf unseren Internetseiten haben Sie die Möglichkeit, Newsletter zu abonnieren. Wir versenden Newsletter und
    elektronische Benachrichtigungen mit werblichen Informationen nur mit der Einwilligung der Empfänger oder einer
    gesetzlichen Erlaubnis. Newsletter enthalten Informationen zu unseren Leistungen und unseren Unternehmen sowie
    Neuigkeiten in der Branche.</p>
  <p>Die Anmeldung zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren: Sie erhalten nach der Anmeldung
    eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Um sich für den Newsletter anzumelden,
    reicht es aus, wenn Sie Ihre E-Mailadresse sowie Ihren Vor- und Nachnamen angeben. Ihre Anmeldung zum Newsletter
    wird protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu
    gehört die Speicherung des Anmelde- und des Bestätigungszeitpunkts, sowie der IP-Adresse. Ebenso werden die
    Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert. Zusätzlich werden während der
    Nutzungszeit des Newsletters folgende Daten erhoben:</p>
  <ul>
    <li>Welche E-Mails per Newsletter zugestellt wurden und ob die Zustellung nicht erfolgreich war (sog. „bounces“ =
      Rückläufer-E-Mails)
    </li>
  </ul>
  <p>Der Versand der Newsletter erfolgt mittels des US-Versanddienstleisters Amazon Web Services Inc., 410 Terry Avenue
    North, Seattle, WA 98109-5210, USA. Die Datenschutzbestimmungen des Versanddienstleisters können Sie hier einsehen:
    <a href="https://aws.amazon.com/de/compliance/germany-data-protection/">https://aws.amazon.com/de/compliance/germany-data-protection/</a>
  </p>
  <p>Der Versanddienstleister wird auf Grundlage unserer berechtigten Interessen an einer sicheren Versendung der
    Newsletter gem. Art.6 Abs. 1 lit. f DSGVO und eines Auftragsverarbeitungsvertrages gem. Art.28Abs. 3 S. 1 DSGVO
    eingesetzt.</p>
  <p>Der Versand des Newsletters erfolgt auf Grundlage einer Einwilligung der Empfänger gem. Art. 6 Abs. 1 lit. a, Art.
    7 DSGVO i.V.m § 7 Abs. 2 Nr. 3 UWG bzw. auf Grundlage der gesetzlichen Erlaubnis gem. § 7 Abs. 3 UWG. Die
    Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit.
    f DSGVO. Unser Interesse richtet sich auf den Einsatz eines nutzerfreundlichen sowie sicheren Newslettersystems, das
    sowohl unseren geschäftlichen Interessen dient, als auch den Erwartungen der Nutzer entspricht und uns ferner den
    Nachweis von Einwilligungen erlaubt.</p>
  <p>Sie können Ihre Einwilligung in den Empfang unseres Newsletters jederzeit widerrufen. Einen Link zur Abbestellung
    des Newsletters finden Sie am Ende eines jeden Newsletters. Wir können die ausgetragenen E-Mailadressen bis zu drei
    Jahren auf Grundlage unserer berechtigten Interessen speichern bevor wir sie löschen, um eine ehemals gegebene
    Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von
    Ansprüchen beschränkt.</p>

  <h3>4. Matomo</h3>
  <p>Auf unseren Webseiten verwenden wir eine auf unseren eigenen Servern implementierte Version von Matomo. Hierbei
    handelt es sich um eine Open-Source-Analysesoftware zum Zweck der statistischen Auswertung der Besucherzugriffe.
    Anhand dieser Auswertung möchten wir insbesondere nachvollziehen, welche unserer Seiten wie oft aufgerufen werden,
    nicht jedoch nachvollziehen, von wem diese aufgerufen werden. Die Implementation von Matomo auf unserem Server nutzt
    keine Cookies. Wir speichern ausschließlich anonymisierte Nutzerdaten zur Erhebung allgemeiner statistischer Werte,
    ohne das Nutzerverhalten umfassend zu analysieren. Eine umfassende Analyse des Nutzerverhaltens ist aufgrund der
    anonymisiert erhobenen Daten auch nicht möglich. Die im Server Log-In übermittelte IP-Adresse wird noch vor der
    Übermittlung an die Matomo-Installation auf unserem Server anonymisiert und durch eine zufällige IP-Adresse ersetzt.
    Eine Nachverfolgung der IP-Adresse</p>
  <p>anhand der anonymisierten Daten ist nicht möglich, da zeitliche Einträge bei der Matomo-Installation randomisiert
    werden.</p>
  <p>Zweck der Verarbeitung ist die statistische Analyse des Nutzerverhaltes zur Optimierung der Funktionalität unserer
    Webseiten. Unser berechtigtes Interesse liegt in der Verbesserung unseres Webangebotes.</p>
  <p>Rechtsgrundlage für den Einsatz von Matomo ist Art. 6 Abs. 1 S.1 lit. f DSGVO.</p>

  <h3>5. Google Dienste</h3>
  <p>Wir nutzen auf unseren Webseiten verschiedene Dienste der Google Ireland Ltd. (im folgenden „Google“), Gordon
    House, Barrow Street, Dublin 4, Ireland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
    94043, USA. Nähere Informationen zu den einzelnen Google-Diensten, die wir auf unseren Webseiten nutzen, finden Sie
    in der weiteren Datenschutzerklärung.</p>
  <p>Google erhebt unter Umständen Informationen (auch personenbezogene Daten) und verarbeitet diese. Dabei kann nicht
    ausgeschlossen werden, dass Google die Informationen auch an einen Server in einem Drittland, darunter auch in den
    USA übermittelt. Das Datenschutzniveau in den USA erreicht nach überwiegender Auffassung derzeit nicht den in der EU
    geltenden Standard weshalb ein Angemessenheitsbeschluss der Europäischen Kommission oder geeignete Garantien zur
    Sicherstellung des Datenschutzes derzeit nicht vorliegen. Google sichert aber zu, für die Übermittlung
    personenbezogener Daten in Drittländer die sog. Standardvertragsklauseln (SCC) nach der Richtlinie 95/46/EG
    einzuhalten (<a
        href="https://policies.google.com/privacy/frameworks">https://policies.google.com/privacy/frameworks</a>).</p>
  <p>Unser Unternehmen hat keinen Einfluss darauf und auch keine abschließenden Informationen darüber, welche Daten
    Google tatsächlich erhebt und verarbeitet. Folgende Daten (auch personenbezogene Daten) können nach Angabe von
    Google erhoben und verarbeitet werden:</p>
  <ul>
    <li>Protokolldaten (insbesondere die IP-Adresse)</li>
    <li>Standortbezogene Informationen</li>
    <li>Cookies und ähnliche Technologien</li>
    <li>Eindeutige Applikationsnummern</li>
  </ul>
  <p>Sofern Sie über einen Google-Account verfugen und dort angemeldet sind, während Sie sich auf unseren Webseiten
    befinden, kann Google die verarbeiteten Informationen gemäß Ihren Account-Einstellungen speichern und ggf. zuordnen.
    Nach Angaben von Google werden je nach Account-Einstellungen Aktivitäten auf anderen Webseiten und in Apps ggf. mit
    Ihren personenbezogenen Daten verknüpft, um die Dienste von Google und von Google eingeblendete Werbung zu
    verbessern. Nähere Informationen hierzu finden Sie unter <a
        href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites?gl=de</a>
  </p>
  <p>Eine direkte Verknüpfung dieser Daten können Sie verhindern, indem Sie sich aus Ihrem Google Account ausloggen oder
    auch die entsprechenden Account-Einstellungen in Ihrem Google Account ändern.<br>
    Weiterhin können Sie Ihre erteilte Einwilligung unter den „Cookie-Einstellungen“ auf unserer Website jederzeit ohne
    eine Begründung widerrufen. Weitere Hinweise zu den Account-Einstellungen von Google finden Sie unter <a
        href="https://privacy.google.com/take-control.html">https://privacy.google.com/take-control.html</a><br>
    Nähere Informationen finden Sie in den Datenschutzhinweisen von Google, die Sie hier abrufen können: <a
        href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>
  <p><strong>5.1 Google Maps</strong></p>
  <p>Wir nutzen auf unseren Webseiten die Dienste von “Google Maps” des Anbieters Google zur Einbindung der Landkarten
    dieses Dienstes.</p>
  <p>Durch die Einbindung dieser Dienste, die auf den Servern von Google liegen, werden Informationen zum Nutzer
    (einschließlich der IP-Adresse) durch Google verarbeitet, damit die abgefragten Inhalte an den Browser gesendet
    werden können. Weiterhin kann Google Maps auch sogenannte Web Beacons (nicht sichtbare Grafiken) verwenden. Durch
    diese Cookies und Web Beacons können Informationen wie der Besucherverkehr auf Webseiten oder Informationen über das
    Nutzergerät erfasst und analysiert werden. Die durch Cookies und Web Beacons erzeugten Informationen (einschließlich
    der IP-Adresse der Nutzer) werden an einen Server von Google, möglicherweise in den USA oder anderen Drittstaaten,
    übertragen und dort gespeichert. Diese Informationen können von Google an Vertragspartner von Google weitergegeben
    werden.</p>
  <p>Mit der Einbindung der Google Maps verfolgen wir den Zweck, unsere Standorte besser auffindbar zu machen und Ihnen
    eine bessere Übersicht über unsere Angebote zu geben. Rechtsgrundlage für die hier beschriebene Verarbeitung
    personenbezogener Daten ist Art. 6 Abs. 1 lit. a) DSGVO, sofern wir Sie um Ihre Einwilligung zum Einsatz der Dienste
    von Google Maps gebeten haben. Detaillierte Informationen zu Google Maps finden Sie auf den Webseiten <a
        href="https://cloud.google.com/maps-platform%3ehttps:/cloud.google.com/maps-platform%3c/a%3e%20sowie%20unter%20%3ca%20href=">https://policies.google.com/privacy</a>.
    Weitere Informationen zur Datenverarbeitung durch Google im Rahmen der Nutzung der Google Dienste erhalten Sie in
    Ziffer 5. Google Dienste.</p>
  <p>Insbesondere werden folgende personenbezogenen Daten durch den Google Maps verarbeitet:</p>
  <ul>
    <li>IP-Adressen</li>
    <li>Standortdaten der Nutzer</li>
    <li>Google verarbeitet die Daten, um die Dienste auf unserer Webseite darzustellen und auszulösen. Google kann diese
      Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte
      diese Daten im Auftrag von Google verarbeiten.
    </li>
  </ul>
  <p>Widerspruchsrecht:</p>
  <p>Sie können Ihre erteilte Einwilligung unter den „Cookie-Einstellungen“ auf unserer Website jederzeit ohne eine
    Begründung widerrufen.</p>
  <p>Weiterhin können Sie die Einbindung der Google Maps Dienste unterbinden, indem Sie das Google Opt-Out Plugin
    aktivieren: <a href="https://tools.google.com/dlpage/gaoptout?">https://tools.google.com/dlpage/gaoptout?</a></p>
  <p>Es besteht die Möglichkeit, dass bei Deaktivierung eine nur eingeschränkte Nutzung unserer Webseite möglich ist.
    Soweit Rechtsgrundlage für die Verarbeitung durch Google Maps unser berechtigtes Interesse gem. Art. 6 Abs. 1 S. 1
    DSGVO ist, haben Sie ein Widerspruchsrecht gem. Art 21 DSGVO. Ihren Widerspruch können Sie uns per Post oder eMail
    mitteilen an <a href="mailto:datenschutz@remondis.de">datenschutz@remondis.de</a></p>
  <p>Die verarbeiteten Informationen werden nur so lange gespeichert, wie dies für den vorgesehenen Zweck notwendig oder
    gesetzlich vorgeschrieben ist. Die Bereitstellung der personenbezogenen Daten ist nicht verpflichtend und weder
    gesetzlich noch vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich.</p>
  <p><strong>5.2 Google Tag Manager</strong></p>
  <p>Wir nutzen auf unseren Webseiten den Google Tag Manager. Der Google Tag Manager ist ein Dienst der Google Ireland
    Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland, US-Muttergesellschaft Google LLC, 1600
    Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
  <p>Durch den Google Tag Manager können verschiedene Daten und Dienste geordnet und vereinfacht auf unserer Webseite
    eingebunden werden. Der Google Tag Manager implementiert dabei die verschiedenen Tags. Bei der Aktivierung eines
    Tags speichert Google unter Umständen Informationen (auch personenbezogene Daten) und verarbeitet diese. Dabei kann
    nicht ausgeschlossen werden, dass Google die Informationen auch an einen Server in einem Drittland übermittelt.</p>
  <p>Mit der Einbindung des Google Tag Managers verfolgen wir den Zweck, eine vereinfachte Einbindung verschiedener
    Dienste vornehmen zu können und Ladezeiten durch die Einbindung zu optimieren. Rechtsgrundlage für die hier
    beschriebene Verarbeitung personenbezogener Daten ist Art. 6 Abs. 1 lit. a) DSGVO.</p>
  <p>Detaillierte Informationen zu dem Google Tag Manager finden Sie auf den Webseiten <a
      href="https://www.google.com/analytics/terms/tag-manager/">https://www.google.com/analytics/terms/tag-manager/</a>
    sowie unter <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> unter dem
    Abschnitt „Daten, die wir aufgrund Ihrer Nutzung unserer Dienste erhalten“. Weitere Informationen zur
    Datenverarbeitung durch Google im Rahmen der Nutzung der Google Dienste erhalten Sie in Ziffer 5. Google Dienste.
  </p>
  <p>Insbesondere werden folgende personenbezogenen Daten durch den Google Tag Manager verarbeitet:</p>
  <ul>
    <li>Online- und Cookie-Kennzeichnungen</li>
    <li>IP-Adresse</li>
  </ul>
  <p>Google verarbeitet die Daten in unserem Auftrag, um die Dienste auf unserer Webseite darzustellen und auszulösen.
    Google kann diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder
    soweit Dritte diese Daten im Auftrag von Google verarbeiten. Sofern Sie einzelne Trackingdienste deaktiviert haben
    (z.B. durch das Setzenlassen eines Opt-Out-Cookies), bleibt die Deaktivierung für alle betroffenen Tracking-Tags
    bestehen, die durch den Google Tag Manager eingebunden werden.</p>
  <p>Widerspruchsrecht:</p>
  <p>Sie können Ihre erteilte Einwilligung unter den „Cookie-Einstellungen“ auf unserer Website jederzeit ohne eine
    Begründung widerrufen. Weiterhin können Sie die Einbindung des Google Tag Managers unterbinden, indem Sie das Google
    Tag-Cookie in Ihrem Browser deaktivieren.</p>
  <p>Es besteht die Möglichkeit, dass bei Deaktivierung eine nur eingeschränkte Nutzung unserer Webseite möglich
    ist.</p>
  <p>Die verarbeiteten Informationen werden nur so lange gespeichert, wie dies für den vorgesehenen Zweck notwendig oder
    gesetzlich vorgeschrieben ist. Die Bereitstellung der personenbezogenen Daten ist nicht verpflichtend und weder
    gesetzlich noch vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich.</p>
  <p><strong>5.3 Google Ads und Conversion-Tracking</strong></p>
  <p>Diese Website nutzt das Online-Werbeprogramm „Google Ads“ und im Rahmen von Google Ads das Conversion-Tracking.
    Google Ads und Google Conversion Tracking sind Online-Werbedienste der Google Ireland Ltd, Gordon House, Barrow
    Street, Dublin 4, Ireland.</p>
  <p>Das Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete Anzeige klickt.
    Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Computersystem abgelegt werden. Diese Cookies
    verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer
    bestimmte Seiten dieser Website und das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der
    Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Alle Google Ads-Kunden erhalten
    unterschiedliche Cookies, die über die Websites nachverfolgt werden können. Die mithilfe des Conversion-Cookies
    eingeholten Informationen dienen dazu, Conversion-Statistiken für Ads-Kunden zu erstellen, die sich für
    Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige
    geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten
    jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Weitere Informationen zur
    Datenverarbeitung von Google im Rahmen der Nutzung der Google-Dienste sowie zur Datenübermittlung in die USA
    erhalten Sie in Ziffer 5. Google-Dienste.</p>
  <p>Mit dem Einsatz von Google Ads und dem Conversion Tracking bezwecken wir, Ihnen interessenbezogene Werbeanzeigen
    zur Kenntnis zu bringen. Rechtsgrundlage für den Einsatz von Google Ads und das Conversion Tracking ist Art. 6 Abs.
    1 lit a) DSGVO.</p>
  <p>Widerspruchsrecht:</p>
  <p>Sie können Ihre erteilte Einwilligung unter den „Cookie-Einstellungen“ auf unserer Website jederzeit ohne eine
    Begründung widerrufen. Weiterhin können Sie die Einbindung des Google Ads unterbinden, indem Sie das Google
    Ads-Cookie in Ihrem Browser deaktivieren.</p>
  <p>Es besteht die Möglichkeit, dass bei Deaktivierung eine nur eingeschränkte Nutzung unserer Webseite möglich
    ist.</p>
  <p>Die verarbeiteten Informationen werden nur so lange gespeichert, wie dies für den vorgesehenen Zweck notwendig oder
    gesetzlich vorgeschrieben ist. Die Bereitstellung der personenbezogenen Daten ist nicht verpflichtend und weder
    gesetzlich noch vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich.</p>
  <p>Unter den nachstehenden Internetadressen erhalten Sie weitere Informationen über die Datenschutzbestimmungen von
    Google <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> und die Hinweise über
    die Privatsphäreeinstellungen von Google <a
        href="https://privacy.google.com/take-control.html?categories_activeEl=sign-in">https://privacy.google.com/take-control.html?categories_activeEl=sign-in</a>.
  </p>
  <p><strong>5.4 &nbsp;Google Analytics</strong></p>
  <p>Wir nutzen auf unseren Webseiten Google Analytics, einen Webanalysedienst der Google Ireland Limited („Google“),
    Gordon House, Barrow Street, Dublin 4, Irland.</p>
  <p>Google Analytics verwendet Textdateien, die auf Ihrem Gerät gespeichert werden und die eine Analyse der Benutzung
    der durch Sie besuchten Webseiten ermöglichen, sogenannte „Cookies“. Weiterhin kann Google Analytics auch sogenannte
    Web Beacons (nicht sichtbare Grafiken) verwenden. Durch diese Cookies und Web Beacons können Informationen wie der
    Besucherverkehr auf Webseiten erfasst und analysiert werden. Die durch Cookies und Web Beacons erzeugten
    Informationen über die Benutzung unserer Webseitne (einschließlich der IP-Adresse der Nutzer) werden an einen Server
    von Google, möglicherweise in den USA oder anderen Drittstaaten, übertragen und dort gespeichert. Diese
    Informationen können von Google an Vertragspartner von Google weiter gegeben werden.</p>
  <p>Mit der Einbindung von Google Analytics verfolgen wir den Zweck, das Nutzerverhalten auf unseren Webseiten zu
    analysieren und anzupassen, um unser Angebot bedarfsgerecht zu verbessern.</p>
  <p>Rechtsgrundlage für die hier beschriebene Verarbeitung personenbezogener Daten ist Art. 6 Abs. 1 lit. a) DSGVO.</p>
  <p>Detaillierte Informationen zu den verarbeiteten Informationen erhalten Sie unter unter „Daten, die wir aufgrund
    Ihrer Nutzung unserer Dienste erhalten“.</p>
  <p>Weitere Informationen zur Datenverarbeitung durch Google im Rahmen der Nutzung der Google Dienste sowie zu der
    Privacy-Shield-Zertifizierung von Google finden Sie in dem Abschnitt zu allgemeinen Informationen zu Google
    Diensten.</p>
  <p>Insbesondere folgende Daten werden von Google Analytics verarbeitet:</p>
  <p>Gerätekennungen</p>
  <p>Online-Kennzeichnungen (einschließlich Cookie-Kennungen)</p>
  <p>IP-Adresse</p>
  <p>Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung („anonymize IP“) ein. Hierdurch wird Ihre
    IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
    Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
    Server von Google in den USA übertragen und dort gekürzt.</p>
  <p>Google verarbeitet die Daten in unserem Auftrag, um die Nutzungen unserer Webseiten auszuwerten und in Reports
    darzustellen sowie um mit der Webseitennutzung verbundene Dienstleistungen zu erbringen. Google kann diese
    Informationen möglicherweise an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte
    diese Daten im Auftrag von Google verarbeiten.</p>
  <p>Darüber hinaus ist Google ist durch den Auftragsverarbeitungsvertrag mit uns berechtigt, bestimmte Subunternehmer
    zu beauftragen und möglicherweise diesen Daten zukommen zu lassen. Eine Liste dieser Subunternehmer finden Sie hier:
    <a href="https://privacy.google.com/businesses/subprocessors/">https://privacy.google.com/businesses/subprocessors/</a>.
  </p>

  <h3>6. Cookiebot / „CookieConsent“, „CookieConsentBulkTicket“</h3>
  <p>Wir nutzen den Zustimmungsverwaltungsdienst Cookiebot, der Cybot A/S, Havnegade 39, 1058 Kopenhagen, Dänemark
    (Cybot). Dies ermöglicht uns die Einwilligung der Websitenutzer zur Datenverarbeitung einzuholen und zu verwalten.
    Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich (Art. 7 Abs. 1DSGVO), der wir
    unterliegen (Art. 6 Abs. 1 S. 1 lit. c DSGVO). Dafür werden mit Hilfe von Cookies folgende Daten verarbeitet:</p>
  <p>Ihre IP-Adresse (die letzten drei Ziffern werden auf ‚0’ gesetzt). Datum und Uhrzeit der Zustimmung.
    Browserinformationen URL, von der die Zustimmung gesendet wurde. Ein zufällig generierter Schlüssel der Einwilligung
    des Endnutzers, als Nachweis der Zustimmung</p>
  <p>Der Schlüssel und der Zustimmungsstatus werden für 12 Monate im Browser mit Hilfe des Cookies „CookieConsent“
    gespeichert. Damit bleibt Ihre Cookie-Präferenz für nachfolgende Seitenanfragen erhalten. Mit Hilfe des Schlüssels
    kann ihre Zustimmung nachgewiesen und nachvollzogen werden.</p>
  <p>Wenn Sie die Service-Funktion „Sammelzustimmung“ aktivieren, um die Zustimmung für mehrere Webseiten durch eine
    einzige Endnutzerzustimmung zu aktivieren, speichert der Dienst zusätzlich eine separate, zufällige, eindeutige ID
    mit Ihrer Zustimmung. Wenn alle folgenden Kriterien erfüllt sind, wird dieser Schlüssel im Cookie des Drittanbieters
    „CookieConsentBulkTicket“ in Ihrem Browser in verschlüsselter Form gespeichert: Sie aktivieren die
    Sammelzustimmungsfunktion in der Dienstkonfiguration. Sie lassen Cookies von Drittanbietern über
    Browsereinstellungen zu. Sie haben „Nicht verfolgen“ über die Browsereinstellungen deaktiviert. Sie akzeptieren alle
    oder wenigstens bestimmte Arten von Cookies, wenn Sie die Zustimmung erteilen.</p>
  <p>Die Funktionsfähigkeit der Website ist ohne die Verarbeitung nicht gewährleistet.</p>
  <p>Cybot ist Empfänger Ihrer personenbezogenen Daten und als Auftragsverarbeiter für uns tätig.</p>
  <p>Die Verarbeitung findet in der Europäischen Union statt. Weitere Informationen zu Widerspruchs- und
    Beseitigungsmöglichkeiten gegenüber Cybot finden Sie unter: https://www.cookiebot.com/de/privacy-policy/</p>
  <p>Ihre personenbezogenen Daten werden fortlaufend nach 12 Monaten oder unmittelbar nach der Kündigung des Vertrages
    zwischen uns und Cybot gelöscht.</p>
  <p>Bitte beachten Sie unsere generellen Ausführungen über die Löschung und Deaktivierung von Cookies oben.</p>

  <h2>C Cookie-Übersicht</h2>
  <p>Bei Cookies handelt es sich um kleine Textdateien, die durch Ihren Browser auf Ihrer Festplatte gespeichert werden.
    Wir verwenden auch sogenannte Session-Cookies. Diese dienen dazu, eine eindeutige Verbindung zwischen Ihnen und
    unserer Internetpräsenz herzustellen. Dafür wird lediglich eine zufällige Zahl gespeichert. Der Session-Cookie wird
    beim Schließen des Browsers automatisch entfernt. Falls Sie keine Cookies auf Ihrem Computer (oder einem anderen
    Gerät) zulassen möchten, können Sie diese in Ihrem Internet-Browser deaktivieren. Allerdings kann dies die
    Benutzerfreundlichkeit und Funktionalität der Website beeinträchtigen. Rechtsgrundlage für die Datenverarbeitung
    durch Cookies zum Zwecke des Betriebs der Internetseite ist Art. 6 Abs. 1 lit. f DSGVO.</p>
  <p>Unsere Website nutzt die folgenden Cookies:</p>
  <h3>1. Notwendige Cookies</h3>
  <p>Betriebstechnische Cookies, die zur Bereitstellung der Grundfunktionen des Shops erforderlich sind und daher nicht
    deaktiviert werden können. Diese dienen dazu, eine eindeutige Verbindung zwischen Ihnen und unserer Internetpräsenz
    herzustellen. Dafür wird lediglich eine zufällige Zahl gespeichert. Der Session-Cookie wird beim Schließen des
    Browsers automatisch entfernt. Falls Sie keine Cookies auf Ihrem Computer (oder einem anderen Gerät) zulassen
    möchten, können Sie diese in Ihrem Internet-Browser deaktivieren. Allerdings kann dies die Benutzerfreundlichkeit
    und Funktionalität der Website beeinträchtigen. Rechtsgrundlage für die Datenverarbeitung durch Cookies zum Zwecke
    des Betriebs der Internetseite ist Art. 6 Abs. 1 lit. f DSGVO, Art 6 Abs. 1 lit. c) DSGVO in Verbindung mit Art. 7
    DSGVO.</p>
  <!--
  <p><strong>__csrf_token-1</strong><br>
  Validierung von Kundenangaben</p>
  <p><strong>allowCookie</strong><br>
  Speicherung der Cookie-Einstellungen</p>
  <p><strong>cookiePreferences</strong><br>
  Speicherung der Cookie-Präferenzen in einem serialisierten String</p>
  <p><strong>csrf</strong><br>
  Validierung von Formulareingaben zur technischen Absicherung des Shops (u.a. vor XSS bzw. Cross-Site-Scripting)</p>
  <p><strong>phpsessid</strong><br>
  Identifikation zwischen Browser und Server zur Herausstellung, ob der jeweilige User einen aktiven Warenkorb besitzt und eingeloggt ist</p>
  <p><strong>session-1</strong><br>
  Identifikation der aktuellen Sitzung, des Benutzer und dessen Warenkorb</p>
  <p><strong>slt</strong><br>
  Wiedererkennung des Kunden bei Rückkehr zum Shop (u.a. auch nach Ablauf der Session)</p>
  <p><strong>sw-cache-hash / sw-states</strong><br>
  Technischer Cookie für die Cache-Funktionalität</p>
  <p><strong>timezone</strong><br>
  Abspeicherung der Zeitzone (u.a. für Zeitangaben in E-Mails)</p>
  <p><strong>x-ua-device:</strong><br>
  Ermittlung des verwendeten Endgerätes (u.a. für die korrekte Anzeige des Shops)</p>
  -->

  <h3>2. Funktionale Cookies</h3>
  <p>Cookies zur Optimierung und Personalisierung deiner Nutzungserfahrung sowie zur Bereitstellung zusätzlicher
    Features.</p>
  <p><strong>sUniqueID</strong><br>
    Zuordnung der Merkliste und Aktivierung bei Nutzung der Merkliste</p>
  <p><strong>Statistik &amp; Tracking</strong></p>
  <p>Analytische Cookies zur Verbesserung der Performance unseres Shops und Durchführung geschäftsrelevanter
    Auswertungen.</p>


  <p><strong>Stand vom 30.08.2022</strong></p>
</template>

<script>
export default {
  name: "datenschutzhinweise-de",
}
</script>

<style scoped>

</style>