<template>
  <customer-page>
    <template v-slot:page-subline>
      {{ $t(`Passwort vergessen`) }}
    </template>
    <template v-slot:page-content>
      <password-form ref="passwordForm">

      </password-form>
      <div class="text-center">
        <hr class="mt-5 mb-3">
        <button class="btn btn-lg btn-primary" @click="onPasswordRecover">> {{ $t(`Passwort speichern`) }}</button>
      </div>

    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import PasswordForm from "../../component/form/password-form";
export default {
  name: "password-recover",
  components: {PasswordForm, CustomerPage},
  methods: {
    onPasswordRecover() {
      this.$refs.passwordForm.validate().then((result) => {
        easyLizeApp.apiClient.passwordRecover((response) => {
          this.$router.replace({ name: 'home' });
        }, this.$route.query.hash, result.password);
      });
    }
  }
}
</script>

<style scoped>

</style>