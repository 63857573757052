export default {
    "glas": {
        co2Bilanz: 0.320,
        title: {
            "de-DE": "Glas",
            "en-GB": "Glass",
            "zh-CN": "玻璃"
        },
        "image":  require('../../assets/images/33358_saucebottle_symbolverpackung_glas_kreis.png')
    },
    "ppk": {
        co2Bilanz: 0.158,
        title: {
            "de-DE": "Papier/Pappe/Karton",
            "en-GB": "Paper/Cardboard",
            "zh-CN": "纸张/纸板/纸箱"
        },
        "image": require('../../assets/images/54032_box_symbolverpackung_ppk_kreis.png')
    },
    "eisenmetalle": {
        co2Bilanz: 1.226,
        title: {
            "de-DE": "Eisenmetalle",
            "en-GB": "Ferrous metals",
            "zh-CN": "黑色金属"
        },
        "image":require('../../assets/images/13142_oilcan_symbolverpackung_eisenmetalle_kreis.png')
    },
    "aluminium": {
        co2Bilanz: 2.416,
        title: {
            "de-DE": "Aluminium",
            "en-GB": "Aluminium",
            "zh-CN": "铝合金"
        },
        "image": require('../../assets/images/11856_tube_symbolverpackung_aluminium_kreis.png')
    },
    "kunststoffe": {
        co2Bilanz: 0.488,
        title: {
            "de-DE": "Kunststoffe",
            "en-GB": "Plastics",
            "zh-CN": "塑料制品"
        },
        "image": require('../../assets/images/10653_spraybottle_symbolverpackung_kunststoff_kreis.png')
    },
    "getraenkekartonverpackungen": {
        co2Bilanz: -0.264,
        title: {
            "de-DE": "Getränkeverbundverp.",
            "en-GB": "Beverage carton composite packaging",
            "zh-CN": "饮料纸盒复合包装"
        },
        "image": require('../../assets/images/39970_tetra_symbolverpackung_getraenkeverbundverpackung_kreis.png')
    },
    "sonstigeVerbundverpackungen": {
        co2Bilanz: -0.044,
        title: {
            "de-DE": "Sonstige Verbundverp.",
            "en-GB": "Other component packaging",
            "zh-CN": "其他复合包装"
        },
        "image": require('../../assets/images/53251_snacktube_symbolverpackung_verbundverpackung_kreis.png')
    },
    "sonstigesMaterial": {
        co2Bilanz: -0.104,
        title: {
            "de-DE": "Sonstige Materialien",
            "en-GB": "Other materials",
            "zh-CN": "其他材料"
        },
        "image": require('../../assets/images/31530_corks_symbolverpackung_sonstiges_kreis.png')
    }
}