<template>
  <wizard :show-calculation-help="false" />
</template>

<script>
import Wizard from "../easy-lize/wizard";
export default {
  name: "contract-new-checkout",
  components: {Wizard}
}
</script>
