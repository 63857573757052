<template>
  <a href="#" class="btn btn-primary" @click="onClick">GetProducts</a>
  <div id="productsResul"></div>
</template>

<script>

export default {
  name: "products",
  methods: {
    onClick(e) {
      e.preventDefault();
      document.getElementById('productsResul').innerHTML = '';
          window.easyLizeApp.apiClient.getProducts(function (result) {
        document.getElementById('productsResul').innerHTML = '<pre>' + JSON.stringify(result, null, 2) + '</pre>';
      });
    }
  }
}
</script>
