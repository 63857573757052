export default {
    "food": {
        "label": {
            "de-DE": "FOOD",
            "en-GB": "FOOD",
            "zh-CN": "食品类"
        },
        "branchen": {
            "backery": {
                "label": {
                    "de-DE": "Bäckerei",
                    "en-GB": "Bakery",
                    "zh-CN": "面包/烘焙品"
                },
                "products": {
                    "ppk" :require('../../assets/images/65912_bakerypackage_baeckerei_ppk_food_kreis.png'),
                    "kunststoffe": require('../../assets/images/18312_bread_baeckerei_kunststoffe_food_kreis.png')
                }
            },
            "convenience": {
                "label": {
                    "de-DE": "Convenience",
                    "en-GB": "Convenience",
                    "zh-CN": "便利食品"
                },
                "products": {
                    "glas": require('../../assets/images/49141_salt_baeckerei_glas_food_kreis.png'),
                    "kunststoffe": require('../../assets/images/13085_oilbottle_convenience_kunststoff_food_kreis.png')
                }
            },
            "fresh-meat-fish": {
                "label": {
                    "de-DE": "Fleisch und Fisch (frisch)",
                    "en-GB": "Fresh Meat & Fish",
                    "zh-CN": "肉类和鱼类（新鲜）"
                },
                "products":{
                    "ppk": require('../../assets/images/73965_pappermeat_fleischfisch_ppk_food_kreis.png'),
                    "kunststoffe": require('../../assets/images/30006_traybeef_fleischfisch_kunststoff_food_kreis.png')
                }
            },
            "breakfast": {
                "label": {
                    "de-DE": "Frühstück",
                    "en-GB": "Breakfast",
                    "zh-CN": "早餐"
                },
                "products": {
                    "glas": require('../../assets/images/25815_chocolatejar_fruestueck_glas_food_kreis.png'),
                    "ppk": require('../../assets/images/10743_cerealbox_fruestueck_ppk_food_kreis.png')
                }
            },
            "beverages": {
                "label": {
                    "de-DE": "Getränke",
                    "en-GB": "Beverages",
                    "zh-CN": "饮料"
                } ,
                "products": {
                    "glas": require('../../assets/images/88245_winebottle_getraenke_glas_food_kreis.png'),
                    "getraenkekartonverpackungen": require('../../assets/images/39970_tetra_getraenke_getraenkeverbundverpackung_food_kreis.png')
                }
            },
            "hot-drinks": {
                "label": {
                    "de-DE": "Heißgetränke",
                    "en-GB": "Hot Drinks",
                    "zh-CN": "热饮"
                },
                "products": {
                    "ppk": require('../../assets/images/10345_boxtea_heissgetraenke_ppk_food_kreis.png'),
                    "sonstigeVerbundverpackungen": require('../../assets/images/58618_pouch_heissgetraenke_verbundverpackung_food_kreis.png')
                }
            },
            "canned-food": {
                "label": {
                    "de-DE": "Konserven",
                    "en-GB": "Canned Food",
                    "zh-CN": "罐头"
                } ,
                "products": {
                    "eisenmetalle": require('../../assets/images/11117_tuna_konserven_glas_food_kreis.png'),
                    "glas": require('../../assets/images/29665_jar_konserven_eisenmetalle_food_kreis.png')
                }
            },
            "dairy": {
                "label": {
                    "de-DE": "Vorratsschrank",
                    "en-GB": "Dairy",
                    "zh-CN": "储备食品"
                },
                "products": {
                    "kunststoffe": require('../../assets/images/61062_yogurt_vorrat_kunststoff_food_kreis.png'),
                    "getraenkekartonverpackungen": require('../../assets/images/83690_milkpack_vorrat_getraenkeverbundverpackung_food_kreis.png')

                }
            },
            "fruits-vegetables": {
                "label": {
                    "de-DE": "Obst und Gemüse",
                    "en-GB": "Fruits & Vegetables",
                    "zh-CN": "水果和蔬菜"
                },
                "products": {
                    "kunststoffe":require('../../assets/images/62936_raspberries_obstgemuese_kunststoff_food_kreis.png'),
                    "sonstigeVerbundverpackungen": require('../../assets/images/10189_potato_obstgemuese_verbundverpackung_food_kreis.png')
                }
            },
            "snacking": {
                "label": {
                    "de-DE": "Süßwaren und Knabbereien",
                    "en-GB": "Snacking",
                    "zh-CN": "甜点和零食"
                },
                "products": {
                    "kunststoffe":require('../../assets/images/67187_mueslibar_snack_kunststoff_food_kreis.png'),
                    "aluminium": require('../../assets/images/82733_chipsbag_snack_aluminium_food_kreis.png')
                }

            },
            "freezer": {
                "label": {
                    "de-DE": "Tiefkühlkost",
                    "en-GB": "Freezer",
                    "zh-CN": "冷冻食品"
                },
                "products":{
                    "kunststoffe":require('../../assets/images/73427_frostedplastic_tiefkuehlkost_kunststoff_food_kreis.png'),
                    "ppk": require('../../assets/images/11868_pizzabox_tiefkuehlkost_ppk_food_kreis.png')
                }

            },
        }
    },
    "non-food": {
        "label": {
            "de-DE": "NONFOOD",
            "en-GB": "NONFOOD",
            "zh-CN": "非食品"
        },
        "branchen": {
            "office-school": {
                "label": {
                    "de-DE": "Büro und Schule",
                    "en-GB": "Office & School",
                    "zh-CN": "办公和学校用品"
                },
                "products": {
                    "ppk": require('../../assets/images/52927_paintpens_bueroschule_ppk_nonfood_kreis.png'),
                    "sonstigeVerbundverpackungen":  require('../../assets/images/87311_lipbalm_bueroschule_verbundverpackung_nonfood_kreis.png')
                }
            },
            "cleaning": {
                "label": {
                    "de-DE": "Reinigung",
                    "en-GB": "Cleaning",
                    "zh-CN": "清洁用品"
                },
                "products": {
                    "kunststoffe": require('../../assets/images/10490_dishwasher_reinigung_kunststoff_nonfood_kreis.png'),
                    "eisenmetalle": require('../../assets/images/57648_paintcan_reinigung_eisenmetalle_nonfood_kreis.png')
                }
            },
            "electronics": {
                "label": {
                    "de-DE": "Elektronik",
                    "en-GB": "Electronics",
                    "zh-CN": "电子产品"
                },
                "products":{
                    "ppk": require('../../assets/images/11838_battery_elektronik_ppk_nonfood_kreis.png'),
                    "eisenmetalle": require('../../assets/images/13860_digipak_eletronik_eisenmetalle_nonfood_kreis.png')
                }
            },
            "health-beauty-baby": {
                "label": {
                    "de-DE": "Gesundheit, Beauty & Baby",
                    "en-GB": "Health, Beauty & Baby",
                    "zh-CN": "健康、美容产品和婴儿用品"
                },
                "products": {
                    "kunststoffe": require('../../assets/images/50038_paperbag_gesundheitbb_kunststoff_fodd_kreis.png'),
                    "glas": require('../../assets/images/14447_creamjar_gesundheitbb_glas_nonfood_kreis.png')
                }
            },
            "household": {
                "label": {
                    "de-DE": "Haushalt",
                    "en-GB": "Household",
                    "zh-CN": "家庭用品"
                },
                "products": {
                    "ppk": require('../../assets/images/89899_candlebox_haushalt_ppk_nonfood_kreis.png'),
                    "aluminium": require('../../assets/images/40605_metallictray_haushalt_aluminium_nonfood_kreis.png')
                }
            },
            "fashion": {
                "label": {
                    "de-DE": "Mode",
                    "en-GB": "Fashion",
                    "zh-CN": "时尚产品"
                },
                "products": {
                    "ppk": require('../../assets/images/61964_shoebox_mode_ppk_nonfood_kreis.png'),
                    "kunststoffe": require('../../assets/images/23541_tshirt_mode_kunststoff_nonfood_kreis.png')
                }
            },
            "outdoor-leisure": {
                "label": {
                    "de-DE": "Outdoor und Freizeit",
                    "en-GB": "Outdoor & Leisure",
                    "zh-CN": "户外和休闲用品"
                },
                "products": {
                    "ppk": require('../../assets/images/61964_shoebox_mode_ppk_nonfood_kreis.png'),
                    "kunststoffe":require('../../assets/images/62504_fishingline_outdoorfreizeit_kunststoff_nonfood_kreis.png')
                }
            },
            "home-improvement": {
                "label": {
                    "de-DE": "Schönes Zuhause",
                    "en-GB": "Home Improvement",
                    "zh-CN": "精美家居"
                },
                "products": {
                    "glas": require('../../assets/images/48040_diffuser_zuhause_glas_nonfood_kreis.png'),
                    "eisenmetalle": require('../../assets/images/76986_candle_zuhause_eisenmetalle_nonfood_kreis.png')
                }
            },
            "garden": {
                "label": {
                    "de-DE": "Garten",
                    "en-GB": "Garden",
                    "zh-CN": "花园用品"
                },
                "products": {
                    "ppk": require('../../assets/images/30926_plantbox_garten_ppk_nonfood_kreis.png'),
                    "kunststoffe":require('../../assets/images/44386_salad_garten_kunststoff_nonfood_kreis.png')
                }
            },
            "others": {
                "label": {
                    "de-DE": "Sonstiges",
                    "en-GB": "Others",
                    "zh-CN": "其他"
                },
                "products": {
                    "ppk": require('../../assets/images/56773_cigarette_sonstiges_ppk_nonfood_kreis.png')
                }
            },
        }
    }
};