<template>
  <messages-container />
  <wizard-step1 v-if="wizard.steps.step1.display" :wizard="wizard" :show-calculation-help="showCalculationHelp" />
  <wizard-step2 ref="step2" v-if="wizard.steps.step2.display" :wizard="wizard" :show-calculation-help="showCalculationHelp" />
  <wizard-step3 v-if="wizard.steps.step3.display" :wizard="wizard" :show-calculation-help="showCalculationHelp" />
  <wizard-step4 v-if="wizard.steps.step4.display" :wizard="wizard" :show-calculation-help="showCalculationHelp" />
  <wizard-step5 v-if="wizard.steps.step5.display" :wizard="wizard" :show-calculation-help="showCalculationHelp" />
</template>

<script>
import WizardStep1 from "../../component/wizard/wizard-step1";
import WizardStep2 from "../../component/wizard/wizard-step2";
import WizardStep3 from "../../component/wizard/wizard-step3";
import WizardStep4 from "../../component/wizard/wizard-step4";
import WizardStep5 from "../../component/wizard/wizard-step5";
import MessagesContainer from "../../component/utils/messages-container";
import {useHead} from "@vueuse/head";
export default {
  name: "wizard",
  components: {MessagesContainer, WizardStep5, WizardStep4, WizardStep3, WizardStep2, WizardStep1},
  props: {
    showCalculationHelp: {
      type: Boolean,
      required: false,
      default: true
    },
    contractId: {
      type: String,
      required: false,
      default: null
    }
  },
  beforeCreate() {
    useHead({
      title: this.$t('Auswahl Verpackungssortiment | EASY-LIZE Verpackungslizenzierung'),
      meta: [
        { name: 'description', content: this.$t('EASY-LIZE führt Sie in nur wenigen Klicks und verständlich erklärt durch die Materialauswahl Ihrer Verpackungen und den gesamten Lizenzierungsprozess.') },
      ],
    });
  },
  created() {
    if(this.$route.name === 'contract_new_checkout' && this.$route.params.contractYear !== "" && this.$route.params.contractId !== "") {
      this.wizard.data.lizenzjahr = parseInt(this.$route.params.contractYear);
      this.wizard.data.contractId = this.$route.params.contractId;
    }
    easyLizeApp.apiClient.getContext((context) => {
      if(context.customer) {
        this.wizard.data.customer = context.customer;
      }
    });
    this.$emitter.on('easyLizeApiToken:expired', this._tokenExpired.bind(this));
  },
  data() {
    return {
      wizard: {
        steps: {
          step1: {
            display: this.showCalculationHelp
          },
          step2: {
            display: false
          },
          step3: {
            display: !this.showCalculationHelp
          },
          step4: {
            display: false
          },
          step5: {
            display: false
          }
        },
        clearCart: true,
        data: {
          contractId: null,
          branchen: {},
          lizenzjahr: null,
          co2Bilanz: {
            total: 0.0
          },
          customer: {
            company: '',
            lucidNumber: '',
            vatId: '',
            taxNumber: '',
            salutationId: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            emailAddress2: '',
            phoneNumber: '',
            companyAddress: {
              zipcode: '',
              city: '',
              countryId: '',
              street: '',
              additionalAddressLine1: '',
              additionalAddressLine2: ''
            },
            differentBillingAddress: false,
            billingAddress: {
              company: '',
              zipcode: '',
              city: '',
              countryId: '',
              street: '',
              additionalAddressLine1: '',
              additionalAddressLine2: ''
            },
          },
          payment: {
            paymentMethodId: null,
            invoiceInterval: null
          }
        },
        stepHistory: [],
        showStep: this.showStep,
        goBack: this.goBack
      }
    }
  },
  methods: {
    _tokenExpired() {
      this.$messenger.pushMessage('danger', 'Die Sitzung ist abgelaufen', 'easylize_wizard');
      if(this.wizard.stepHistory.length > 0) {
        window.location.href = this.$getUrl(this.$route, "/guide");
      }
    },
    _getCurrentStep() {
      for(let step in this.wizard.steps) {
        if(this.wizard.steps[step].display) {
          return step;
        }
      }
      return null;
    },
    _displayStep(displayStep) {
      if(typeof this.wizard.steps[displayStep] !== 'undefined') {
        for (let step in this.wizard.steps) {
          this.wizard.steps[step].display = false;
        }
        this.wizard.steps[displayStep].display = true;
        //this.$refs.step2.loadProducts();
      } else {
        console.error('step ' + displayStep + ' not found!');
      }
    },
    showStep(nextStep) {
      const currentStep = this._getCurrentStep();
      if(currentStep && typeof this.wizard.steps[nextStep] !== 'undefined') {
        this.wizard.stepHistory.push(currentStep);
      }
      this._displayStep(nextStep);
    },
    goBack() {
      this.clearCart = false;
      if(this.wizard.stepHistory.length > 0) {
        const prevStep = this.wizard.stepHistory.pop();
        this._displayStep(prevStep);
      } else {
        this.$router.replace({ name: 'home', query: { keepCart: true } });
      }
    }
  }
}
</script>
