<template>
  <h2>Cookies </h2>

  <span id="cookiebotscript"></span>

  <hr>

  <h1>Data Policy</h1>
  ​
<h2>A. General information</h2>


<h3>1. Responsible body and contact details </h3>
<p>Controller in the sense of the GDPR for these Internet pages is</p>

<p>EKO-PUNKT GmbH &amp; Co. KG<br>
Waltherstra&szlig;e 49&ndash;51<br>
51069 K&ouml;ln</p>

<p>Further company details can be found in the <a href="https://www.remondis.de/en/imprint/">Imprint</a>.<a href="#_msocom_1" name="_msoanchor_1">[ZB1]</a>&nbsp;</p>
<p>Contact details of our data protection officer:</p>
<p>REMONDIS SE &amp; Co.KG<br>
Brunnenstra&szlig;e 138<br>
44536 L&uuml;nen</p>
<p><a href="mailto:datenschutz@remondis.de">datenschutz@remondis.de</a></p>
<p>REMONDIS SE &amp; CO. KG acts as a single point of contact for data subjects and is responsible for information obligations pursuant to Art. 13 and 14 GDPR, data protection impact assessments pursuant to Art. 24, 32 GDPR.</p>

<h3>2. Declaration of joint responsibility</h3>
<p>REMONDIS SE &amp; Co. KG and its subsidiaries and associated companies exercise joint responsibility in many areas in accordance with &sect; 26 of the GDPR. Services and supplies are generally provided by the subsidiaries of our Group, which use the central data processing system in our Group for this purpose. The joint responsibility extends in particular to the following areas:</p>
<ul>
    <li>Customer databases</li>
    <li>- Application management &amp; recruiting</li>
</ul>
<p>Within the framework of joint responsibility, REMONDIS SE &amp; CO KG and its subsidiaries and associated companies have agreed which of them is responsible for which duties under data protection law (in particular information duties in accordance with Art. 13, 14 GDPR). Within the scope of joint responsibility, REMONDIS SE &amp; Co. KG and the subsidiary or associated company concerned are jointly obliged to provide the information required under Art. 13, 14 GDPR. The companies shall provide the respective requested company with the necessary information from their area of activity. The companies shall inform each other without delay of the legal positions asserted by data subjects and provide each other with all information necessary to respond to requests for information. Data protection rights can be asserted against all companies involved.</p>
<p>In addition, some processing operations may give rise to joint responsibility between REMONDIS companies or with third parties. These are dealt with in the context of the respective processing (Part B).</p>
<h3>3. Data categories, purposes and legal basis of data processing</h3>
<p>We process your personal data in accordance with the provisions of the General Data Protection Regulation (GDPR) and the Federal Data Protection Act (BDSG) as well as all other applicable legal provisions. The collection and processing of data is determined by the service or information you have chosen.</p>
<p>In general, we collect the following information:</p>
<ul>
<li>Last name, first name, salutation</li>
<li>E-mail address</li>
<li>Communication data (IP address, device information)</li>
<li>Usage data (web pages visited, access times, interest in content)</li>
<li>Address</li>
<li>Telephone number</li>
</ul>
<p>The specific data collection and processing can be found in the individual processing operations (Part B) as well as in the respective provisions of our contractual documents.</p>

<h3>4. Data recipients</h3>
<p>We only transfer your personal data to third parties in the following cases:</p>
<ul>
<li>The transfer takes place in compliance with a legal requirement under which we are obliged to report or pass on the data.</li>
<li>The transfer of data takes place to external companies that act as order processors on our behalf or that act on our behalf (this concerns, for example, courier and logistics companies, call centres, external computer centres, EDP and IT applications, website management, auditing services, credit institutions, data disposal, etc.).</li>
<li>The transmission of data to third parties takes place on the basis of your consent.</li>
<li>Data is transferred for the purpose of processing a contractual relationship with you pursuant to Art. 6 para. 1 s. 1 lit. b GDPR (e.g. to an affiliated company within the group (cf. item 2) or a subcontractor or to a logistics company).</li>
</ul>

<h3>5. Deletion of data and duration of storage</h3>

<p>In principle, we process and store your data only for the duration of our business relationship, including the initiation and execution of the contract as well as the statutory retention periods.</p>

<p>If the data is no longer required for the fulfilment of contractual or legal obligations and rights, it is regularly deleted, unless its &ndash; temporary &ndash; further processing is necessary for the fulfilment of the purposes listed above for an overriding legitimate interest.</p>
<p>Insofar as individual processing of personal data requires other deletion periods, these are mentioned in the description of the individual processing operations under section B.</p>

<h3>6. Processing of your data in a third country</h3>
<p>Data is only transferred to bodies in third countries (states outside the European Union (EU) or the European Economic Area (EEA)) if it is necessary for the execution of an order/contract from or with you, if it is required by law (e.g. reporting obligations under tax law), if appropriate data protection levels exist or if you have given us your consent.</p>
<p>The processing of your data in a third country may also be carried out in connection with the involvement of service providers as part of commissioned processing. If there is no EU Commission decision on an adequate level of data protection for the country in question, we will ensure through appropriate contracts that your rights and freedoms are adequately protected and guaranteed. For more detailed information, please refer to the individual descriptions of the processing operations under section B. </p>
<p>Information on the appropriate or adequate safeguards and on the possibility of obtaining a copy from you can be obtained from the Data Protection Officer upon request.</p>

<h3>7. Your rights as a data subject</h3>
<p>Upon request, we will provide you with written or electronic information as to whether and which of your personal data is stored with us (Art. 15 GDPR) and we will check your entries for deletion (Art. 17 GDPR), correction (Art. 16 GDPR), restriction of processing (Art. 18 GDPR) and transfer (Art. 20 GDPR) of your personal data and execute them if the conditions are met. </p>
<p>For this purpose, please contact:</p>
<p>REMONDIS SE &amp; Co. KG<br />- Data Protection Officer -<br />Brunnenstr. 138<br />D-44536 L&uuml;nen <br /><a href="mailto:datenschutz@remondis.de">datenschutz@remondis.de</a></p>
<p>Insofar as you are of the opinion that the processing of your personal data is carried out unlawfully, you may lodge a complaint with a supervisory authority (Art. 77 GDPR). A list of the data protection officers and their contact details can be found under the following link<u>:&nbsp;<a name="_Hlk83287619"></a><a>https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html</a></u><u><a href="#_msocom_2" name="_msoanchor_2">[ZB2]</a>&nbsp;</u></p>
<h3>8. Information on the right to object</h3>
<p>Insofar as the processing of your personal data is carried out on the basis of Art. 6 para. 1 s. 1 lit. f GDPR (data processing based on a balance of interests) or on the basis of Art. 6 para. 1 sentence 1 lit. e GDPR (data processing in the public interest), you have the right to object to the processing at any time. </p>

<p>We will then in any case no longer process the personal data for the purposes of direct marketing or related profiling and furthermore not for other purposes, unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves the assertion, exercise or defence of legal claims. In this case, you must provide reasons for the objection that arise from your particular situation.</p>

<p>If possible, you should send your objection to the following contact address: </p>

<p>datenschutz@remondis.de</p>
<p>The same applies if you have given your consent to the collection or use of personal data and wish to revoke it. You can revoke such consent at any time with effect for the future by e-mail or letter.</p>

<h3>B. Individual processing</h3>
<h3>1. Logging</h3>
<p>When using the website, certain connection data and data provided by your Internet browser are temporarily stored. The following data is logged for the operation of the website:</p>
<ul type="disc">
<li>IP address of the retrieving computer</li>
<li>Operating system of the retrieving computer</li>
<li>Browser version of the retrieving computer</li>
<li>Name of the retrieved file</li>
<li>Date and time of retrieval</li>
<li>Amount of data transferred</li>
<li>Referring URL</li>
<li>Connection logging</li>
</ul>
<p>The data collection and processing of the log file information is based on our legitimate interest and for the purpose of securing our online offer. The log file information is stored anonymously after the session has ended.</p>
<h3>2. Contact form/ requests for information</h3>
<p>In the interest of communication with customers and interested parties, we offer a contact form on our website where you can request information about our products or contact us in general. In addition to the voluntary information and the content of your message, we require the following information from you:</p>
<p>Company<br />Form of address<br />Last name<br />Email address</p>
<p>We need this information to process your request, to contact you correctly and to send you an answer. Requests received via the contact form are stored as emails and regularly checked to see whether data can be deleted. Should data no longer be required in the context of a relationship with a customer or interested party, or should the customer have a contrary interest, we will delete the data concerned not later than after 180 days, provided that this does not conflict with statutory retention obligations. The legal basis for this data processing is Art. 6 para. 1 lit. f) GDPR. Insofar as contact is made to initiate contractual relations, the legal basis is Art. 6 para.1 lit. b) GDPR.</p>
<h3>3. Matomo</h3>
<p>On our websites we use a version of Matomo which is implemented on our own servers. This is an open source analysis software for the purpose of statistical evaluation of visitor access. Based on this evaluation, we particularly wish to track which of our sites are accessed and how often, but not by whom they are accessed. The implementation of Matomo on our servers does not use any cookies. We only store anonymised user data for collecting general statistical values, without comprehensively analysing user behaviour. A comprehensive analysis of user behaviour is also not possible due to the anonymised collection of the data. The IP address transmitted in the server log-in is anonymised on our server before it is transmitted to the Matomo installation and replaced by a random IP address. It is not possible to trace the IP address using the anonymised data, as time entries are randomised by the Matomo installation.</p>
<p>The purpose of the processing is the statistical analysis of user behaviour to optimise the functionality of our websites. Our legitimate interest lies in the improvement of our website.</p>
<p>The legal basis for the use of Matomo is Art. 6 para. 1 s. 1 lit. f GDPR. </p>
<h3>4. Google Analytics</h3>
<p>We use Google Analytics, a web analysis service of Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland, on our websites.</p>
<p>Google Analytics uses text files which are stored on your device and which make an analysis of the use of the websites visited by you possible, so-called "cookies". In addition, Google Analytics also uses so-called web beacons (invisible graphics). With these cookies and web beacons, information such as visitors to websites can be recorded and analysed. The information generated by cookies and web beacons about the use of our websites (including the users' IP addresses) are transmitted to a Google server, possibly in the USA or other third countries, and stored there. This information can be forwarded to Google's contracting partners by Google. </p>
<p>By incorporating Google Analytics, we pursue the purpose of analysing and adapting user behaviour on our websites in order to improve our offer according to requirements.</p>
<p>The legal basis for the processing of personal data described here is Art. 6 subsection 1 lit. a) GDPR.</p>
<p>You can find detailed information on the processed information at https://privacy.google.com/businesses/adsservices/ and at https://www.google.com/intl/de/policies/privacy/#infocollect under "Data which we receive on the basis of your use of our services&ldquo;.</p>
<p>Further information on data processing by Google in the course of the use of Google services and on the Privacy Shield certification of Google can be found in the section on general information on Google services.</p>
<p>In particular, the following data are processed by Google Analytics:</p>
<p>Device IDs</p>
<p>Online IDs (including Cookie IDs)</p>
<p>IP address</p>
<p>We only use Google Analytics with activated IP anonymisation ("anonymize IP"). In this way, your IP address is curtailed by Google within member states of the European Union or in other contracting states of the European Economic Area Treaty. Only in exceptional cases is the complete IP address transmitted to a Google server in the USA and curtailed there.</p>
<p>For the use of Google Analytics, we have concluded a contract for commissioned processing pursuant to Art. 28 GDPR with Google. Google processes the data by our order, in order to evaluate the uses of our websites and to portray them in reports and also to render the services connected with the use of the website. Google can possibly transmit this information to third parties to the extent provided for by law or to the extent that third parties process these data by order of Google.</p>
<p>In addition, Google is entitled to commission certain sub-contractors and possibly to provide them with data as a result of the commissioned processing agreement with us. You will find a list of these sub-contractors here: https://privacy.google.com/businesses/subprocessors/.</p>
<p>Right of revocation</p>
<p>You have a right of revocation. You can revoke your granted consent at any time under (&hellip;) without stating reasons.</p>
<p>In addition, you can prevent processing of your data by Google Analytics by deactivating the Google Analytics cookie in your browser and clicking on this opt-out link or downloading and installing the plug-in provided by Google here (https://tools.google.com/dlpage/gaoptout?hl=de).</p>
<p>It is possible that only limited use of our website is possible in the event of deactivation.</p>
<p>The processed information is only stored for a long as is necessary for the planned purpose or has been prescribed by law.</p>
<p>Provision of the personal data is not mandatory and has not been prescribed by law or by contract, nor is it necessary for conclusion of a contract.</p>
<h3>5. Cookiebot / "CookieConsent", "CookieConsentBulkTicket".</h3>
<p>We use the consent management service Cookiebot, provided by Cybot A/S, Havnegade 39, 1058 Copenhagen, Denmark (Cybot). This allows us to obtain and manage consent from website users for data processing. The processing is necessary to comply with a legal obligation (Art. 7 para. 1DSGVO) to which we are subject (Art. 6 para. 1 p. 1 lit. c DSGVO). For this purpose, the following data is processed with the help of cookies: </p>
<p>Your IP address (the last three digits are set to '0'). Date and time of consent. Browser information URL from which the consent was sent. A randomly generated key of the end user's consent, as proof of consent. </p>
<p>The key and consent status are stored in the browser for 12 months using the "CookieConsent" cookie. This preserves your cookie preference for subsequent page requests. With the help of the key, their consent can be proven and tracked. </p>
<p>If you enable the "Collective Consent" service feature to enable consent for multiple web pages through a single end-user consent, the service will also store a separate, random, unique ID with your consent. If all of the following criteria are met, this key is stored in the third-party cookie "CookieConsentBulkTicket" in your browser in encrypted form: you enable the collective consent feature in the service configuration. You allow third-party cookies via browser settings. You have disabled "Do not track" via browser settings. You accept all or at least certain types of cookies when you give consent. </p>
<p>The functionality of the website is not guaranteed without the processing. </p>
<p>Cybot is a recipient of your personal data and acts as a processor for us. </p>
<p>The processing takes place in the European Union. You can find more information about objection and removal options vis-&agrave;-vis Cybot at: https://www.cookiebot.com/de/privacy-policy/. </p>
<p>Your personal data will be deleted consecutively after 12 months or immediately after the termination of the contract between us and Cybot. </p>
<p>Please see our general comments about deleting and disabling cookies above.</p>


<p>Version from 01.09.2022</p>




</template>

<script>
export default {
  name: "datenschutzhinweise-en"
}
</script>

<style scoped>

</style>