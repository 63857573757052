<template>

  <div class="bg-red-circle">
    <div class="circle"></div>
  </div> 


  <section id="step-3" class="easy-step step-3" data-step="3">
    <div class="container-fluid container-xxl p-0">
      <div class="row g-0 position-relative">
        <div class="col-center">
<!--
          <div class="bg-red-circle">
            <div class="circle"></div>
          </div>
-->
          <max-order-volume-modal v-if="showMaxOrderVolumeModal" @closed="showMaxOrderVolumeModal = false" />
          <cart @cart:loaded="onCartLoaded" ref="cart" :contract-year="wizard.data.lizenzjahr">
            <template #default="{ products, cart, isLoading, params, getTotalItemsNetPrice, isZeroInput }">
              <loader-overlay :is-loading="isLoading" />
              <div class="col-12 easy-container omg-layout" v-if="cart">
                <messages-container />
                <div class="row">
                  <div class="col-12 col-lg-4 order-2 order-lg-1">
                    <div class="section-header mt-lg-5">
                      {{ $t(`Preisberechnung`) }}
                      <table class="w-100 mt-3 fs-16">
                        <!--
                        <tr v-if="getMinimumValueItem(products)">
                          <td class="w-100">{{ getMinimumValueItem(products).name }}</td>
                          <td class="text-end">{{ $filters.formatNumber(getMinimumValueItem(products).totalPrice) }}</td>
                          <td class="text-end">EUR</td>
                        </tr>
                        <tr>
                          <td class="w-100">{{ $t(`Nettopreis`) }}</td>
                          <td class="text-end">{{ $filters.formatNumber(getTotalItemsNetPrice()) }}</td>
                          <td class="text-end">EUR</td>
                        </tr>
                      -->
                        <template v-if="hasVoucherItem(cart)" v-for="voucherItem in getVoucherItems(cart)">
                          <tr>
                            <td>{{ $t('Gutscheincode') }} </td>
                            <td colspan="2" style="min-width:175px;" class="text-end">{{ voucherItem.payload.code }} (<a href="#" class="text-danger" @click="_removeVoucherItem($event, voucherItem.id)">{{ $t(`entfernen`) }}</a>)</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td class="text-end">{{ $filters.formatNumber(voucherItem.price.unitPrice) }}</td>
                            <td class="text-end">EUR</td>
                          </tr>
                        </template>
                        <tr v-if="!hasVoucherItem(cart)">
                          <td colspan="3" class="voucher-table-col">
                            <div class="accordion accordion-flush" id="accordionFlushVoucher">
                              <div class="accordion-item">
                                <span class="accordion-header" id="accordionFlushVoucherToggle">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{ $t('Gutscheincode eingeben') }}
                                  </button>
                                </span>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="accordionFlushVoucherToggle" data-bs-parent="#accordionFlushVoucher">
                                  <div class="input-group mb-3">
                                    <input class="form-control" v-model="voucherCode" type="text" @keyup="onVoucherCodeChanged">
                                    <button class="btn btn-primary" @click="applyVoucher" :disabled="voucherCode == ''">&gt; {{ $t('Code übernehmen') }}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan=3>
                            <hr>
                          </td>
                        </tr>

                        <tr>
                          <!--<td class="w-100">{{ $t(`Zwischensumme`) }}</td>-->
                          <td class="w-100 fw-bold">{{ replacePlaceholders($t("Ihr Angebot für {contractYear} netto", { contractYear: wizard.data.lizenzjahr }), { contractYear: wizard.data.lizenzjahr }) }}</td>
                          <td class="text-end fw-bold">{{ $filters.formatNumber(cart.price.netPrice) }}</td>
                          <td class="text-end fw-bold">EUR</td>
                        </tr>

                        <tr v-if="showTaxPrices" v-for="tax in cart.price.calculatedTaxes">
                          <td class="w-100">{{ $t(`MwSt.`) }} {{ tax.taxRate }}%</td>
                          <td class="text-end">{{ $filters.formatNumber(tax.tax) }}</td>
                          <td class="text-end">EUR</td>
                        </tr>

                        <tr v-if="showTaxPrices">
                          <!--
                          <td class="w-100"><b>{{ $t(`Gesamtsumme`) }}</b></td>
                          <td class="text-end"><b>{{ $filters.formatNumber(cart.price.totalPrice) }}</b></td>
                          <td class="text-end"><b>EUR</b></td>
                          -->
                          <td class="w-100">{{ $t(`Rechnungsbetrag`) }}</td>
                          <td class="text-end">{{ $filters.formatNumber(cart.price.totalPrice) }}</td>
                          <td class="text-end">EUR</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td colspan=2 style="min-width:175px;"></td>
                        </tr>
                      </table>
                    </div>
                    <div class=""></div>
                  </div>
                  <div class="col-12 col-lg-5 order-1 order-lg-2 offset-lg-1">
                    <div class="section-header mt-lg-5">
                      {{ replacePlaceholders($t(`Jahresmenge Verpackungen {contractYear}`, { contractYear: wizard.data.lizenzjahr }), { contractYear: wizard.data.lizenzjahr }) }}
                      <table class="w-100 mt-3 fs-16">
                        <template v-for="product in products">
                          <tr v-if="(product.inputWeight > 0.0 || isZeroInput()) && product.type !== 'minimumValue'">
                            <template v-if="params.addPeriodQuantityShowUnitPrice">
                              <td class="pe-3">{{ $t(product.name) }}</td>
                              <td class="text-end">{{ $filters.formatNumber(product.inputWeight, 4) }} kg ({{ $filters.formatNumber(product.unitPrice, 4)}} &euro;/kg)</td>
                            </template>
                            <template v-else>
                              <td class="w-100">{{ $t(product.name) }}</td>
                              <td class="text-end">{{ $filters.formatNumber(product.inputWeight, 4) }}</td>
                              <td class="text-end"> kg</td>
                            </template>

                          </tr>
                        </template>
                        <tr>
                          <td></td>
                          <td colspan="2" style="min-width:100px;">&nbsp;</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="!showTaxPrices">
                  <div class="col-12 pt-5">
                    {{ $t(`Alle angegebenen Preise sind Nettopreise und verstehen sich zzgl. der gesetzlichen Mehrwertsteuer in Höhe von 19%`) }}.
                  </div>
                </div>

                <div class="co2-saving-wrapper my-lg-5 d-block d-xl-none">
                  <hr class="d-block d-lg-none">
                  <div class="co2-saving">
                    {{ replacePlaceholders($t(`Ihr Klimaschutz-Beitrag {contractYear}`), { contractYear: wizard.data.lizenzjahr }) }}:<br>
                    {{ replacePlaceholders($t(`Jährlich {savedCo2} kg eingespartes`), { savedCo2: $filters.formatNumber(wizard.data.co2Bilanz.total, 2) }) }} CO<sub>2</sub><br>
                    <div class="co2-saving-reference">{{ $t(`Der berechnete Beitrag basiert auf Werten der`) }} <br><a href="https://www.oeko.de/fileadmin/oekodoc/Duale_Systeme_Oekobilanz_Endbericht.pdf" target="_blank">{{ $t(`Studie des Öko-Institut e.V. Darmstadt vom 24.04.2022`) }}</a></div>
                  </div>
                </div>          

              </div>
            </template>
          </cart>
          <div class="step-nav mt-5">
            <!--<a class="btn btn-primary me-4">&lt; Zurück</a><a class="btn btn-primary">> Weiter</a>-->
            <router-link :to="{ name: 'contract_new_period', params: { contractId: wizard.data.contractId, contractYear: wizard.data.lizenzjahr }}" class="btn btn-primary me-4 demo-only" v-if="wizard.data.contractId">&lt; {{ $t(`zurück`) }}</router-link>
            <button v-else class="btn btn-primary me-4 demo-only" @click="wizard.goBack()">&lt; {{ $t(`zurück`) }}</button>

            <button class="btn btn-primary demo-only" v-if="wizard.data.customer && wizard.data.customer.active" @click="gotoNextStep('step5')"> > {{ $t(`weiter`) }}</button>
            <button class="btn btn-primary demo-only" v-else @click="gotoNextStep('step4')"> > {{ $t(`weiter`) }}</button>
          </div>

          <div class="co2-saving-wrapper mt-lg-5 d-none d-xl-block">
            <hr class="d-block d-lg-none">
            <div class="co2-saving">
              {{ replacePlaceholders($t(`Ihr Klimaschutz-Beitrag {contractYear}`), { contractYear: wizard.data.lizenzjahr }) }}:<br>
              {{ $t(`Jährlich`) }} {{ $filters.formatNumber(wizard.data.co2Bilanz.total, 2) }} kg {{ $t(`eingespartes`) }} CO<sub>2</sub><br>
              <div class="co2-saving-reference">{{ $t(`Der berechnete Beitrag basiert auf Werten der`) }} <br><a href="https://www.oeko.de/fileadmin/oekodoc/Duale_Systeme_Oekobilanz_Endbericht.pdf" target="_blank">{{ $t(`Studie des Öko-Institut e.V.  Darmstadt vom 24.04.2022`) }}</a></div>
            </div>
          </div>          

        </div>
        <wizard-circle-bar current-step="step3" :show-calculation-help="showCalculationHelp" />
      </div>
    </div>
  </section>
</template>

<script>
import WizardCircleBar from "./wizard-circle-bar";
import Cart from "../cart/cart";
import LoaderOverlay from "../utils/loader-overlay";
import Co2BilanzCalculator from "../../../utils/products/co2BilanzCalculator";
import MessagesContainer from "../utils/messages-container";
import {replacePlaceholders, scrollTop} from "../../../utils/utils";
import MaxOrderVolumeModal from "../modal/max-order-volume-modal.vue";

const co2BilanzCalculator = new Co2BilanzCalculator();

export default {
  name: "wizard-step3",
  components: {MaxOrderVolumeModal, MessagesContainer, LoaderOverlay, Cart, WizardCircleBar},
  props: {
    wizard: {
      type: Object,
      required: true
    },
    showCalculationHelp: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    showTaxPrices() {
      return this.context && this.context.customer;
    }
  },
  created() {
    scrollTop();
    easyLizeApp.apiClient.getContext((context) => {
      this.context = context;
    });
  },
  data() {
    return {
      voucherCode: '',
      showMaxOrderVolumeModal: false,
      context: null
    };
  },
  methods: {
    replacePlaceholders,
    _removeVoucherItem(e, voucherItemId) {
      e.preventDefault();
      this.$refs.cart.removeVoucher(voucherItemId);
    },
    isNullSubmit(products) {

      return false;
    },
    getMinimumValueItem(products) {
      for(let productId in products) {
        if(products[productId].type === 'minimumValue' && products[productId].totalPrice > 0) {
          return products[productId];
        }
      }
      return null;
    },
    onCartLoaded(cart, products) {
      this.wizard.data.co2Bilanz = co2BilanzCalculator.calculateFromProducts(products);
    },
    onVoucherCodeChanged(e) {
      if(e.keyCode === 13) {
        this.applyVoucher();
      }
    },
    applyVoucher() {
      if(this.voucherCode == '') {
        return;
      }
      this.$refs.cart.applyVoucher(this.voucherCode).then((result) => {
        if(!result.success) {
          switch (result.code) {
            case 'promotion-not-found':
            case 'promotion-not-eligible':
              this.$messenger.pushMessage('danger', this.$t('Der Gutscheincode wurde nicht gefunden'));
              break;
            default:
              this.$messenger.pushMessage('danger', this.$t('Der Gutscheincode wurde nicht gefunden'));
              break;
          }
        } else {
          this.voucherCode = ''
        }
      });
    },
    getVoucherItems(cart) {
      if(!cart) {
        return [];
      }
      return cart.lineItems.filter((item) => {
        return item.type === 'promotion';
      });
    },
    hasVoucherItem(cart) {
      if(!cart) {
        return false;
      }
      return cart.lineItems.filter((item) => {
        return item.type === 'promotion';
      }).length > 0;
    },
    gotoNextStep(stepkey) {
      if(this.$refs.cart.isEmpty() || !this.$refs.cart.isValid()) {
        return;
      }

      this.$refs.cart.isMaxOrderVolumeReached().then((isMaxOrderVolumeReached) => {
        if(isMaxOrderVolumeReached) {
          this.showMaxOrderVolumeModal = true;
        } else {
          this.wizard.showStep(stepkey);
        }
      });
    }
  }
}
</script>
