<template>
  <div id="main" class="main position-relative h-100" v-if="!isLoading">
    <nav class="navbar navbar-expand-lg bg-light fixed-top align-items-start">
      <div class="container-fluid container-xxllll p-0">
        <div class="row g-0 w-100">
          <div class="col d-flex flex-column w-100">
            <div class="first-row w-100 d-flex flex-row align-items-start justify-content-between">
              <a v-if="!testing" class="navbar-brand d-block" href="https://www.easy-lize.de" rel="preload">
                <img id="logo" :src="logoUrl" alt="logo" />
              </a>
              <a v-if="testing" class="navbar-brand d-block" :href="$getUrl($route, '/')" rel="preload">
                <img id="logo" :src="logoUrl" alt="logo" />
              </a>

              <div class="collapse navbar-collapse bg-white" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

                  <li class="nav-item d-block d-lg-none">
                    <a class="nav-link text-center" href="https://www.easy-lize.de/checken/" target="_blank">{{ $t(`Checken`) }}</a>
                  </li>
                  <li class="nav-item active d-block d-lg-none">
                    <a class="nav-link text-center active" aria-current="page" :href="$getUrl($route, '/')">{{ $t(`Lizenzieren`) }}</a>
                  </li>
                  <li class="nav-item d-block d-lg-none">
                    <a class="nav-link text-center" href="https://www.easy-lize.de/umwelt-schuetzen/" target="_blank">{{ $t(`Umwelt schützen`) }}</a>
                  </li>
                  <li class="nav-item d-block d-lg-none">
                    <a class="nav-link text-center" href="https://www.easy-lize.de/mehr-wissen/" target="_blank">{{ $t(`Mehr Wissen`) }}</a>
                  </li>

                  <li class="nav-item" v-if="this.testing">
                    <div class="input-group">
                      <input type="date" v-model="testingDate" @change="onChangeTestDate">
                      <div class="input-group-append">
                        <button class="btn btn-outline-danger btn-reset-debug-date" type="button" v-if="testingDate" @click="onResetTestDate">X</button>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link text-center" v-if="showGuideLink" aria-current="page" :href="$getUrl($route, '/guide/')">&gt; {{ $t(`Für Erstlizenzierer`) }}</a>
                  </li>

                  <li class="nav-item" v-if="customerIsAuthorized">
                    <a class="nav-link text-center" aria-current="page" :href="$getUrl($route, '/messages')">{{ $t(`Nachrichten`) }}</a>
                  </li>
                  <li class="nav-item" v-if="customerIsAuthorized">
                    <a class="nav-link text-center" aria-current="page" :href="$getUrl($route, '/contracts')">{{ $t(`Verträge`) }}</a>
                  </li>
<!---->
                  <li class="navbar-nav text-center" v-if="!isLoadingLanguages">
                    <div class="nav-item dropdown" v-if="selectedLanguage">
                      <a class="nav-link dropdown-toggle" href="#"
                         id="navbarDropdownLanguage" role="button"
                         data-bs-toggle="dropdown" aria-expanded="false"> {{ selectedLanguage.code }}
                      </a>
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownLanguage">
                        <li v-for="language in languages" class="text-center"><a href="" class="dropdown-item locale-select" @click="onChangeLanguage($event, language)">{{ language.code }}</a></li>
                      </ul>
                    </div>
                  </li>
                  
                  <li class="ms-3 nav-item text-center d-flex flex-column justify-content-center" v-if="!isLoadingContext && !customerIsAuthorized">
                    <a :href="$getUrl($route, '/login')"><i class="bi bi-person"></i></a>
                  </li>
                  <li class="nav-item text-center">
                    <div class="nav-item dropdown" v-if="!isLoadingContext && customerIsAuthorized">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-person-fill"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item text-center" :href="$getUrl($route, '/customer')">{{ $t(`Stammdaten`) }}</a></li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item text-center" href="#" @click="onLogout">{{ $t(`Abmelden`) }}</a></li>
                      </ul>
                    </div>
                  </li>


                </ul>
              </div>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                      aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <!--/first-row-->

            <div class="second-row w-100 d-none d-lg-block">
              <!--<div class="collapse navbar-collapse bg-white" id="navbarSupportedContent">-->
              <ul :class="'navbar-nav me-auto' + (showSecondNavigation ? '' : ' d-none')">
                <li class="nav-item">
                  <a class="nav-link text-center text" href="https://www.easy-lize.de/checken/" target="_blank">{{ $t(`Checken`) }}</a>
                </li>
                <li class="nav-item active">
                  <a class="nav-link text-center" aria-current="page" :href="$getUrl($route, '/')">{{ $t(`Lizenzieren`) }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-center" href="https://www.easy-lize.de/umwelt-schuetzen/" target="_blank">{{ $t(`Umwelt schützen`) }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-center" href="https://www.easy-lize.de/mehr-wissen/" target="_blank">{{ $t(`Mehr Wissen`) }}</a>
                </li>
              </ul>
              <!--</div>-->
            </div>
            <!--/second-row-->

          </div>
        </div>
      </div>
    </nav>


    <div class="content">
      <div class="container-fluid container-xxl p-0">
        <router-view></router-view>
      </div>
    </div>
  </div><!-- /main -->

  <footer id="footer" class="footer">
    <div class="first-footer-row">
      <div class="container-fluid container-xxl p-0">
        <div class="row g-0">
          <div class="col-12 py-4">
            <div class="easy-container omg-layout text-center text-lg-start">
              {{ $t(`EASY-LIZE // Ein Angebot der REMONDIS-Gruppe`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="second-footer-row">
      <div class="container-fluid container-xxl p-0 bg-gray">
        <div class="row g-0">
          <div class="col-12 py-4">
            <div class="easy-container omg-layout">
              <ul class="nav footer-nav d-flex justify-content-center justify-content-lg-start">
                <li class="nav-item">
                  <agb-link class="nav-link" :title="$t(`AGB`)" target="_blank">&gt; {{ $t(`AGB`) }}</agb-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://www.easy-lize.de/impressum" :title="$t(`Impressum`)" target="_blank">> {{ $t(`Impressum`) }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :href="$getUrl($route, '/datenschutzhinweise')" :title="$t(`Datenschutzhinweise`)">> {{ $t(`Datenschutzhinweise`) }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://www.easy-lize.de/" title="Easy-Lize" target="_blank">> {{ $t(`EASY-LIZE Homepage`) }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://www.eko-punkt.de" title="EKO-PUNKT GmbH" target="_blank">> {{ $t(`EKO-PUNKT Homepage`) }}</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="https://www.remondis.de" title="REMONDIS SE" target="_blank">> {{ $t(`REMONDIS-Gruppe`) }}</a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="third-footer-row">
      <div class="container-fluid container-xxl p-0 bg-lightgray">
        <div class="row g-0">
          <div class="col-12 py-4">
            <div class="easy-container omg-layout text-center text-lg-start">
              &copy; {{new Date().getFullYear()}} REMONDIS SE & Co. KG
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { useRoute } from 'vue-router';
import 'bootstrap/js/src/dropdown';
import AgbLink from "./component/utils/agb-link";
import apiClient from "../config/apiClient";
export default {
  name: 'easylize-app',
  components: {AgbLink},
  watch: {
    '$route'() {
      this._loadWhitelabel();
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingContext: false,
      isLoadingLanguages: false,
      customer: null,
      languages: [],
      selectedLanguage: null,
      showGuideLink: false,
      showSecondNavigation: false,
      testing: false,
      testingDate: null,
      isLoaded: false,
      logoUrl: require('../../assets/images/logo-easy-lize.jpg')
    };
  },
  created() {
    this.$emitter.on('contextChanged', (context) => {
      this._loadCustomer(context);
      this._loadLanguages();
    });
    this.testing = apiClient.testing || false;
    if(this.testing) {
      this.testingDate = localStorage.getItem('easyLizeTestDate') || null;
    }
    this._loadWhitelabel();
  },
  computed: {
    customerIsAuthorized() {
      return this.customer && this.customer.active;
    }
  },
  methods: {
    _loadWhitelabel() {
      this.isLoading = true;
      if(this.isLoaded) {
        this.isLoading = false;
        return;
      }
      const whiteLabelKey = this.$route.params.whiteLabelKey || null;
      if(!whiteLabelKey) {
        this.isLoading = false;
        return;
      }
      easyLizeApp.apiClient.activateWhitelabel(() => {
        this.$whitelabel.loadWhiteLabel()
            .then(this._setWhiteLabel.bind(this))
            .catch(this._setWhiteLabel.bind(this));
      }, whiteLabelKey);

      this.isLoaded = true;
    },
    _setWhiteLabel() {
      this.$whitelabel.loadCustomCss();
      if(this.$whitelabel.hasLogo()) {
        this.logoUrl = this.$whitelabel.getLogoUrl();
      } else {
        this.logoUrl = require('../../assets/images/logo-easy-lize.jpg');
      }
      this.isLoading = false;
    },
    _loadCustomer(context) {
      this.customer = context.customer;
      if(this.customer) {
        this.showGuideLink = false;
        this.showSecondNavigation = false;
      } else {
        this.showGuideLink = true;
        this.showSecondNavigation = true;
      }
    },
    _removeUrlParameter(url, parameter) {
      const urlParts = url.split('?');

      if (urlParts.length >= 2) {
        // Get first part, and remove from array
        const urlBase = urlParts.shift();

        // Join it back up
        const queryString = urlParts.join('?');

        const prefix = encodeURIComponent(parameter) + '=';
        const parts = queryString.split(/[&;]/g);

        // Reverse iteration as may be destructive
        for (let i = parts.length; i-- > 0; ) {
          // Idiom for string.startsWith
          if (parts[i].lastIndexOf(prefix, 0) !== -1) {
            parts.splice(i, 1);
          }
        }
        url = urlBase + '?' + parts.join('&');
      }
      return url;
    },
    _loadLanguages() {
      let defaultLocale = easyLizeApp.locale;
      const queryParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      if(queryParams.lang) {
        defaultLocale = queryParams.lang;
      }
      this.isLoadingLanguages = true;
      easyLizeApp.apiClient.getLanguages((languages) => {
        this.languages = languages;
        for(let l in this.languages) {
          if(this.languages[l].locale === defaultLocale) {
            this.changeLanguage(this.languages[l]);
            if(queryParams.lang) {
              window.location.href = this._removeUrlParameter(window.location.href, 'lang');
            }
            break;
          }
        }
        this.isLoadingLanguages = false;
      });
    },
    onChangeTestDate() {
      localStorage.setItem('easyLizeTestDate', this.testingDate);
    },
    onResetTestDate() {
      this.testingDate = null;
      localStorage.removeItem('easyLizeTestDate');
      window.location.reload();
    },
    changeLanguage(language) {

      this.selectedLanguage = language;
      easyLizeApp.setLanguage(language);
    },
    onChangeLanguage(e, language) {
      e.preventDefault();
      easyLizeApp.apiClient.changeLanguage(language.id, () => {
        this.changeLanguage(language);
        window.location.reload();
      });
    },
    onLogout() {
      easyLizeApp.apiClient.logout(() => {
        window.location.href = this.$getUrl(this.$route, "/login");
      });
    }
  }
}
</script>
