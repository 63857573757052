<template>
  <img :src="src" class="kunden-siegel-img" :alt="fileName" :title="fileName" @click="downloadImage" />
</template>

<script>
export default {
  name: 'KundenSiegelImage',
  props: {
    src: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true}
  },
  methods: {
    downloadImage() {
      const link = document.createElement('a');
      link.href = this.src;
      link.download = this.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style scoped>
.kunden-siegel-img {
  cursor: pointer;
  height: 100px;
  width: 100px;
}
</style>