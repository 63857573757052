<template>
  <modal :auto-open="true" :show-close-btn="false" class="max-order-volume-modal">
    <template #content>
      <p>{{ $t('Vielen Dank für Ihr Interesse an EASY-LIZE, dem Lizenzierungsportal von EKO-PUNKT.') }}</p>
      <p>{{ $t('Für die von Ihnen angefragte Mengen zu lizenzierender Verpackungsmaterialien, möchten wir Ihnen gerne ein individuelles und auf Ihren Bedarf zugeschnittenes Angebot unterbreiten.') }} </p>
      <p v-html="contactInfoText" />
      <p>{{ $t('Vielen Dank und beste Grüße. Ihr Team von EKO-PUNKT') }}</p>
    </template>
    <template #footer>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('Schließen') }}</button>
    </template>
  </modal>
</template>
<script>
import Modal from "./modal.vue";

export default {
  name: "max-order-volume-modal",
  components: {Modal},
  computed: {
    contactInfoText() {
      return this.$t(`Bitte kontaktieren Sie hierzu den Vertriebsinnendienst unter <a href="tel:+492219648970">0221-9648970</a> oder via E-Mail <a href="mailto:vid@eko-punkt.de">vid@eko-punkt.de</a>.`);
    }
  },
  created() {
    easyLizeApp.apiClient.showMaxOrderVolumenModal();
  }
}
</script>