<template>
<Field v-model="modelValue" class="form-control" @change="updateValue($event.target.value)" :disabled="isLoading" as="select">
  <option value="">{{ $t(`Wählen Sie ein Land`) }}</option>
  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
</Field>
</template>

<script>
import {Field} from "vee-validate";

export default {
  name: "country-select",
  emits: ['update:modelValue'],
  components: { Field },
  props: {
    modelValue: { required: true, default: null },
    rules: {
      type: [Object, String, Function],
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      countries: {}
    }
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getCountries((countries) => {
      this.countries = countries;
      if(!this.modelValue) {
        for(let i in countries) {
          if(countries[i].iso === 'DE') {
            this.updateValue(countries[i].id);
            break;
          }
        }
      } else {
        this.updateValue(this.modelValue);
      }
      this.isLoading = false;
    })
  },
  methods: {
    updateValue(countryId) {
      this.$emit('update:modelValue', countryId);
    },
    getCountry(countryId = null) {
      countryId = countryId || this.modelValue || null;
      if(!countryId) {
        return null;
      }
      if(typeof this.countries[countryId] === "undefined") {
        return null;
      }
      return this.countries[countryId];
    },
    isEuMemberState(countryId = null) {
      const country = this.getCountry(countryId);
      if(!country) {
        return false;
      }
      return country.euMemberState || false;
    },
    getVatIdPattern(countryId = null) {
      const country = this.getCountry(countryId);
      if(!country) {
        return false;
      }
      return country.vatIdPattern || null;
    },
    onChangeCountry() {

    }
  }
}
</script>
