<template>
  <customer-page size="full">
    <template v-slot:page-headline>
      {{ $t(`Vertragserweiterung`) }} <span v-if="!isLoading">{{ contractPeriod.contractYear }}</span>
    </template>
    <template v-if="!isLoading" v-slot:page-content>
      <cart :contract-period-id="contractPeriodId" :contract-year="contractPeriod.contractYear" quantity-type="add" :show-kg-price="true" :clear-cart="clearCart" :recalculate-on-load="true">

      </cart>
      <div class="w-100 d-flex justify-content-between pt-5 flex-wrap">
        <router-link class="btn btn-primary" :to="{ name: 'contract_view', params: { contractId: contractPeriod.contractId, contractYear: contractPeriod.contractYear } }">&lt; {{ $t(`zurück`) }}</router-link>
        <router-link class="btn btn-primary" :to="{ name: 'contract_period_checkout', params: { contractPeriodId: contractPeriodId, quantityType: 'add' } }">&gt; {{ $t(`weiter`) }}</router-link>
      </div>
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import Cart from "../../component/cart/cart";
export default {
  name: "contract-period-quantity-add",
  components: {Cart, CustomerPage},
  data() {
    return {
      isLoading: false,
      contractPeriod: null
    }
  },
  computed: {
    contractPeriodId() {
      return this.$route.params.contractPeriodId;
    },
    clearCart() {;
      return !(this.$route.query.keepCart || false);
    }
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContractPeriod((contractPeriod) => {
      this.contractPeriod = contractPeriod;
      this.isLoading = false;
    }, this.contractPeriodId);
  }
}
</script>
