<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="id" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header mt-5">
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="accordion accordion-flush my-5" id="berechnungsilfe">
        <div class="verpackung accordion-item" v-for="(verpackungGruppe, gruppeIndex) in verpackungGruppen">
          <h2 class="accordion-header" :id="`verpackung-gruppe-header-${gruppeIndex}`">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="`#verpackung-gruppe-collapse-${gruppeIndex}`" aria-expanded="false"
                    :aria-controls="`verpackung-gruppe-collapse-${gruppeIndex}`">
              {{verpackungGruppe.name}}
            </button>
          </h2>
          <div :id="`verpackung-gruppe-collapse-${gruppeIndex}`" class="accordion-collapse collapse" :aria-labelledby="`verpackung-gruppe-header-${gruppeIndex}`" data-bs-parent="berechnungsilfe">
            <div class="verpackung" v-for="(verpackung, verpackungIndex) in verpackungGruppe.verpackungen">
              <h3>{{getTranslation(verpackung.name)}} <span class="verpackung-unit" v-if="hasUnit(verpackung)">{{ $t(`in`) }} {{ getTranslation(verpackung.unit) }}</span></h3>
              <div class="verpackung-varianten">
              <div class="off-helper-wrapper">
                <div class="off-helper-sizes">
                  <div class="off-helper-size-select mx-lg-4 d-flex flex-row align-items-center">
                    <div :class="mergeActiveClass('circle-select-box me-4', isActive(verpackung, '_selectedVariant', variantIndex))" v-for="(variant, variantIndex) in verpackung.variants" @click="setActive(verpackung, '_selectedVariant', variantIndex)">{{ variant.value }}</div>
                  </div>
                </div>
              </div>
              <template v-for="(variant, variantIndex) in verpackung.variants">
                <div class="verpackung-variant" v-if="isActive(verpackung, '_selectedVariant', variantIndex)">
                  <div class="variant-options">
                    <hr>
                    <div class="d-flex input-wrapper">
                      <label class="variant-info">{{ $t(`Info`) }}: </label>
                      {{ getTranslation(variant.info) }}
                    </div>
                    <div class="mt-3" v-for="(option, optionIndex) in variant.options">
                      <div class="form-check form-switch" v-if="option.type === 'boolean'">
                        <label class="form-check-label" :for="`variant-option-${gruppeIndex}-${verpackungIndex}-${variantIndex}-${optionIndex}`">{{ getTranslation(option.name) }}</label>
                        <input class="form-check-input" type="checkbox" role="switch" v-model="option._selectedOption" :value="true" :id="`variant-option-${gruppeIndex}-${verpackungIndex}-${variantIndex}-${optionIndex}`" @change="recalculate()">
                        
                        
                      </div>

                      <div class="d-flex input-wrapper align-items-centerrr" v-if="option.type === 'select'">
                        <label class="form-label" :for="`variant-option-${gruppeIndex}-${verpackungIndex}-${variantIndex}-${optionIndex}`">{{ getTranslation(option.name) }}</label>
                        <select class="form-select" :id="`variant-option-${gruppeIndex}-${verpackungIndex}-${variantIndex}-${optionIndex}`" v-model="option._selectedOption" @change="recalculate()">
                          <option :value="null">{{ $t(`Keine Option auswählen`) }}</option>
                          <option :value="selectionIndex" v-for="(selection, selectionIndex) in option.selections">{{ getTranslation(selection.name) }}</option>
                        </select>
                      </div>

                    </div>
                  </div>

                  <div class="d-flex input-wrapper align-items-centerrr mt-3">
                    <label :for="`variant-quantity-${gruppeIndex}-${verpackungIndex}-${variantIndex}`">{{ $t(`Anzahl`) }}</label>
                    <input :id="`variant-quantity-${gruppeIndex}-${verpackungIndex}-${variantIndex}`" v-model="variant._quantity" @change="recalculate()"
                           class="form-control number-input" type="number" placeholder="0" :aria-label="$t(`Anzahl`)">
                  </div>
                <hr>
                </div>
              </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container .container-size-small">
        <hr>
        <ul class="basket clear-list h-list">
          <template v-for="product in products">
            <li v-if="getProductQuantity(product.key) > 0" class="basket-item text-center">
              <label class="product-label"><b>{{ $t(product.name) }}</b></label><br>
              <span class="product-quantity ms-1">{{ $filters.formatNumber(getProductQuantity(product.key), 4) }} kg</span>
            </li>
          </template>
        </ul>
        <div class="button--help--ppk--size-m--option-1 mt-5">
          <button class="btn btn-primary" data-bs-dismiss="offcanvas" @click="acceptInput()">&gt; {{ $t(`Übernehmen`) }}</button>
          <br><p class="mt-2">{{ $t(`Mit dem Übernehmen der Werte werden alle bestehenden Werte überschrieben`) }}!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vpHelper from "../../../../config/vp-helper.json";
import { roundNumber } from "../../../../utils/utils";
import 'bootstrap/js/src/collapse';

let offcanvas = null;
export default {
  name: "berechnungshilfe-offcanvas",
  props: {
    id: {
      type: String,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    calculateCart: {
      type: Function,
      required: true
    }
  },
  watch: {
    products() {
      const productNames = {};
      this.verpackungGruppen = {};

      for(let productId in this.products) {
        productNames[this.products[productId].key] = this.products[productId].name;
      }

      for(let verpackungIndex in vpHelper.verpackungen) {
        const verpackung = vpHelper.verpackungen[verpackungIndex];
        if(typeof this.verpackungGruppen[verpackung.group] === "undefined") {
          this.verpackungGruppen[verpackung.group] = {
            name: productNames[verpackung.group],
            verpackungen: []
          };
        }
        this.verpackungGruppen[verpackung.group].verpackungen.push(verpackung);
        for(let variantKey in verpackung.variants) {
          if(typeof verpackung.variants[variantKey]._quantity === "undefined") {
            verpackung.variants[variantKey]._quantity = 0;
          }
          for(let optionKey in verpackung.variants[variantKey].options) {
            const option = verpackung.variants[variantKey].options[optionKey];
            let defaultSelectedOption = null;
            if(option.type === "boolean") {
              defaultSelectedOption = false;
            }
            if(typeof option._selectedOption === "undefined") {
              verpackung.variants[variantKey].options[optionKey]._selectedOption = defaultSelectedOption;
            }
          }
        }
      }
    }
  },
  created() {

  },
  mounted() {
    offcanvas = document.getElementById(this.id);
    if(offcanvas) {
      offcanvas.addEventListener('show.bs.offcanvas', event => {
        this.recalculate();
      });
    }
  },
  data() {
    return {
      verpackungGruppen: [],
      locale: easyLizeApp.locale,
      productQuantities: {}
    };
  },
  methods: {
    _addProductQuantities(variantQuantity, fraktions) {
      for(let f in fraktions) {
        const fraktion = fraktions[f];
        if(typeof this.productQuantities[fraktion.id] === "undefined") {
          this.productQuantities[fraktion.id] = 0;
        }
        this.productQuantities[fraktion.id]+= roundNumber(variantQuantity * fraktion.value, 4);
      }
    },
    recalculate() {
      this.productQuantities = {};
      for(let gruppeIndex in this.verpackungGruppen) {
        const verpackungGruppe = this.verpackungGruppen[gruppeIndex];
        for(let verpackungIndex in verpackungGruppe.verpackungen) {
          const verpackung = verpackungGruppe.verpackungen[verpackungIndex];
          for(let variantKey in verpackung.variants) {
            const variant = verpackung.variants[variantKey];
            const variantQuantity = parseInt(variant._quantity || 0);
            this._addProductQuantities(variantQuantity, variant.fraktions || []);
            for(let optionKey in variant.options) {
              const option = variant.options[optionKey];
              if(typeof option._selectedOption === "undefined") {
                continue;
              }
              switch (option.type) {
                case "boolean":
                  this._addProductQuantities(variantQuantity, (option._selectedOption || false) ? (option.fraktions || {}) : {});
                  break;
                case "select":
                  const selectedOption = option.selections[option._selectedOption] || {};
                  this._addProductQuantities(variantQuantity, selectedOption.fraktions || {});
                  break;
              }
            }
          }
        }
      }
    },
    getTranslation(translations) {
      if(typeof translations === "string") {
        return translations;
      }
      return translations[this.locale] || '';
    },
    hasUnit(verpackung) {
      return typeof verpackung.unit === "object" || typeof verpackung === "string";
    },
    mergeActiveClass(defaultClass, isActive, activeClass = 'active') {
      if(isActive) {
        return defaultClass + ' active';
      }
      return defaultClass;
    },
    isActive(parent, dataName, childIndex, defaultIndex = -1) {
      return (parent[dataName] ?? defaultIndex) === childIndex;
    },
    setActive(parent, dataName, childIndex) {
      parent[dataName] = childIndex;
    },
    getProductQuantity(productKey) {
      return this.productQuantities[productKey] || 0.0;
    },
    acceptInput() {
      for(let p in this.products) {
        this.products[p].inputWeight = this.productQuantities[this.products[p].key] || 0.0;
      }
      this.calculateCart(() => {});
    }
  }
}
</script>
