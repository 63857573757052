function roundNumber(number, precision) {
    const factor = Math.pow(10, precision);
    const tempNumber = number * factor;
    const roundedTempNumber = Math.round(tempNumber + Number.EPSILON);
    return roundedTempNumber / factor;
}

function validateEmail(email) {
    if(email.match(/\s+/)) {
        return false;
    }
    return email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i);
}

function replacePlaceholders(text, placeholders) {
    for (let name in placeholders) {
        text = text.replaceAll('{' + name + '}', placeholders[name]);
    }
    return text;
}

function updateMetaTags(title, description) {
    document.title = title;
    document.getElementsByTagName('meta')["description"].content = description;
}

function scrollTop() {
    window.scrollTo(0, 0);
}

export {
    roundNumber,
    validateEmail,
    replacePlaceholders,
    updateMetaTags,
    scrollTop
}