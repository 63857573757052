<template>
  <data-table
      :columns="tableColumns"
      :rows="messages"
      :is-loading="isLoading"
  >

    <template v-slot:column-hasAttachments="{ row }">
      <!--i class="bi bi-paperclip"  v-if="row.hasAttachments"></i>
      <span v-else></span-->
      <span></span>
    </template>
  
    <template v-slot:column-subject="{ row }">
      <router-link :to="{ name: 'customer_message_view', params: { id: row.id }}">{{ row.subject }}</router-link>
    </template>
    <template v-slot:column-fromSystem="{ row }">
      <span v-if="row.fromSystem">{{ $t(`EKO-PUNKT`) }}</span>
      <span v-else>{{ row.customer.firstName }} {{ row.customer.lastName }}</span>
    </template>
    <template v-slot:column-mailDateTime="{ row }">
      {{ $d(row.mailDateTime, 'long') }}
    </template>
  </data-table>
</template>

<script>
import DataTable from "../table/dataTable";
export default {
  name: "customer-message-table",
  components: {DataTable},
  watch: {
    folder() {
      this.loadMessages();
    }
  },
  props: {
    folder: {
      type: String,
      required: false,
      default: 'inbox'
    },
    limit: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      isLoading: false,
      messages: []
    };
  },
  created() {
    this.loadMessages();
  },
  computed: {
    tableColumns() {
      return [
        {
          field: 'hasAttachments',
          label: ''
        },
        {
          field: 'subject',
          label: this.$t('Betreff')
        },
        {
          field: 'fromSystem',
          label: this.$t('Von')
        },
        {
          field: 'mailDateTime',
          label: this.$t('Datum')
        }
      ];
    },
  },
  methods: {
    loadMessages() {
      this.isLoading = true;
      easyLizeApp.apiClient.getCustomerMessages((result) => {
        this.messages = result.elements;
        this.isLoading = false;
      }, this.folder, this.limit);
    }
  }
}
</script>

<style scoped>

</style>