<template>
  <a :href="gtcUrl"><slot></slot></a>
</template>

<script>
import config from "../../../config/apiClient";
export default {
  name: "agb-link",
  data() {
    return {
      gtcUrl: '#'
    };
  },
  created() {
    easyLizeApp.apiClient.getContext((context) => {
      let gtcUrl = config.url + '/easylize/document/agb/' + context.customerGroup.id;
      if(context.customer) {
        gtcUrl+= '/' + context.customer.id;
      }
      this.gtcUrl = gtcUrl;
    });
  }
}
</script>

<style scoped>

</style>