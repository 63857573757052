export default class Whitelabel {
    /**
     *
     * @param {ApiClient} apiClient
     */
    constructor(apiClient) {
        this.apiClient = apiClient;
        this.whitelabel = null;
    }

    loadWhiteLabel() {
        return new Promise((resolve) => {
            if(this.whitelabel) {
                resolve(this.whitelabel);
            } else {
                this.apiClient.getWhitelabel()
                    .then((whitelabel) => {
                        this.whitelabel = whitelabel;
                        resolve(this.whitelabel);
                    })
                    .catch((whitelabel) => {
                        this.whitelabel = whitelabel;
                        resolve(this.whitelabel);
                    });
            }
        })

    }

    loadCustomCss() {
        if(!this.whitelabel || !this.whitelabel.active) {
            return;
        }

        const customVars = this.whitelabel.vars || {};
        let cumstomVarsCss = '';
        for(let varName in customVars) {
            if(customVars[varName] !== null && customVars[varName] !== '') {
                cumstomVarsCss += `--${varName}: ${customVars[varName]};`;
            }
        }
        if(cumstomVarsCss) {
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `body {${cumstomVarsCss}}`;
            document.getElementsByTagName('body')[0].prepend(style);
        }
        if(this.whitelabel.customCss) {
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = this.whitelabel.customCss;
            document.getElementsByTagName('body')[0].prepend(style);
        }
    }

    hasLogo() {
        return this.whitelabel && this.whitelabel.active && this.whitelabel.logoUrl;
    }

    getLogoUrl() {
        return this.whitelabel.logoUrl;
    }
}