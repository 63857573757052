<template>
  <customer-page>
    <template v-slot:page-headline>{{ $t(`Nachricht`) }}</template>
    <template v-slot:page-content>
      <a :href="$getUrl($route, '/messages')" class="btn btn-primary">&lt; {{ $t(`zurück`) }}</a>
      <hr>

      <div :class="getMessageClass('message-box card card-full mb-5 active scroll-to-position', message)" v-if="!isLoading" v-for="message in messages">
        <div class="card-header d-flex justify-content-between">
          <div id="email-sender" class="email-sender">
            <img id="logo" src="../../../../assets/images/logo-easy-lize.jpg" alt="logo" v-if="message.fromSystem" />
            <span v-else>{{ message.customer.firstName }} {{ message.customer.lastName }}</span>
          </div>
          <div id="email-datetime" class="text-dark">{{ $d(message.mailDateTime, 'long') }}</div>
        </div>
        <div class="card-body bg-white">
          <h5 class="card-title">{{ message.subject }}</h5>
          <p class="card-text" v-html="message.content"></p>
          <hr v-if="message.attachments.length > 0">
          <div class="message-attachment" v-for="attachment in message.attachments">

          </div>
        </div>
        <div class="card-footer">

        </div>
      </div>

      <customer-message-create-form :reply-message="replyMessage" v-if="!isLoading" @message:created="onMessageCreated" />

      <a :href="$getUrl($route, '/messages')" class="btn btn-primary">&lt; {{ $t(`zurück`) }}</a>
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../../pages/customer-page";
import CustomerMessageCreateForm from "../../component/customer-message/customer-message-create-form";
export default {
  name: "customer-message-view",
  components: {CustomerMessageCreateForm, CustomerPage},
  data() {
    return {
      isLoading: false,
      messages: []
    };
  },
  created() {
    this.loadMessage();
  },
  computed: {
    replyMessage() {
      return this.messages.at(0) || null;
    }
  },
  methods: {
    loadMessage() {
      this.isLoading = true;
      easyLizeApp.apiClient.getMessageHistory((result) => {
        this.messages = result.elements;
        this.isLoading = false;
      }, this.$route.params.id);
    },
    getMessageClass(defaultClass, message) {
      if(message.fromSystem) {
        //defaultClass+= ' bg-info';
      }
      return defaultClass;
    },
    onMessageCreated() {
      this.loadMessage();
    }
  }
}
</script>

<style scoped>

</style>