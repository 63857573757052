<template>
<!--             
              <div class="bg-red-circle" v-if="showCircle">
                <div class="circle"></div>
              </div>
-->  
  <section class="easy-step">
    <loader-overlay :is-loading="isLoading" />
    <div class="container-fluid container-xxl p-0">
      <div class="row g-0 position-relative">
        <div class="col-easy">
          <div class="col-12 easy-container omg-layout">
            <div v-if="showHeadline || hasSlot('page-subline')" class="jumbotron pt-5 pb-5 text-center justify-content-center align-items-center mb-0">
              <div class="container">
                <h1 class="display-3" v-if="showHeadline"><slot name="page-headline">{{ $t(`EASY-LIZE`) }}</slot></h1>
                <p v-if="hasSlot('page-subline')" class="lead">
                  <slot name="page-subline"></slot>
                </p>
              </div>
            </div>
            
            <slot name="page-content-container">
<!--             
              <div class="bg-red-circle" v-if="showCircle">
                <div class="circle"></div>
              </div>
-->              
              <div :class="`container py-5 bg-whitee container-size-${size}`">
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <messages-container v-if="showMessages" />
                    <slot name="page-content"></slot>
                  </div>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import MessagesContainer from "../component/utils/messages-container";
import LoaderOverlay from "../component/utils/loader-overlay";
export default {
  name: "customer-page",
  components: {LoaderOverlay, MessagesContainer},
  props: {
    showHeadline: {
      type: Boolean,
      required: false,
      default: true
    },
    showMessages: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: 'small'
    },
    showCircle: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    hasSlot(slot) {
      return typeof this.$slots[slot] !== "undefined";
    }
  }
}
</script>
