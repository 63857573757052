<template>
  <customer-page size="full">
    <template v-slot:page-headline>
      {{ $t(`Jahresabschluss`) }}  <span v-if="!isLoading">{{ contractPeriod.contractYear }}</span>
    </template>
    <template v-if="!isLoading" v-slot:page-content>
      <cart :contract-period-id="contractPeriodId" :contract-year="contractPeriod.contractYear" quantity-type="final" :show-kg-price="true" :clear-cart="clearCart" :recalculate-on-load="true" :is-update-final-quantity="isUpdateFinalQuantity">

      </cart>
      <div class="w-100 d-flex justify-content-between pt-5">
        <router-link class="btn btn-primary" :to="{ name: 'contract_view', params: { contractId: contractPeriod.contractId, contractYear: contractPeriod.contractYear } }">&lt; {{ $t(`zurück`) }}</router-link>
        <router-link class="btn btn-primary" :to="{ name: 'contract_period_checkout', params: { contractPeriodId: contractPeriodId, quantityType: 'final' } }">&gt; {{ $t(`weiter`) }}</router-link>
      </div>
    </template>
  </customer-page>
  <modal v-if="showFinalAddendumInfoModal" :auto-open="true" :show-close-btn="false" class="cart-empty-modal">
    <template v-slot:content>
      <div class="alert alert-info" role="alert">
        {{ $replacePlaceholders($t('Eine Mengenanpassung der Jahresabschlussmeldung nach dem Stichtag {date} ist möglich. Hierbei kann es zu Preisabweichungen gegenüber den fristgerechten Mengenmeldungen kommen.', { date: $d(showFinalAddendumInfoDate, 'short') }), {
          date: $d(showFinalAddendumInfoDate, 'short')
        }) }}
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('Schließen') }}</button>
    </template>
  </modal>
</template>

<script>
import CustomerPage from "../customer-page";
import Cart from "../../component/cart/cart";
import Modal from "../../component/modal/modal.vue";
export default {
  name: "contract-period-final",
  components: {Modal, Cart, CustomerPage},
  data() {
    return {
      isLoading: false,
      contractPeriod: null,
      isUpdateFinalQuantity: false,
      showFinalAddendumInfoModal: false,
      showFinalAddendumInfoDate: null
    }
  },
  computed: {
    contractPeriodId() {
      return this.$route.params.contractPeriodId;
    },
    clearCart() {;
      return !(this.$route.query.keepCart || false);
    }
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContractPeriod((contractPeriod) => {
      let totalFinalQuantities = 0;
      let totalNewQuantities = 0;
      this.contractPeriod = contractPeriod;
      for(let q in contractPeriod.contractPeriodQuantities) {
        const contractPeriodQuantity = contractPeriod.contractPeriodQuantities[q];
        if(contractPeriodQuantity.quantityType === 'final') {
          totalFinalQuantities++;
        } else {
          totalNewQuantities++;
        }
      }
      this.isUpdateFinalQuantity = totalNewQuantities === 0 && totalFinalQuantities > 1 || totalNewQuantities > 0 && totalFinalQuantities > 0;
      easyLizeApp.apiClient.showFinalAddendumInfo((result) => {
        this.showFinalAddendumInfoModal = result['show-info'] === true;
        this.showFinalAddendumInfoDate = result.date;
        this.isLoading = false;
      }, this.contractPeriodId);
    }, this.contractPeriodId);
  }
}
</script>
