import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createHead } from "@vueuse/head";
import mitt from 'mitt'
import VueApp from './App';
import router from './router';
import ApiClient from "../utils/apiClient";
import filters from './vue/filters';
import messagesDeDe from './messages/de-DE.json';
import messagesEnGb from './messages/en-GB.json';
import messagesZhGb from './messages/zh-CN.json';
import messages from "../utils/vue/messages";
import apiClient from "../utils/vue/apiClient";
import {replacePlaceholders} from "../utils/utils";
import Whitelabel from "../utils/whitelabel";

export default class App
{
    constructor(options) {
        window.easyLizeApp = this;

        let locale = sessionStorage.getItem('easyLizeLocale') || null;
        if(!locale && (window.location.pathname.indexOf('/zh-cn') === 0 || window.location.pathname.indexOf('/cn') === 0)) {
            locale = 'zh-CN';
        } else if(!locale) {
            const language = (navigator.languages[0] || navigator.language || 'de-DE').toLowerCase();
            switch (language.substring(0, 2)) {
                case 'en':
                    locale = 'en-GB';
                    break;
                case 'zh':
                    locale = 'zh-CN';
                    break;
                default:
                    locale = 'de-DE';
                    break;
            }
        }
        this.locale = locale;
        this.$vue = createApp(VueApp);
        this._createEmitter();

        router.beforeEach((to, from, next) => {
            if(from.name !== undefined) {
                to.params.whiteLabelKey = from.params.whiteLabelKey || '';
            }
            next();
        });
        this.$vue.use(router);

        this.apiClient = new ApiClient(this.$emitter, options.api.url, options.api.accessKey, options.api.testing || false);
        this.whitelabel = new Whitelabel(this.apiClient);
        this.$vue.config.globalProperties.$filters = filters;
        this.$vue.config.globalProperties.$replacePlaceholders = replacePlaceholders;
        this.$vue.config.globalProperties.$emitter = this.$emitter;
        this.$vue.config.globalProperties.$whitelabel = this.whitelabel;
        this.$vue.config.globalProperties.$getUrl = ($route, url) => {
            const whiteLabelKey = $route.params.whiteLabelKey || '';
            if(whiteLabelKey !== '') {
                return `/${whiteLabelKey}${url}`;
            }
            return url;
        };

        window.onunhandledrejection = event => {
            this.apiClient.logError(event.type || 'unhandledrejection', {
                message: event.reason.message || '',
                stack: event.reason.stack || '',
            });
        };
        window.onerror = (msg, url, lineNo, linePos, error) => {
          try {
              this.apiClient.logError(error.name || 'error', {
                  msg: error.message || msg,
                  url: window.location.href
              });
          } catch(e) {
              console.error(e);
          }
        };

        this.$vueI18n = this._createI18n();
        this.$vue.use(this.$vueI18n);
        this.$vue.use(apiClient, {
            apiClient: this.apiClient
        });
        this.$vue.use(messages);

        const head = createHead();
        this.$vue.use(head);
    }

    _createEmitter() {
        this.$emitter = mitt();
        this.$emitter.once = (type, handler) => {
            const wrappedHandler = (evt) => {
                handler(evt)
                this.$emitter.off(type, wrappedHandler)
            }
            this.$emitter.on(type, wrappedHandler)
        };
    }

    _createI18n() {
        const messages = {
            "de-DE": messagesDeDe,
            "en-GB": messagesEnGb,
            "zh-CN": messagesZhGb
        };
        return createI18n({
            locale: this.locale,
            messages,
            datetimeFormats: {
                "de-DE": {
                    short: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    },
                    long: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    }
                },
                "en-GB": {
                    short: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    },
                    long: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    }
                },
                "zh-CN": {
                    short: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    },
                    long: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    }
                }
            }
        });
    }

    setLanguage(language) {
        this.locale = language.locale;
        this.apiClient.setLanguageId(language.id);
        this.$vueI18n.locale = language.locale;
        sessionStorage.setItem('easyLizeLocale', language.locale);
        sessionStorage.setItem('easyLizeLanguageId', language.id);
        this.$emitter.emit('languageChanged', language);
    }

    setLocale(locale) {
        this.$vueI18n.locale = locale;
    }

    async startApp() {
        this.$vue.mount('#app');
    }
}
