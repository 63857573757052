<template>
  <customer-page>
    <template v-slot:page-headline>{{ $t(`Ihre E-Mail-Adresse ändern`) }}</template>
    <template v-slot:page-content>
      <Form ref="form">
        <div class="form-group mb-3 row border-top border-lg-0">
          <label for="password2" class="col-md-5 col-form-label">{{ $t(`Ihr Passwort`) }}</label>
          <div class="col-md-7">
            <Field type="password" class="form-control" id="password2" name="password" v-model="password" :rules="isRequired" />
            <ErrorMessage name="password" />
          </div>
        </div>
        <div class="form-group mb-3 row border-top border-lg-0">
          <label for="email" class="col-md-5 col-form-label">{{ $t(`Ihre neue E-Mail-Adresse`) }}</label>
          <div class="col-md-7">
            <Field type="email" class="form-control" id="email" name="email" v-model="emailAddress" :rules="isValidEmailAddress" />
            <ErrorMessage name="email" />
          </div>
        </div>
        <div class="form-group mb-3 row border-top border-lg-0">
          <label for="email2" class="col-md-5 col-form-label">{{ $t(`Ihre neue E-Mail-Adresse wiederholen`) }}</label>
          <div class="col-md-7">
            <Field type="email" class="form-control" id="email2" name="email2" v-model="emailAddress2" :rules="repeatEmailAddress" />
            <ErrorMessage name="email2" />
          </div>
        </div>
        <hr class="my-4">
        <div class="form-group mb-3 row border-top border-lg-0">
          <div class="w-100 d-flex justify-content-between pt-5">
            <div><a class="btn btn-primary btn-back" :href="$getUrl($route, '/customer')">&lt; {{ $t(`zurück`) }}</a></div>
            <button class="btn btn-primary" @click="onChangeEmailAddress" :disabled="isSavingPassword">> {{ $t(`E-Mail speichern`) }}</button>
          </div>
        </div>
      </Form>
    </template>
  </customer-page>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import CustomerPage from "../customer-page";
import {validateEmail} from "../../../utils/utils";

export default {
  name: "customer-account-email",
  components: {CustomerPage, Form, Field, ErrorMessage },
  data() {
    return {
      isSavingPassword: false,
      password: '',
      emailAddress: '',
      emailAddress2: ''
    }
  },
  methods: {
    isRequired(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    isValidEmailAddress(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      if(!validateEmail(value)) {
        return this.$t('Bitte geben Sie eine gültige E-Mail ein');
      }
      return true;
    },
    repeatEmailAddress(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      if(!validateEmail(value)) {
        return this.$t('Bitte geben Sie eine gültige E-Mail ein');
      }
      if(value !== this.emailAddress) {
        return this.$t('Bitte bestätigen Sie Ihre E-Mail');
      }
      return true;
    },
    onChangeEmailAddress(e) {
      e.preventDefault();
      this.$refs.form.validate().then((result) => {
        if(!result.valid) {
          return;
        }
        this.isSavingPassword = true;
        easyLizeApp.apiClient.updateCustomerEmail(() => {
          this.$messenger.pushMessage('success', this.$t('Ihre E-Mail-Adresse wurde geändert'));
          this.$refs.form.resetForm();
          this.isSavingPassword = false;
        }, this.password, this.emailAddress, this.emailAddress2);
      });
    }
  }
}
</script>

<style scoped>

</style>