<template>
  <Form ref="form" class="password-form">
    <div class="row">
      <div class="col-12">
        <h2 class="h5" v-if="!passwordChange">{{$t('Ihr Passwort')}}</h2>
        <div class="form-group mb-3" v-if="passwordChange">
          <div class="form-floating">
            <Field type="password" class="form-control" id="floatingPasswordOld" placeholder="Ihr aktuelles Passwort" name="oldPassword" v-model="oldPassword" :rules="oldPasswordRule" />
            <ErrorMessage name="oldPassword" />
            <label for="floatingPasswordOld">Ihr aktuelles Passwort</label>
          </div>
        </div>
        <div class="form-group mb-3">
          <div class="form-floating">
            <Field type="password" class="form-control" id="floatingPassword" :placeholder="$t('Ihr persönliches Passwort')" name="password" v-model="password" :rules="passwordRule" />
            <ErrorMessage name="password" />
            <label for="floatingPassword">{{$t('Ihr persönliches Passwort')}}</label>
          </div>
          <div class="font-12 pt-1 password-restriction">
            <ul>
              <li :class="getPasswordRestrictionClass('minLength')">{{$t('Mindestlänge 12 Zeichen')}}</li>
              <li :class="getPasswordRestrictionClass('minOneUpperCase')">{{$t('mindestens ein Großbuchstabe')}}</li>
              <li :class="getPasswordRestrictionClass('minOneLowerCase')">{{$t('mindestens ein Kleibuchstabe')}}</li>
              <li :class="getPasswordRestrictionClass('minOneNumber')">{{$t('mindestens eine Ziffer')}}</li>
              <li :class="getPasswordRestrictionClass('minOneSpecialChar')">{{ replacePlaceholders($t('mindestens ein Sonderzeichen {specialChars}'), { specialChars: this.allowedSpecialChars.join(' ') }) }}</li>
              <li :class="getPasswordRestrictionClass('notAllowedSpecialChars')">{{$t('andere Sonderzeichen, Umlaute nicht zulässig')}}</li>
            </ul>
          </div>
        </div>
        <div class="form-group mb-3">
          <div class="form-floating">
            <Field type="password" class="form-control" id="floatingPasswordConfirm" :placeholder="$t('Passwort wiederholen')" name="password2" v-model="password2" :rules="password2Rule" />
            <ErrorMessage name="password2" />
            <label for="floatingPasswordConfirm">{{$t('Passwort wiederholen')}}</label>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import {replacePlaceholders} from "../../../utils/utils";
export default {
  name: "password-control",
  components: { Form, Field, ErrorMessage },
  props: {
    passwordChange: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    password() {
      this._validatePassword();
    }
  },
  data() {
    return {
      oldPassword: '',
      password: '',
      password2: '',
      allowedSpecialChars: [ '!', '#', '-', '?', '*', '+', '=', '<', '>', '/', '(', ')', '&', '%', '$', '§', '"' ],
      validation: {
        minLength: false,
        minOneUpperCase: false,
        minOneLowerCase: false,
        minOneNumber: false,
        minOneSpecialChar: false,
        notAllowedSpecialChars: false
      }
    }
  },
  methods: {
    replacePlaceholders,
    _isPasswordValid() {
      for(let rule in this.validation) {
        if(!this.validation[rule]) {
          return false;
        }
      }
      return true;
    },
    _validatePassword() {
      const allowsSpecialChars = this.allowedSpecialChars.map((specialChar) => '\\' + specialChar).join();
      const pwdLengthRe = new RegExp('^.{12,}$');
      const pwdUpperRe = new RegExp('[A-Z]+');
      const pwdLowerRe = new RegExp('[a-z]+');
      const pwdNumberRe = new RegExp('[0-9]+');
      const pwdSpecialRe = new RegExp('[' + allowsSpecialChars + ']+');
      const pwdAllowedCharsRe = new RegExp('^[a-zA-Z0-9' + allowsSpecialChars + ']+$');

      this.validation.minLength = pwdLengthRe.test(this.password);
      this.validation.minOneUpperCase = pwdUpperRe.test(this.password);
      this.validation.minOneLowerCase = pwdLowerRe.test(this.password);
      this.validation.minOneNumber = pwdNumberRe.test(this.password);
      this.validation.minOneSpecialChar = pwdSpecialRe.test(this.password);
      this.validation.notAllowedSpecialChars = pwdAllowedCharsRe.test(this.password);
    },
    getPasswordRestrictionClass(rule) {
      return (this.validation[rule] || false) === true ? 'success' : 'invalid';
    },
    oldPasswordRule() {
      if(this.oldPassword === '') {
        return this.$t('Dieses Feld ist ein Pflichtfeld.');
      }
      return true;
    },
    passwordRule() {
      if(this.password === '') {
        return this.$t('Dieses Feld ist ein Pflichtfeld.');
      }
      if(!this._isPasswordValid()) {
        return this.$t('Ihr Passwort ist ungültig');
      }
      return true;
    },
    password2Rule() {
      if(this.password2 === '') {
        return this.$t('Dieses Feld ist ein Pflichtfeld.');
      }
      if(this.password !== this.password2) {
        return this.$t('Bitte wiederholen Sie Ihr Passwort');
      }
      return true;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((result) => {
          result.oldPassword = this.oldPassword;
          result.password = this.password;
          resolve(result);
        }).catch(reject);
      });
    },
    reset() {
      this.$refs.form.resetForm();
    }
  }
}
</script>

<style scoped>

</style>