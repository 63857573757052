<template>
  <slot name="content-before" v-bind="{ isLoading, hidePaymentMethods, paymentMethods}"></slot>
  <div class="payment-method-select">
    <div v-if="!isLoading && !hidePaymentMethods" class="payment-methods-wrapper">
      <h4 class="my-4">{{ $t('Bezahlart') }}</h4>
      <template v-for="paymentMethod in paymentMethods">
        <div :class="`form-check payment-method-${paymentMethod.shortName}`" v-if="!readonly">
          <input class="form-check-input payment-method-radio" type="radio" name="paymentMethodId" :id="`paymentMethod${paymentMethod.id}`" :value="paymentMethod.id" :checked="selectedPaymentMethod && paymentMethod.id === selectedPaymentMethod.id" @change="onPaymentMethodChanged($event.target.value)">
          <label class="form-check-label" :for="`paymentMethod${paymentMethod.id}`">
            <img v-if="paymentMethod.logo" :src="paymentMethod.logo" class="payment-method-logo" :alt="paymentMethod.name" />
            {{ $t(paymentMethod.name) }}
          </label>
        </div>
        <label v-if="readonly && selectedPaymentMethod && paymentMethod.id === selectedPaymentMethod.id" class="form-check-label">
          <img v-if="paymentMethod.logo" :src="paymentMethod.logo" class="payment-method-logo" :alt="paymentMethod.name" />
          {{ $t(paymentMethod.name) }}
        </label>
        <div v-if="selectedPaymentMethod && paymentMethod.id === selectedPaymentMethod.id" class="payment-method-form" :id="`payment-method-form-${paymentMethod.id}`"></div>
      </template>
      <payment-method-invoice v-if="selectedPaymentMethod && selectedPaymentMethod.shortName === 'invoice_payment'" v-model="invoiceInterval" :payment-method="selectedPaymentMethod" @change:invoiceInterval="onInvoiceIntervalChanged" :readonly="readonly" />
      <payment-method-crefopay v-if="selectedPaymentMethod && _isCrefopayPaymentMethod(selectedPaymentMethod.shortName)" :payment-method-short-name="selectedPaymentMethod.shortName" ref="paymentMethodCrefopay" @validity-change="onValidityChange" />
      <payment-method-adyen v-if="selectedPaymentMethod && _isAdyenPaymentMethod(selectedPaymentMethod)" :payment-method="selectedPaymentMethod" ref="paymentMethodAdyen" @handlePayment="onHandleAdyenPayment" />
    </div>
  </div>
  <slot name="content-after" v-bind="{ isLoading, hidePaymentMethods, paymentMethods}"></slot>
</template>

<script>
import LoaderOverlay from "../../utils/loader-overlay";
import PaymentMethodInvoice from "./payment-method-invoice";
import PaymentMethodCrefopay from "./payment-method-crefopay";
import PaymentMethodAdyen from "./payment-method-adyen";
export default {
  name: "payment-method-select",
  components: {PaymentMethodCrefopay, PaymentMethodAdyen, PaymentMethodInvoice, LoaderOverlay},
  emits: [ 'update:paymentMethodId', 'update:invoiceInterval', 'validity-change', 'handle-payment' ],
  props: {
    contractPeriodId: {
      type: String,
      required: false,
      default: null
    },
    contractYear: {
      type: Number,
      required: false,
      default: null
    },
    paymentMethodId: {
      type: String,
      required: false,
      default: null
    },
    invoiceInterval: {
      type: String,
      required: false,
      default: null
    },
  },
  created() {
    this.isLoading = true;
    this.confirmFormSubmit = document.querySelector('#btn-contract-submit');
    if(this.contractPeriodId) {
      easyLizeApp.apiClient.getContractPeriod((contractPeriod) => {
        this.readonly = contractPeriod.invoicePayment;
        this._loadPaymentMethods(() => {
          if(contractPeriod.lastContractPeriodQuantity) {
            this.setPaymentMethodId(contractPeriod.lastContractPeriodQuantity.paymentMethodId);
          }
          if(contractPeriod.invoicePayment) {
            this.setInvoiceInterval(contractPeriod.invoiceIntervalType);
          }
          this.isLoading = false;
        });
      }, this.contractPeriodId)
    } else {
      this._loadPaymentMethods(() => {
        this.isLoading = false;
      });
    }
  },
  data() {
    return {
      isLoading: false,
      paymentMethods: {},
      selectedPaymentMethod: null,
      readonly: false,
      hidePaymentMethods: false,
      confirmFormSubmit: null
    };
  },
  methods: {
    _loadPaymentMethods(callback) {
      easyLizeApp.apiClient.getPaymentMethods((result) => {
        this.hidePaymentMethods = result.hidePaymentMethods;
        this.paymentMethods = result.paymentMethods;
        for(let p in result.paymentMethods) {
          if(result.paymentMethods[p].selected && this.paymentMethodId === null) {
            this.setPaymentMethodId(result.paymentMethods[p].id);
            break;
          }
        }
        callback();
      }, this.contractYear, this.contractPeriodId);
    },
    _isCrefopayPaymentMethod(shortName) {
      return shortName === 'prepaid' || shortName === 'credit_card' || shortName === 'pay_pal'|| shortName === 'sofort'|| shortName === 'direct_debit';
    },
    _isAdyenPaymentMethod(paymentMethod) {
      const pluginName = (paymentMethod.plugin || '').toLowerCase();
      return pluginName == 'adyenpaymentshopware6';
    },
    onValidityChange(result) {
      this.$emit('validity-change', result);
    },
    onPaymentMethodChanged(paymentMethodId) {
      this.setPaymentMethodId(paymentMethodId);
      if (this.confirmFormSubmit) {
        this.confirmFormSubmit.classList.remove('d-none');
      }
      this.$emit('validity-change', { success: true, errors: [] });
      setTimeout(() => {
        if(this.selectedPaymentMethod && this._isCrefopayPaymentMethod(this.selectedPaymentMethod) && this.$refs.paymentMethodCrefopay) {
          this.$refs.paymentMethodCrefopay.initPaymentMethod();
        }
        if(this.selectedPaymentMethod && this._isAdyenPaymentMethod(this.selectedPaymentMethod) && this.$refs.paymentMethodAdyen) {
          this.$refs.paymentMethodAdyen.initPaymentMethod();
        }
      }, 0);
    },
    onHandleAdyenPayment(data, callback) {
      this.$emit('handle-payment', data, callback);
    },
    onInvoiceIntervalChanged(invoiceInterval) {
      this.setInvoiceInterval(invoiceInterval);
    },
    setPaymentMethodId(paymentMethodId) {
      if(typeof this.paymentMethods[paymentMethodId] === "undefined") {
        return;
      }
      this.$emit('update:paymentMethodId', paymentMethodId);
      this.selectedPaymentMethod = this.paymentMethods[paymentMethodId];
    },
    setInvoiceInterval(invoiceInterval) {
      this.$emit('update:invoiceInterval', invoiceInterval);
    },
    validate(callback) {
      if(this.hidePaymentMethods) {
        callback({});
      } else if(this.selectedPaymentMethod && this.selectedPaymentMethod.shortName === 'invoice_payment') {
        callback({});
      } else if(this.selectedPaymentMethod && this._isCrefopayPaymentMethod(this.selectedPaymentMethod.shortName)) {
        this.$refs.paymentMethodCrefopay.validate(callback);
      } else if(this.selectedPaymentMethod && this._isAdyenPaymentMethod(this.selectedPaymentMethod)) {
        this.$refs.paymentMethodAdyen.validate(callback);
      }
    }
  }
}
</script>