<template>
  <div class="messages-container">
    <div :class="`alert alert-${message.type} alert-dismissible fade show`" v-for="(message, index) in messages">
      {{ message.message }}
      <button type="button" class="btn-close" @click="deleteMessage(index)" aria-label="Close"></button>
    </div>
  </div>
</template>

<script>
import 'bootstrap/js/src/alert';
export default {
  name: "messages-container",
  props: {
    messagesRoute: {
      type: String | Object,
      required: false,
      default: null
    }
  },
  created() {
    this.$messenger.setMessageListener(this.fetchMessages.bind(this));
    this.fetchMessages();
  },
  data() {
    return {
      messages: []
    };
  },
  methods: {
    deleteMessage(index) {
      if(typeof this.messages[index] !== "undefined") {
        this.messages.splice(index, 1);
      }
    },
    fetchMessages() {
      this.$messenger.getMessages((messages) => {
        this.messages = messages;
      }, this.messagesRoute);
    }
  }
}
</script>