<template>
  <modal class="duplicate-lucid-number-modal" ref="duplicateLucidNumberModal" :show-close-btn="false">
    <template v-slot:content>
      <div class="alert alert-info" role="alert">
        {{ $replacePlaceholders($t('Die LUCID Nr. {lucidNumber} wird bereits verwendet'), {
          lucidNumber: wizard.data.customer.lucidNumber
        }) }}
      </div>
      <hr class="my-4">
      <p>
        {{ $t('Möchten Sie eine andere LUCID Nr. verwenden?') }}<br/>
        <button class="btn btn-primary me-4 mt-3 demo-only" @click="changeLucidNr">&gt; {{ $t(`LUCID Nr. ändern`) }}</button>
      </p>
      <hr class="my-4">
      <p>
        {{ $t('Haben Sie bereits einen Vertrag?') }}<br/>
        <router-link to="/login" @click="$refs.duplicateLucidNumberModal.hide()" class="btn btn-primary me-4 mt-3 demo-only">&gt; {{ $t(`Zum Login`) }}</router-link>
      </p>
      <!--hr class="my-4">
      <p>
        {{ $t('Ich möchte einen neuen Vertrag mit dieser LUCID Nr. abschließen') }}<br/>
        <button @click="onShowNextStep" class="btn btn-primary me-4 mt-3 demo-only">&gt; {{ $t(`weiter`) }}</button>
      </p-->
    </template>
  </modal>
  <div class="bg-red-circle">
    <div class="circle"></div>
  </div> 

  <section id="step-4" class="easy-step step-4" data-step="3">
    <div class="container-fluid container-xxl p-0">
      <div class="row g-0 position-relative">
        <div class="col-center">
<!--
          <div class="bg-red-circle">
            <div class="circle"></div>
          </div>
-->

          <!--  ******************************************************************************** -->
          <section class="content form pt-3 pb-5">
            <messages-container />
            <!-- form start -->
            <Form ref="form">
              <div class="container pt-3 checkout-customer">
                <div class="new-contract-checkout-personal easy-container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <checkout-form-company ref="checkoutFormCompany" :customer="wizard.data.customer" :selected-company-country="selectedCompanyCountry" :show-email-address2="showEmailAddress2" @update:lucidNumber="onLucidNumberChanged" />
                    </div>
                    <div class="col-12 col-lg-6">
                      <hr class="my-4 d-block d-lg-none">
                      <checkout-form-address ref="checkoutFormAddress" :customer="wizard.data.customer" :selected-company-country="selectedCompanyCountry" @update:selectedCompanyCountry="onUpdateSelectedCompanyCountry" />
                    </div>
                  </div>


                </div><!-- / row -->

              </div>
            </Form>
          </section>


          <!--  ******************************************************************************** -->


          <div class="step-nav mt-5">
            <!--<a class="btn btn-primary me-4">&lt; Zurück</a><a class="btn btn-primary">> Weiter</a>-->
            <button class="btn btn-primary me-4 demo-only" @click="wizard.goBack()">&lt; {{ $t(`zurück`) }}</button>
            <button class="btn btn-primary demo-only" @click="onShowNextStep" :disabled="isCreatingCustomer">
              <div class="spinner-border wizard-step4-loader" role="status" v-if="isCreatingCustomer">
                <span class="sr-only"></span>
              </div>
              > {{ $t(`weiter`) }}
            </button>
          </div>
        </div>

        <wizard-circle-bar current-step="step4" :show-calculation-help="showCalculationHelp" />
      </div>
    </div>
  </section>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import WizardCircleBar from "./wizard-circle-bar";
import Cart from "../cart/cart";
import LoaderOverlay from "../utils/loader-overlay";
import SalutationSelect from "../form/salutation-select";
import CountrySelect from "../form/country-select";
import {scrollTop, validateEmail} from "../../../utils/utils";
import CheckoutFormCompany from "../form/checkout/checkout-form-company";
import CheckoutFormAddress from "../form/checkout/checkout-form-address";
import MessagesContainer from "../utils/messages-container.vue";
import Modal from "../modal/modal.vue";
import {ref} from "vue";

export default {
  name: "wizard-step4",
  components: {
    Modal,
    MessagesContainer,
    CheckoutFormAddress,
    CheckoutFormCompany,
    CountrySelect, SalutationSelect, LoaderOverlay, Cart, WizardCircleBar, Form, Field, ErrorMessage},
  props: {
    wizard: {
      type: Object,
      required: true
    },
    showCalculationHelp: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isCreatingCustomer: false,
      customerExists: false,
      selectedCompanyCountry: null,
      showEmailAddress2: true
    };
  },
  created() {
    scrollTop();
    easyLizeApp.apiClient.getContext((context) => {
      if(context.customer) {
        this.customerExists = true;
        this.showEmailAddress2 = !context.customer.active;
        this.wizard.data.customer = context.customer;
      }
    });
  },
  methods: {
    ref,
    onUpdateSelectedCompanyCountry(selectedCompanyCountry) {
      this.selectedCompanyCountry = selectedCompanyCountry;
    },
    onLucidNumberChanged() {
      this.wizard.data.customer.skipDuplicateLucidNumberModal = false;
    },
    changeLucidNr() {
      const lucidNumberInput = document.querySelector('.lucid-number-validation');
      this.$refs.duplicateLucidNumberModal.hide();
      if(lucidNumberInput) {
        lucidNumberInput.focus();
        lucidNumberInput.select();
      }
    },
    onShowNextStep() {
      this.isCreatingCustomer = true;
      this.$refs.duplicateLucidNumberModal.hide();
      this.$refs.checkoutFormCompany.validate().then((companyResult) => {
        if (!companyResult.valid) {
          this.isCreatingCustomer = false;
          return;
        }
        this.$refs.checkoutFormAddress.validate().then((addressResult) => {
          if (!addressResult.valid) {
            this.isCreatingCustomer = false;
            return;
          }
          easyLizeApp.apiClient.validateContractYear((result) => {
            if(!result.success) {
              this.isCreatingCustomer = false;
              return;
            }

            if(this.customerExists) {
              easyLizeApp.apiClient.updateCustomer((result) => {
                if(result.success) {
                  this.wizard.showStep('step5');
                } else {
                  this.$refs.duplicateLucidNumberModal.show();
                  this.wizard.data.customer.skipDuplicateLucidNumberModal = true;
                }
                this.isCreatingCustomer = false;
              }, this.wizard.data.customer)
            } else {
              easyLizeApp.apiClient.createCustomer((result) => {
                if(result.success) {
                  this.wizard.data.customer = result.context.customer;
                  this.wizard.showStep('step5');
                } else {
                  this.$refs.duplicateLucidNumberModal.show();
                  this.wizard.data.customer.skipDuplicateLucidNumberModal = true;
                }
                this.isCreatingCustomer = false;
              }, this.wizard.data.customer);
            }
          }, this.wizard.data.lizenzjahr);
        });
      });

      /*this.$refs.form.validate().then((result) => {
        if(!result.valid) {
          return;
        }
        this.isCreatingCustomer = true;
        easyLizeApp.apiClient.createCustomer(() => {
          this.wizard.showStep('step5');
          this.isCreatingCustomer = false;
        }, this.wizard.data.customer);
      });*/
    },

  }
}
</script>
