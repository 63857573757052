<template>
  <div class="payment-method-invoice" v-if="paymentMethod && paymentMethod.selectInvoiceInterval">
    <hr>
    <h4 class="my-4">{{$t('Zahlungsintervall')}}</h4>
    <template v-for="invoiceInterval in invoiceIntervals">
      <div class="form-check" v-if="!readonly">
        <input class="form-check-input" type="radio" name="invoiceInterval" :id="'invoiceInterval-' + invoiceInterval" :value="invoiceInterval" :checked="invoiceInterval === modelValue" @click="setInvoiceInterval(invoiceInterval)">
        <label class="form-check-label" :for="'invoiceInterval-' + invoiceInterval">
          <span v-if="invoiceInterval == 'yearly'">{{ $t(`jährlich`) }}</span>
          <span v-if="invoiceInterval == 'halfYearly'">{{ $t(`halbjährlich`) }}</span>
          <span v-if="invoiceInterval == 'quarterly'">{{ $t(`quartalsweise`) }}</span>
          <span v-if="invoiceInterval == 'monthly'">{{ $t(`monatlich`) }}</span>
          <span v-if="invoiceInterval == 'immediately'">{{ $t(`sofort`) }}</span>
        </label>
      </div>
      <label class="form-check-label" v-if="readonly && invoiceInterval === modelValue">
        <span v-if="invoiceInterval == 'yearly'">{{ $t(`jährlich`) }}</span>
        <span v-if="invoiceInterval == 'halfYearly'">{{ $t(`halbjährlich`) }}</span>
        <span v-if="invoiceInterval == 'quarterly'">{{ $t(`quartalsweise`) }}</span>
        <span v-if="invoiceInterval == 'monthly'">{{ $t(`monatlich`) }}</span>
        <span v-if="invoiceInterval == 'immediately'">{{ $t(`sofort`) }}</span>
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: "payment-method-invoice",
  emits: [ 'update:modelValue', 'change:invoiceInterval' ],
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    paymentMethod: {
      type: Object,
      required: true
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      invoiceIntervals: []
    };
  },
  created() {
    this.invoiceIntervals = this.paymentMethod.invoiceIntervals || [];
    this.setInvoiceInterval(this.invoiceIntervals[0] || null);
  },
  methods: {
    setInvoiceInterval(invoiceInterval) {
      this.$emit('update:modelValue', invoiceInterval);
      this.$emit('change:invoiceInterval', invoiceInterval);
    }
  }
}
</script>
