import productsConfig from '../../config/products';
import { roundNumber } from '../utils';

export default class Co2BilanzCalculator {
    constructor() {
        this.productsCo2Bilanz = {};
        for(let productKey in productsConfig) {
            if(typeof productsConfig[productKey].co2Bilanz === "number") {
                this.productsCo2Bilanz[productKey] = productsConfig[productKey].co2Bilanz;
            }
        }
    }

    calculateFromProducts(products) {
        let co2Bilanz = {
            total: 0.0
        };
        for(let p in products) {
            const productKey = products[p].key;
            if(typeof this.productsCo2Bilanz[productKey] !== "undefined") {
                co2Bilanz[productKey] = roundNumber(this.productsCo2Bilanz[productKey] * products[p].inputWeight, 4);
                co2Bilanz.total+= co2Bilanz[productKey];
            }
        }
        return co2Bilanz;
    }
}