<template>
  <customer-page>
    <template v-slot:page-headline>{{ $t(`Nachrichten schreiben`) }}</template>
    <template v-slot:page-content>
      <customer-message-create-form @message:created="onMessageCreated" />
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import CustomerMessageCreateForm from "../../component/customer-message/customer-message-create-form";
export default {
  name: "customer-message-create",
  components: {CustomerMessageCreateForm, CustomerPage},
  methods: {
    onMessageCreated() {
      this.$router.replace({ name: 'customer_messages_list', query: { folder: 'sent' } });
    }
  }
}
</script>

<style scoped>

</style>