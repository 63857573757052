<template>
  <div :class="modalClass" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="showModelHeader" class="modal-header">
          <h5 class="modal-title"><slot name="title"></slot></h5>
          <button v-if="showCloseBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bsModal from "bootstrap/js/src/modal";
export default {
  name: "modal",
  emits: [ 'closed' ],
  props: {
    autoOpen: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    modalBackdrop: {
      type: [ Boolean, String ],
      default: true
    },
    modalFocus: {
      type: Boolean,
      default: true
    },
    modalKeyboard: {
      type: Boolean,
      default: true
    },
    buttons: {
      type: Object,
      required: []
    },
    class: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      modalInstance: null
    }
  },
  computed: {
    showModelHeader() {
      return this.showCloseBtn || this._hasSlot('title');
    },
    modalClass() {
      if(this.class !== '') {
        return 'modal ' + this.class;
      }
      return 'modal';
    }
  },
  mounted() {
    this.$el.addEventListener('hidden.bs.modal', () => this.$emit('closed'));
    if(this.autoOpen) {
      this.show();
    }
  },
  methods: {
    _hasSlot(slot) {
      return typeof this.$slots[slot] !== "undefined";
    },
    getInstance() {
      if(!this.modalInstance) {
        this.modalInstance = new bsModal(this.$el, {
          backdrop: this.modalBackdrop,
          focus: this.modalFocus,
          keyboard: this.modalKeyboard
        });
      }
      return this.modalInstance;
    },
    show() {
      const modalInstance = this.getInstance();
      modalInstance.show();
    },
    hide() {
      const modalInstance = this.getInstance();
      modalInstance.hide();
    },
    toggle() {
      const modalInstance = this.getInstance();
      modalInstance.toggle();
    }
  }
}
</script>

<style scoped>

</style>