<template>
  <div class="bg-red-circle">
    <div class="circle"></div>
  </div> 

  <section id="step-2" class="easy-step step-2" data-step="2">
    <cart ref="cart" :contract-year="wizard.data.lizenzjahr" @cart:recalculated="onCartRecalculated" @cart:loaded="onCartLoaded" :clear-cart="clearCart" :recalculateOnLoad="true">
      <template #default="{ products, cart, params, calculateCart, isLoading }">
        <loader-overlay :is-loading="isLoading" />
        <div class="container-fluid container-xxl p-0">
          <div class="row g-0 position-relative">

            <div class="col-center">
<!--              
              <div class="bg-red-circle">
                <div class="circle"></div>
              </div>
-->

              <div class="step-nav mt-2">
                <!--<a class="btn btn-primary me-4">&lt; Zurück</a><a class="btn btn-primary">> Weiter</a>-->
                <button class="btn btn-primary me-4 demo-only" @click="wizard.goBack()">&lt; {{ $t(`zurück`) }}</button>
                <button class="btn btn-primary demo-only" :disabled="!enableNextStep(products)" @click="onShowNextStep()"> > {{ $t(`weiter`) }}</button>

              </div>

              <div class="col-12 easy-container omg-layout my-5">

                <div class="row">
                  <div class="col-12 col-lg-4 order-2 order-lg-1">

                    <form id="form-lizenzjahr" class="form mt-3 mt-lg-0">
                      <div class="d-flex select-lizenzjahr flex-row justify-content-lg-between align-items-center flex-wrap">
                      <label class="form-label fs-24 fw-b me-3 me-lg-0">{{ $t(`Lizenzjahr`) }}: </label>
                      <contract-year-select v-model="wizard.data.lizenzjahr" :aria-label="$t(`auswählen`)" />
                      </div>

                    </form>

                  </div>
                  <div class="col-12 col-lg-4 order-1 order-lg-2">
                    <form id="form-retail" class="form">
                        <div class="btn btn-primary" data-bs-toggle="offcanvas"
                             data-bs-target="#offcanvasRight">{{ $t(`Verpackungsgewichte ermitteln`) }}</div>
                    </form>
                  </div>
                  <div class="col-12 col-lg-4 order-3 order-lg-3">
                    <div class="total-value fs-24 fw-b d-flex justify-content-lg-end justify-content-start mt-3 mt-lg-0" v-if="cart">
                      <div class="total-value-title me-3">{{ $t(`Ihr Nettopreis`) }}: </div>
                      <div id="total-value">{{ $filters.formatNumber(cart.price.netPrice) }} EUR</div>
                    </div>
                  </div>
                </div>

                <form id="fraktionen-form">
                  <div class="row">
                    <div class="col-12 col-lg-6 mt-5">
                      <div class="row">
                        <div v-for="product in bigProducts" class="col-12 col-sm-6">
                          <div class="box-fraktion box-big">
                            <div class="fraktion-image">
                              <img id="fraktion-ppk" class="d-block mx-lg-auto img-fluid" :alt="product.title" rel="preload" :src="product.image" />
                            </div>
                            <div class="fraktion-info-icon" :data-show="`info-${product.key}`" v-if="showFraktionInfo">
                              <div class="icon-circle" @click="showInfo(product.key);">i</div>
                            </div>
                            <div class="fraktion-label">
                              <span v-html="product.title"></span>
                            </div>
                            <div v-if="!isLoading" class="fraktion-input">
                              <div class="input-group mb-3">
                                <weight-input class="form-control" placeholder="Menge eingeben" v-model="getProduct(products, product.key).inputWeight" @update:modelValue="calculateCart" :decimal-places="params.weightInputDecimalPlaces || 4" />
                                <span class="input-group-text" id="menge-ppk">kg</span>
                              </div>
                            </div>
                            <div :id="`info-${product.key}`" v-if="showFraktionInfo" class="fraktion-info"><span v-html="getProductConfigValue(product.key, 'infoText')"></span> <div class="close-info" @click="closeInfo">&times</div></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="switch-more-fraktionen" class="col-12 col-lg-6 cursor-pointer d-flex flex-column justify-content-center align-content-conter align-items-center mt-5 mt-lg-0" v-if="!showMoreFractions">
                      <div class="switch-more-fraktionen" @click="switchMoreFractions()">
                        <div class="d-flex align-items-center"><img class="me-3" id="more-circle-button" alt="Weitere Fraktionen" rel="preload" src="../../../../assets/images/more-circle-button.svg" height="60" width="60" /> {{ $t(`Weitere Materialfraktionen einblenden`) }}</div>
                      </div>
                    </div>

                    <div id="show-more-fraktionen" class="col-12 col-lg-6 mt-5" v-if="showMoreFractions">
                      <div class="faktionsliste">
                        <div v-for="product in smallProducts" class="box-fraktion box-small">
                          <div class="fraktion-image">
                            <img id="fraktion-ppk" class="d-block mx-lg-auto img-fluid" :alt="product.title" rel="preload" :src="product.image" />
                          </div>
                          <div class="fraktion-info-icon" :data-show="`info-${product.key}`" v-if="showFraktionInfo">
                            <div class="icon-circle" @click="showInfo(product.key);">i</div>
                          </div>
                          <div class="fraktion-label">
                            <span v-html="product.title"></span>
                          </div>
                          <div v-if="!isLoading" class="fraktion-input">
                            <div class="input-group mb-3">
                              <weight-input class="form-control" placeholder="Menge eingeben" v-model="getProduct(products, product.key).inputWeight" @update:modelValue="calculateCart" :decimal-places="params.weightInputDecimalPlaces || 4" />
                              <span class="input-group-text" id="menge-ppk">kg</span>
                            </div>
                          </div>
                          <div :id="`info-${product.key}`" v-if="showFraktionInfo" class="fraktion-info"><span v-html="getProductConfigValue(product.key, 'infoText')"></span> <div class="close-info" @click="closeInfo">&times</div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              
            </div><!-- /col-center -->

            <wizard-circle-bar current-step="step2" :show-calculation-help="showCalculationHelp" />

          </div>
        </div>

        <berechnungshilfe-offcanvas id="offcanvasRight" :products="products" :calculate-cart="calculateCart" />
      </template>
    </cart>
  </section>
</template>

<script>
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/offcanvas';
import WizardCircleBar from "./wizard-circle-bar";
import Cart from "../cart/cart";
import WeightInput from "../cart/weight-input";
import LoaderOverlay from "../utils/loader-overlay";
import BerechnungshilfeOffcanvas from "./wizard-step2/berechnungshilfe-offcanvas";
import Co2BilanzCalculator from "../../../utils/products/co2BilanzCalculator";
import ContractYearSelect from "../form/contract-year-select";
import productsConfig from "../../../config/products";
import branchen from "../../../config/branchen";
import { scrollTop } from '../../../utils/utils';
import MaxOrderVolumeModal from "../modal/max-order-volume-modal.vue";
import {isClearCart} from "../../../utils/router";
import wizard from "../../pages/easy-lize/wizard.vue";

const co2BilanzCalculator = new Co2BilanzCalculator();

export default {
  name: "wizard-step2",
  components: {
    MaxOrderVolumeModal,
    ContractYearSelect, BerechnungshilfeOffcanvas, LoaderOverlay, WeightInput, Cart, WizardCircleBar},
  props: {
    wizard: {
      type: Object,
      required: true
    },
    showCalculationHelp: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showMoreFractions: false,
      retailHelper: false,
      showFraktionInfo: false,
      bigProducts: [],
      smallProducts: [],
      clearCart: true
    };
  },
  created() {
    scrollTop();
    this.loadProducts();
    this.clearCart = this.wizard.clearCart;
    this.wizard.clearCart = false;
  },
  methods: {
    onShowNextStep() {
      this.$refs.cart.calculateCart(() => {
        this.wizard.showStep('step3');
      });
    },
    _recalculateCo2Bilanz(products) {
      this.wizard.data.co2Bilanz = co2BilanzCalculator.calculateFromProducts(products);
    },
    _getSelectedBranchen() {
      const keys = [];
      for (let c in branchen) {
        for(let branchenKey in branchen[c].branchen) {
          if(typeof this.wizard.data.branchen[branchenKey] === "undefined") {
            continue;
          }
          if(!this.wizard.data.branchen[branchenKey]) {
            continue;
          }
          if(typeof branchen[c].branchen[branchenKey]["products"] === "undefined") {
            continue;
          }
          keys.push(branchen[c].branchen[branchenKey]);
        }
      }
      return keys;
    },
    _getBranchenKeys() {
      const keys = [];
      for (let c in branchen) {
        for(let branchenKey in branchen[c].branchen) {
          keys.push(branchenKey);
        }
      }
      return keys;
    },
    loadProducts() {
      const selectedBranchen = this._getSelectedBranchen();
      const assocProducts = {};
      let position = 10;
      for(let productKey in productsConfig) {
        assocProducts[productKey] = {
          key: productKey,
          title: this.getProductConfigValue(productKey, 'title'),
          image: productsConfig[productKey].image,
          position: position
        };
        position+= 10;
      }
      try {
        if (selectedBranchen.length === 1) {
          const branche = selectedBranchen[0];
          let bigProductPosition = 1;
          for (let productKey in branche['products']) {
            assocProducts[productKey].position = bigProductPosition;
            assocProducts[productKey].image = branche['products'][productKey];
            bigProductPosition = bigProductPosition + 1;
            if(bigProductPosition >= 3) {
              break;
            }
          }
        }
      } catch(e) {
        console.error(e);
      }

      const sortedProducts = Object.values(assocProducts);
      sortedProducts.sort((l, r) => { return l.position - r.position; });
      for(let i = 0; i < sortedProducts.length; i++) {
        if(i > 1) {
          this.smallProducts.push(sortedProducts[i]);
        } else {
          this.bigProducts.push(sortedProducts[i]);
        }
      }
    },
    onCartLoaded(cart, products) {
      this._recalculateCo2Bilanz(products);
    },
    onCartRecalculated({ products }) {
      this._recalculateCo2Bilanz(products);
    },
    switchMoreFractions() {
      this.showMoreFractions = true;
    },
    showInfo(fraktion) {
      const box = document.querySelector("#info-" + fraktion);
      box.classList.add("show");
    },
    closeInfo(event) {
      const box = event.target.closest('.fraktion-info');
      box.classList.remove('show');
    },
    getProduct(products, productKey) {
      for(let productId in products) {
        if(products[productId].key === productKey) {
          return products[productId];
        }
      }
      return {};
    },
    enableNextStep(products) {
      if(!this.wizard.data.lizenzjahr) {
        return false;
      }
      for(let p in products) {
        if(products[p].inputWeight > 0) {
          return true;
        }
      }
      return false;
    },
    getProductConfigValue(productKey, configKey)
    {
      if(typeof productsConfig[productKey] === "undefined") {
        return '';
      }
      if(typeof productsConfig[productKey][configKey] === "undefined") {
        return '';
      }
      if(typeof productsConfig[productKey][configKey][easyLizeApp.locale] === "undefined") {
        return '';
      }
      return productsConfig[productKey][configKey][easyLizeApp.locale];
    }
  }
}
</script>
