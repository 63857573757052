<template>
<table class="table easy-table default-items-table">
  <thead>
  <tr>
    <th v-for="column in columns">{{ column.label }}</th>
  </tr>
  </thead>
  <tbody v-if="!isLoading">
  <tr v-for="row in rows" class="default-form-item">
    <td v-for="(column, index) in columns" >
      <div v-if="index == 0" class="mt-3 d-block d-lg-none"></div>
      <div class="d-flex flex-row justify-content-between justify-content-lg-start">
        
          <div class="d-block d-lg-none"><b>{{ column.label }}&nbsp;</b></div>
          <div>
            <slot :name="`column-${column.field}`" v-bind="{ row }">{{ getColumnValue(row, column.field) }}</slot>
          </div>
        </div>
      
    </td>
  </tr>
  </tbody>
</table>
  <div class="table-loading-placeholder" v-if="isLoading">
    <p class="placeholder-glow" v-for="index in 1">
      <span class="placeholder col-12"></span>
    </p>
  </div>
</template>

<script>
export default {
  name: "data-table",
  props: {
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Object,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getColumnValue(row, field) {
      const fields = field.split('.');
      let currentValue = row;
      for(let f in fields) {
        const fieldPart = fields[f];
        currentValue = currentValue[fieldPart] || {};
      }
      return currentValue;
    }
  }
}
</script>
