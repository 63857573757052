<template>
<customer-page size="full">
  <template v-slot:page-headline>
    <template v-if="showContractYearTitle">{{ $replacePlaceholders($t('Mengen {contractYear} lizenzieren'), { contractYear: contractYear }) }}</template>
    <template v-else>{{ $t('Verpackungslizenz Rechner') }}</template>
  </template>
  <template v-slot:page-subline v-if="!showContractYearTitle"><h2>{{ $tc('Jährliche Verpackungsmengen eingeben und Lizenz-Kosten berechnen') }}</h2></template>
  <template v-slot:page-content>
    <div class="action-bar text-center mb-3" v-if="!showContractYearTitle">
      <div class="d-inline-block fs-24"><label class="form-label me-3">{{ $t("Lizenzjahr") }}:</label></div>
      <div class="d-inline-block"><contract-year-select v-model="contractYear" name="contractYear" /></div>
    </div>
    <cart ref="cart" @cart:changed="onCartChanged" :contract-year="contractYear" :clear-cart="clearCart" :recalculate-on-load="true" />
    <div class="w-100 d-flex justify-content-end">
      <div>
        <a href="#" v-if="!nextBtnEnabled" class="btn btn-primary btn-lg disabled">&gt; {{ $t(`weiter`) }}</a>
        <a href="#" v-if="nextBtnEnabled" @click="onStartCheckout" class="btn btn-primary btn-lg">&gt; {{ $t(`weiter`) }}</a>
      </div>
    </div>
  </template>
</customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import ContractPeriodCheckout from "./contract-period-checkout";
import Cart from "../../component/cart/cart";
import ContractYearSelect from "../../component/form/contract-year-select";
import MaxOrderVolumeModal from "../../component/modal/max-order-volume-modal.vue";
import {isClearCart} from "../../../utils/router";
export default {
  name: "contract-new",
  components: {ContractYearSelect, Cart, ContractPeriodCheckout, CustomerPage},
  data() {
    return {
      nextBtnEnabled: false,
      showContractYearTitle: (this.$route.params.contractYear ?? null) != null,
      contractYear: this.$route.params.contractYear,
      clearCart: true
    }
  },
  created() {
    this.clearCart = isClearCart(this.$router, this.$route);
  },
  methods: {
    onCartChanged({ cart, cartComponent }) {
      this.updateNextBtnEnabled(cart, cartComponent);
    },
    updateNextBtnEnabled(cart, cartComponent) {
      this.nextBtnEnabled = cart && (!cartComponent.isZeroInput() || this.showContractYearTitle) && this.contractYear !== "";
    },
    onStartCheckout() {
      this.$router.push({ name: 'contract_new_checkout', params: { contractYear: this.contractYear } });
    }
  }
}
</script>

<style scoped>

</style>