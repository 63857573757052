class Messenger {
  constructor(app) {
    this.app = app;
    this.$router = this.app.config.globalProperties.$router;
    this.$emitter = this.app.config.globalProperties.$emitter;
    this.messages = {};
    this.messageListener = null;
    this.$emitter.on('api-client-error', this._catchApiErrors.bind(this));
    this.$emitter.on('customer-group-changed', this._catchCustomerGroupChanged.bind(this));
    this.$emitter.on('contract-year-invalid', this._catchContractYearInvalidFailed.bind(this));
  }

  _catchApiErrors(result) {
      this.pushShopwareErrors(result.errors);
  }

  _catchCustomerGroupChanged(customerGroup) {
    this.pushMessage('success', 'Sie wurden der Kundengruppe ' + customerGroup.name + ' zugeordnet.')
  }

  _catchContractYearInvalidFailed(error) {
    if(error.error == 'contractYearNotAvailable') {
      this.pushMessage('danger', 'Das Lizenzjahr ' + error.contractYear + ' kann nicht bestellt werden.');
    }
  }

  _triggerOnMessage() {
    if(this.messageListener) {
      (this.messageListener)();
    }
  }

  _getRouteName(forRoute) {
    if(forRoute === null) {
      forRoute = this.$router.currentRoute.value || null;
    } else if(typeof forRoute === "string") {
      return forRoute;
    }
    if(!forRoute) {
      return null;
    }
    return forRoute.name;
  }

  setMessageListener(messageListener) {
    this.messageListener = messageListener;
  }

  clearMessages(forRoute = null) {
    if(forRoute && typeof this.messages[forRoute] !== 'undefined') {
      delete this.messages[forRoute];
    } else if(!forRoute) {
      this.messages = {};
    }
  }

  pushShopwareErrors(errors, forRoute = null) {
    const routeName = this._getRouteName(forRoute);
    for(let e in errors) {
      const error = errors[e];
      let errorMessage = error.detail;
      this.pushMessage('danger', errorMessage, forRoute);
    }
    this._triggerOnMessage();
  }

  pushMessage(type, message, forRoute = null) {
    const routeName = this._getRouteName(forRoute);
    if(!routeName) {
      return false;
    }

    this.messages[routeName] = this.messages[routeName] || [];
    this.messages[routeName].push({
      type: type,
      message: message,
      date: new Date()
    });
    this._triggerOnMessage();
    return true;
  }

  getMessages(callback, forRoute = null) {
    const routeName = this._getRouteName(forRoute);
    if(!routeName) {
      return;
    }
    if(typeof this.messages[routeName] === "undefined") {
      return;
    }
    const messages = [];
    for(let m in this.messages[routeName]) {
      messages.push(this.messages[routeName][m]);
    }
    callback(messages);
    delete (this.messages[routeName]);
  }
};
export default {
  install(app, options) {
    app.config.globalProperties.$messenger = new Messenger(app);
  }
};