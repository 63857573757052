<template>
  <Form ref="form">
    <div class="form-check" v-if="showGtcLink">
      <Field class="form-check-input" type="checkbox" value="1" name="gtc" id="flexCheckDefault" :rules="isRequired" required="required" />
      <label class="form-check-label" for="flexCheckDefault">
        {{ $t(`Ich habe die`) }} <agb-link target="_blank">{{ $t(`AGB`) }}</agb-link> {{ $t(`gelesen und akzeptiert`) }}
      </label>
      <ErrorMessage name="gtc" />
    </div>
    <div class="form-check">
      <Field class="form-check-input" type="checkbox" value="1" name="privacy" id="flexCheckChecked" :rules="isRequired" required="required" />
      <label class="form-check-label" for="flexCheckChecked">
        {{ $t(`Ich habe die`) }} <a href="https://www.easy-lize.de/datenschutzhinweise" target="_blank">{{ $t(`Datenschutzhinweise`) }}</a> {{ $t(`gelesen und akzeptiert`) }}
      </label>
      <ErrorMessage name="privacy" />
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import AgbLink from "../../utils/agb-link";

export default {
  name: "gtc-container",
  components: {AgbLink, Form, Field, ErrorMessage },
  props: {
    showGtcLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    isRequired(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
}
</script>

<style scoped>

</style>