<template>
  <customer-page size="full">
    <template v-slot:page-headline>
      {{ $t('Lizenzrechner') }}
    </template>
    <template v-slot:page-content>
      <loader-overlay :is-loading="isLoading" />
      <modal v-if="!isLoading && showError" :auto-open="true" :show-close-btn="false" class="payment-failed-modal">
        <template v-slot:content>
          <div class="alert alert-danger" role="alert">
            {{ $t('Zahlung fehlgeschlagen') }}
          </div>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('Schließen') }}</button>
        </template>
      </modal>
      <div class="col-12 easy-container mb-5" v-if="!isLoading">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h4 class="my-4">{{ $t(`Unternehmen`) }}</h4>
              <div class="row">
                <div class="col-md-5">{{ $t(`Firma`) }}</div>
                <div class="col-md-7">{{ customer.company }}</div>

                <div class="col-md-5">{{ $t(`LUCID Nr.`) }}</div>
                <div class="col-md-7">{{ customer.lucidNumber }}</div>

                <div class="col-md-5">{{ $t(`Umsatzsteuer ID-Nr.`) }}</div>
                <div class="col-md-7">{{ customer.vatId }}</div>

                <div class="col-md-5">{{ $t(`Steuer-Nr.`) }}</div>
                <div class="col-md-7">{{ customer.taxNumber }}</div>
                <hr class="my-4">
                <h4 class="my-4">{{ $t(`Adresse`) }}</h4>
                <div class="col-md-5">{{ $t(`Kontaktadresse`) }}</div>
                <div class="col-md-7">
                  {{ customer.companyAddress.company }}<br>
                  {{ customer.companyAddress.firstName }} {{ customer.companyAddress.lastName }} <br>

                  <span v-if="customer.companyAddress.additionalAddressLine1"> {{ customer.companyAddress.additionalAddressLine1 }} <br></span>
                  <span v-if="customer.companyAddress.additionalAddressLine2"> {{ customer.companyAddress.additionalAddressLine2 }} <br></span>
                  {{ customer.companyAddress.street }}<br>
                  {{ customer.companyAddress.zipcode }} {{ customer.companyAddress.city }}<br>
                  {{ customer.companyAddress.country.name }}
                </div>

                <div v-if="customer.differentBillingAddress" class="col-md-5 mt-3">{{ $t(`Rechnungsadresse`) }}</div>
                <div v-if="customer.differentBillingAddress" class="col-md-7 mt-3">
                  {{ customer.billingAddress.company }}<br>
                  {{ customer.billingAddress.firstName }} {{ customer.billingAddress.lastName }} <br>
                  <span v-if="customer.billingAddress.additionalAddressLine1"> {{ customer.billingAddress.additionalAddressLine1 }} <br></span>
                  <span v-if="customer.billingAddress.additionalAddressLine2"> {{ customer.billingAddress.additionalAddressLine2 }} <br></span>
                  {{ customer.billingAddress.street }}<br>
                  {{ customer.billingAddress.zipcode }} {{ customer.billingAddress.city }}<br>
                  {{ customer.billingAddress.country.name }}
                </div>
                <hr class="my-4">
                <h4 class="my-4">{{ $t(`Kontakt`) }}</h4>

                <div class="col-md-5">{{ $t(`Anrede`) }}</div>
                <div class="col-md-7">{{ customer.salutation.displayName }}</div>

                <div class="col-md-5">{{ $t(`Vorname`) }}</div>
                <div class="col-md-7">{{ customer.firstName }}</div>

                <div class="col-md-5">{{ $t(`Nachname`) }}</div>
                <div class="col-md-7">{{ customer.lastName }}</div>

                <div class="col-md-5">{{ $t(`E-Mail`) }}</div>
                <div class="col-md-7">{{ customer.emailAddress }}</div>

                <div class="col-md-5">{{ $t(`Telefon-Nr.`) }}</div>
                <div class="col-md-7">{{ customer.phoneNumber }}</div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <h4 class="my-4">{{ $t(`Jahresmenge Verpackungen`) }}</h4>
              <template v-for="lineItem in cart.lineItems">
                <div class="row" v-if="lineItem.type != 'minimumValue' && lineItem.type != 'promotion'">
                  <div class="col-md-7">{{ lineItem.label }}</div>
                  <div class="col-md-5">{{ $filters.formatNumber(lineItem.extensions['cart-item-weight'].weight ?? 0.0, 4) }} kg</div>
                </div>
              </template>
              <div class="row mt-3">

                <div class="col-md-7">{{ $t(`Lizenzjahr`) }}</div>
                <div class="col-md-5">{{ contractPeriodQuantity.contractPeriod.contractYear }}</div>

                <div class="col-md-7">{{ $t(`Zwischensumme`) }}</div>
                <div class="col-md-5">{{ $filters.formatNumber(cart.price.netPrice) }} EUR</div>
              </div>

              <div class="row" v-for="tax in cart.price.calculatedTaxes">
                <div class="col-md-7">{{ $t(`MwSt.`) }} {{ tax.taxRate }} %</div>
                <div class="col-md-5">{{ $filters.formatNumber(tax.tax) }} EUR</div>
              </div>

              <div class="row mt-3">

                <div class="col-md-7"><b>{{ $t(`Rechnungsbetrag`) }}</b></div>
                <div class="col-md-5"><b>{{ $filters.formatNumber(cart.price.totalPrice) }} EUR</b></div>

              </div>
              <hr>
              <payment-method-select ref="paymentmethodSelect" :contract-year="contractPeriodQuantity.contractPeriod.contractYear" :contract-period-id="contractPeriodQuantity.contractPeriod.id" v-model:payment-method-id="paymentMethodId" v-model:invoice-interval="invoiceInterval" @validity-change="onPaymentmethodValidityChange" @handle-payment="onHandlePayment">
                <template v-slot:content-after="{ isLoading, hidePaymentMethods }">
                  <hr v-if="!isLoading && !hidePaymentMethods">
                </template>
              </payment-method-select>
              <gtc-container ref="gtcContainer" id="gtc-container" />
            </div>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-between mt-5 pt-5">
          <a :href="$getUrl($route, '/')" class="btn btn-primary me-4 demo-only">&lt; {{ $t(`zurück`) }}</a>
          <button class="btn btn-primary me-4 demo-only" @click="onCreatePayment" :disabled="isCreatingPayment">> {{ $t(`kostenpflichtig bezahlen`) }}</button>
        </div>
      </div>
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import PaymentMethodSelect from "../../component/form/payment-method/payment-method-select";
import GtcContainer from "../../component/form/checkout/gtc-container";
import Modal from "../../component/modal/modal";
export default {
  name: "payment-start",
  components: {Modal, GtcContainer, PaymentMethodSelect, CustomerPage},
  props: {
    showError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      isCreatingPayment: false,
      customer: null,
      cart: null,
      contractPeriodQuantity: null,
      paymentMethodId: null,
      invoiceInterval: null,
      paymentMethodValid: true
    }
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContractPeriodQuantity((contractPeriodQuantity) => {
      this.contractPeriodQuantity = contractPeriodQuantity;
      const createCartFromContractPeriodQuantity = () => {
        easyLizeApp.apiClient.createCartFromContractPeriodQuantity((cart) => {
          this.cart = cart;
          this.isLoading = false;
        }, this.$route.params.contractPeriodQuantityId);
      }
      easyLizeApp.apiClient.hashLogin(() => {
        easyLizeApp.apiClient.getContext((context) => {
          this.customer = context.customer;
          createCartFromContractPeriodQuantity();
        });
      }, contractPeriodQuantity.contract.customer.id, contractPeriodQuantity.contract.customer.hash);


    }, this.$route.params.contractPeriodQuantityId);

  },
  methods: {
    onPaymentmethodValidityChange(result) {
      this.paymentMethodValid = result.success;
    },
    onHandlePayment(paymentOptions, startPayment) {
      this._startPayment(paymentOptions, startPayment);
    },
    onCreatePayment() {
      this.$refs.gtcContainer.validate().then((gtcResult) => {
        if (!gtcResult.valid) {
          return;
        }
        this.$refs.paymentmethodSelect.validate((paymentOptions, startPayment) => {
          this._startPayment(paymentOptions, startPayment);
        });
      });
      //this.isCreatingPayment = true;
    },
    _startPayment(paymentOptions, startPayment) {
      this.isCreatingPayment = true;
      easyLizeApp.apiClient.contractPeriodQuantityPayment((response) => {
        if(typeof startPayment == 'function') {
          startPayment(response);
        } else {
          window.location.replace(response.redirect);
        }
      }, this.contractPeriodQuantity.id, this.paymentMethodId, this.invoiceInterval, paymentOptions);
    }
  }
}
</script>

<style scoped>

</style>