<template>
<customer-page>
  <template v-slot:page-headline>
    {{ $t('Vertrag kündigen') }}
  </template>
  <template v-if="!isLoading" v-slot:page-content>
    <Form ref="form" v-if="!contractCancelled">
      <div class="mb-3">
        {{ $replacePlaceholders($t('Info zur Kündigung zum {nextCancellationDateTime}'), {
          nextCancellationDateTime: $d(nextCancellationDateTime, 'short')
        }) }}
      </div>
      <div class="mb-3">
        <Field v-model="cancellationReason" name="cancellation-reason" class="form-select cancellation-reason-select" as="select" :rules="cancellationReasonRequired">
          <option :value="-1" selected>{{ $t('Bitte wählen Sie einen Küdigungsgrund aus') }}</option>
          <option :value="$t('Attraktiverer Preis bei einem Wettbewerber')">{{ $t('Attraktiverer Preis bei einem Wettbewerber') }}</option>
          <option :value="$t('vorsorgliche Kündigung')">{{ $t('vorsorgliche Kündigung') }}</option>
          <option :value="$t('Geschäftsaufgabe')">{{ $t('Geschäftsaufgabe') }}</option>
          <option :value="$t('unzufrieden mit Service')">{{ $t('unzufrieden mit Service') }}</option>
          <option value="">{{ $t('anderer') }}</option>
        </Field>
        <ErrorMessage name="cancellation-reason" />
      </div>
      <div class="mb-3" v-if="cancellationReason === ''">
        <label for="custom-cancellation-reason" class="form-label">{{ $t('Küdigungsgrund') }}</label>
        <Field v-model="customerCancellationReason" class="form-control custom-cancellation-reason" name="custom-cancellation-reason" rows="5" as="textarea" :rules="customerCancellationReasonRequred"></Field>
        <ErrorMessage name="custom-cancellation-reason" />
      </div>
      <div class="mb-3">
        <div class="form-check">
          <Field v-model="contractCancelConfirmCheck" class="form-check-input" type="checkbox" :value="true" id="contractCancelConfirmCheck" name="contractCancelConfirmCheck" :rules="contractCancelConfirmCheckRequired" />
          <label class="form-check-label" for="contractCancelConfirmCheck">{{ $t('Ich möchte meinen Vertrag kündigen') }}</label>
          <ErrorMessage name="contractCancelConfirmCheck" />
        </div>
      </div>
    </Form>
    <div v-if="contractCancelled">
      <p>{{ $t('Hiermit bestätigen wir Ihnen den Eingang der Kündigung.') }}</p>
      <p>
        {{ $replacePlaceholders($t('Ihr vertrag endet zum {nextCancellationDateTime}'), {
        nextCancellationDateTime: $d(nextCancellationDateTime, 'short')
      }) }}
      </p>
    </div>
    <div class="w-100 d-flex justify-content-between pt-5">
      <div><router-link class="btn btn-primary" :to="{ name: 'contract_view', params: { contractId: contractPeriod.contractId, contractYear: contractPeriod.contractYear } }">&lt; {{ $t(`zurück`) }}</router-link></div>
      <button v-if="!contractCancelled" class="btn btn-primary" @click="onCancelContract" :disabled="contractCancelling">&gt; {{ $t('Vertrag kündigen') }}</button>
    </div>
  </template>
</customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "contract-cancel",
  components: {CustomerPage, Form, Field, ErrorMessage},
  data() {
    return {
      isLoading: false,
      contractCancelled: false,
      contractCancelling: false,
      contractPeriod: null,
      contractCancelConfirmCheck: false,
      nextCancellationDateTime: null,
      cancellationReason: -1,
      customerCancellationReason: ''
    };
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContractPeriod((contractPeriod) => {
      this.contractPeriod = contractPeriod;
      easyLizeApp.apiClient.getNextContractCancellationDate((result) => {
        this.nextCancellationDateTime = result.nextCancellationDateTime;
        if(result.isCancelled) {
          this.$messenger.pushMessage('danger', this.$t('Ihr Vertrag ist bereits gekündigt'), 'contract_view');
          this.$router.go(-1);
          return;
        }
        this.isLoading = false;
      }, contractPeriod.contractId);
    }, this.$route.params.contractPeriodId);
  },
  methods: {
    cancellationReasonRequired(value) {
      if (value === -1) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    customerCancellationReasonRequred(value) {
      if(this.cancellationReason === '' && value === '') {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    contractCancelConfirmCheckRequired(value) {
      if(!value) {
        return this.$t('Dieses Feld ist ein Pflichtfeld');
      }
      return true;
    },
    onCancelContract(e) {
      e.preventDefault();
      this.$refs.form.validate().then((result) => {
        if(!result.valid) {
          return;
        }
        this.contractCancelling = true;
        easyLizeApp.apiClient.cancelContract(() => {
          this.contractCancelled = true;
        }, this.contractPeriod.contractId, this.cancellationReason === '' ? this.customerCancellationReason : this.cancellationReason);
      });
    }
  }
}
</script>

<style scoped>

</style>