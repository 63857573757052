<template>
  <customer-page>
    <template v-slot:page-headline>{{ $t(`Ihre Stammdaten bearbeiten`) }}</template>
    <template v-slot:page-content v-if="!isLoading">
      <hr>
      <checkout-form-company ref="checkoutFormCompany" :customer="customer" :selected-company-country="selectedCompanyCountry" :show-email-address2="false" v-if="!isLoading" :show-customer-number="true" />
      <checkout-form-address ref="checkoutFormAddress" :customer="customer" :selected-company-country="selectedCompanyCountry" @update:selectedCompanyCountry="onUpdateSelectedCompanyCountry" />
      <div class="w-100 d-flex justify-content-between pt-5">
        <div><a class="btn btn-primary btn-back" :href="$getUrl($route, '/customer')">&lt; {{ $t(`zurück`) }}</a></div>
        <button class="btn btn-primary" :disabled="isUpdating" @click="onChangeCustomerAccount">> {{ $t(`Stammdaten speichern`) }}</button>
      </div>
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import CheckoutFormCompany from "../../component/form/checkout/checkout-form-company";
import CheckoutFormAddress from "../../component/form/checkout/checkout-form-address";
import {scrollTop} from "../../../utils/utils";
export default {
  name: "customer-account-edit",
  components: {CheckoutFormAddress, CheckoutFormCompany, CustomerPage},
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      customer: null,
      selectedCompanyCountry: null
    }
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContext((context) => {
      this.customer = context.customer;
      this.isLoading = false;
    });
  },
  methods: {
    onUpdateSelectedCompanyCountry(selectedCompanyCountry) {
      this.selectedCompanyCountry = selectedCompanyCountry;
    },
    onChangeCustomerAccount(e) {
      e.preventDefault();
      this.$refs.checkoutFormCompany.validate().then((companyResult) => {
        if(!companyResult.valid) {
          return;
        }
        this.$refs.checkoutFormAddress.validate().then((addressResult) => {
          if(!addressResult.valid) {
            return;
          }
          this.isUpdating = true;
          easyLizeApp.apiClient.updateCustomer((result) => {
            this.isUpdating = false;
            if(result.duplicateLucidNumber) {
              this.$messenger.pushMessage('danger', this.$replacePlaceholders(this.$t('Die LUCID Nr. {lucidNumber} wird bereits verwendet'), {
                lucidNumber: this.customer.lucidNumber
              }));
              scrollTop();
              return;
            }
            this.$messenger.pushMessage('success', this.$t('Ihre Stammdaten wurde gespeichert'));
            scrollTop();
          }, this.customer);
        });
      });
    }
  }
}
</script>
