<template>
  <loader-overlay :is-loading="isLoading" />
  <div class="credit-card-form row" v-if="!isLoading">
    <input type="hidden" data-crefopay="paymentMethod" :value="crefopayPaymentMethod">
    <div v-if="crefopayPaymentMethod === 'CC3D' && errors.length === 0">
      <div class="crefopay-instrument-form--element col-12 mb-2">
        <label for="crefopay-accountHolder">
          {{ $t(`Inhaber`) }}
        </label>
        <div class="form-control"
             id="crefopay-accountHolder"
             data-crefopay-placeholder="paymentInstrument.accountHolder">
        </div>
        <div class="crefopay-field--error text-danger fw-bold" v-if="showErrors.accountHolder">
          {{ $t(`Dieses Feld ist ungültig`) }}
        </div>
      </div>

      <div class="crefopay-instrument-form--element col-12 mb-2">
        <label for="crefopay-number">
          {{ $t(`Nummer`) }}
        </label>
        <div class="form-control"
             id="crefopay-number"
             data-crefopay-placeholder="paymentInstrument.number">
        </div>
        <div class="crefopay-field--error text-danger fw-bold" v-if="showErrors.number">
          {{ $t(`Dieses Feld ist ungültig`) }}
        </div>
      </div>

      <div class="crefopay-instrument-form--element col-12 col-md-6 mb-2">
        <label for="crefopay-validity">
          {{ $t(`Ablaufdatum`) }}
        </label>
        <div type="text" class="form-control"
             id="crefopay-validity"
             data-crefopay-placeholder="paymentInstrument.validity">
        </div>
        <div class="crefopay-field--error text-danger fw-bold" v-if="showErrors.validity">
          {{ $t(`Dieses Feld ist ungültig`) }}
        </div>
      </div>

      <div class="crefopay-instrument-form--element col-12 col-md-6 mb-2">
        <label for="crefopay-cvv">
          {{ $t(`CVV`) }}
        </label>
        <div id="crefopay-cvv" type="text" class="form-control"
             data-crefopay-placeholder="paymentInstrument.cvv">
        </div>
        <div class="crefopay-field--error text-danger fw-bold" v-if="showErrors.cvv">
          {{ $t(`Dieses Feld ist ungültig`) }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="crefopayPaymentMethod === 'DD' && errors.length === 0">
    <div class="form-group crefopay-instrument-form--element col-5 instrument--new">
      <label for="crefopay-newBankAccountHolder">
        {{ $t(`Inhaber`) }}
      </label>

      <input type="text"
             id="crefopay-newBankAccountHolder"
             class="form-control"
             data-crefopay="paymentInstrument.bankAccountHolder"/>

      <div class="crefopay-field--error" hidden="hidden">
        {{ $t(`Dieses Feld ist ungültig`) }}
      </div>
    </div>

    <div class="form-group crefopay-instrument-form--element col-6 instrument--new">
      <label for="crefopay-newIban">
        {{ $t(`IBAN`) }}
      </label>

      <input type="text"
             id="crefopay-newIban"
             class="form-control"
             data-crefopay="paymentInstrument.iban"/>

      <div class="crefopay-field--error" hidden="hidden">
        {{ $t(`Dieses Feld ist ungültig`) }}
      </div>
    </div>

    <div class="form-group crefopay-instrument-form--element col-5 instrument--new">
      <label for="crefopay-newBic">
        {{ $t(`BIC`) }}
      </label>

      <input type="text"
             id="crefopay-newBic"
             class="form-control"
             data-crefopay="paymentInstrument.bic"/>

      <div class="crefopay-field--error" hidden="hidden">
        {{ $t(`Dieses Feld ist ungültig`) }}
      </div>
    </div>
  </div>
  <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
    <ul>
      <li v-for="error in errors">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
import LoaderOverlay from "../../utils/loader-overlay";
import jQuery from 'jquery';
import config from "../../../../config/apiClient";

export default {
  name: "payment-method-crefopay",
  emits: [ 'validity-change' ],
  components: {LoaderOverlay},
  props: {
    paymentMethodShortName: {
      type: String,
      required: true
    }
  },
  computed: {
    crefopayPaymentMethod() {
      switch (this.paymentMethodShortName) {
        case 'prepaid':
          return 'PREPAID';
        case 'credit_card':
          return 'CC3D';
        case 'pay_pal':
          return 'PAYPAL';
        case 'sofort':
          return 'SU';
        case 'direct_debit':
          return 'DD';
      }
      return '';
    },
    days() {
      const days = [];
      for(let d = 1; d <= 31; d++) {
        days.push(d);
      }
      return days;
    },
    months() {
      const months = [];
      for(let m = 1; m <= 12; m++) {
        months.push(m);
      }
      return months;
    }
  },
  data() {
    return {
      options: {
        sandbox: config.crefopaySandbox || false,
        libUrl: {
          live: 'https://api.crefopay.de/libs/3.0/secure-fields.js',
          sandbox: 'https://sandbox.crefopay.de/libs/3.0/secure-fields.js'
        },
        apiEndpoint: {
          live: 'https://api.crefopay.de/secureFields/',
          sandbox: 'https://sandbox.crefopay.de/secureFields/'
        },
        placeholder: {
          accountHolder: '',
          number: '0123456789101112',
          cvv: '000'
        }
      },
      errors: [],
      showErrors: {
        accountHolder: false,
        number: false,
        validity: false,
        cvv: false
      },
      secureFieldsClientInstance: null,
      isLoading: false,
      validationCallback: null,
      publicToken: null,
      transactionId: null,
    };
  },
  mounted() {
    this.initPaymentMethod();
  },
  methods: {
    _paymentRegisteredCallback(data) {
      if(typeof data.errorDetails !== "undefined") {
        for(let e in data.errorDetails) {
          if(typeof data.errorDetails[e].message === "string" && data.errorDetails[e].message === "field.invalid" && typeof data.errorDetails[e].field === "string") {
            switch (data.errorDetails[e].field) {
              case 'paymentInstrument.accountHolder':
                this.showErrors.accountHolder = true;
                break;
              case 'paymentInstrument.validity':
                this.showErrors.validity = true;
                break;
              case 'paymentInstrument.number':
                this.showErrors.number = true;
                break;
              case 'paymentInstrument.cvv':
                this.showErrors.cvv = true;
                break;
            }
          }
        }
      }
      if(data.resultCode === 0) {
        if(typeof this.validationCallback === "function") {
          const crefopayData = {
            orderId: data.orderNo,
            paymentInstrumentId: data.paymentInstrumentId
          };
          this.validationCallback({
            paymentMethod: this.crefopayPaymentMethod,
            crefopay: crefopayData
          });
        }
      } else {
        easyLizeApp.apiClient.logError('crefopay.paymentRegisteredCallback', data);
      }
    },
    _crefoPayOnInit(data) {
    },
    _resetErrors() {
      this.showErrors.accountHolder = false;
      this.showErrors.number = false;
      this.showErrors.validity = false;
      this.showErrors.cvv = false;
    },
    initPaymentMethod() {
      this.isLoading = true;
      if(document.getElementById('crefopay-lib-script')) {
        this.createPaymentMethod(this.updatePaymentMethod.bind(this));
      } else {
        this.isLoading = true;
        const script = document.createElement('script');
        let scriptLoaded = false;
        window.jQuery = jQuery;
        window.$ = jQuery;
        script.type = 'text/javascript';
        script.id = 'crefopay-lib-script';
        script.onload = () => {
          if(scriptLoaded) {
            return;
          }
          this.createPaymentMethod(this.updatePaymentMethod.bind(this));
          scriptLoaded = true;
        };
        script.addEventListener('load', () => {
          if(scriptLoaded) {
            return;
          }
          this.createPaymentMethod(this.updatePaymentMethod.bind(this));
          scriptLoaded = true;
        });
        script.src = this.options.sandbox ? this.options.libUrl.sandbox : this.options.libUrl.live;
        document.head.appendChild(script);
      }
    },
    updatePaymentMethod(result) {
      this.errors = result.errors || [];
      this.$emit('validity-change', { result })
      this.isLoading = false;
    },
    createPaymentMethod(callback) {
      easyLizeApp.apiClient.createCrefoPayOrder((result) => {
        if(!result.success) {
          callback({ success: false, errors: result.errors || [] })
        }
        this.secureFieldsClientInstance = new SecureFieldsClient(
            result.publicToken,
            result.transactionId,
            this._paymentRegisteredCallback.bind(this),
            this._crefoPayOnInit.bind(this),
            {
              url: this.options.sandbox ? this.options.apiEndpoint.sandbox : this.options.apiEndpoint.live,
              placeholders: this.options.placeholder
            }
        );
        callback({ success: true });
      });
    },
    validate(callback) {
      this._resetErrors();
      this.validationCallback = callback;
      this.secureFieldsClientInstance.registerPayment();
    }
  }
}
</script>

<style scoped lang="scss">
.crefopay-instrument-form--element {
  .form-control {
    padding: 0;
    height: 32px;
  }
}
</style>