<template>
  <customer-page size="full">
    <template v-slot:page-headline>{{ $t(`Nachrichten`) }}</template>
    <template v-slot:page-content>
      <ul id="message-types" class="clear-list h-list mb-5">
        <li :class="folder === 'inbox' ? 'active' : ''"><a href="#" @click="onChangeFolder($event, 'inbox')">{{ $t(`Empfangen`) }}</a></li>
        <li :class="folder === 'sent' ? 'active' : ''"><a href="#" @click="onChangeFolder($event, 'sent')">{{ $t(`Gesendet`) }}</a></li>
        <li :class="folder === 'trash' ? 'active' : ''"><a href="#" @click="onChangeFolder($event, 'trash')">{{ $t(`Papierkorb`) }}</a></li>
        <li><a :href="$getUrl($route, '/messages/create')">{{ $t(`Nachricht schreiben`) }}</a></li>
      </ul>

      <customer-message-table :folder="folder" />
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import CustomerMessageTable from "../../component/customer-message/customer-message-table";
export default {
  name: "customer-messages-list",
  components: {CustomerMessageTable, CustomerPage},
  data() {
    return {
      folder: 'inbox'
    };
  },
  created() {
    this.folder = this.$route.query.folder || 'inbox';;
  },
  methods: {
    onChangeFolder(e, folder) {
      e.preventDefault();
      this.folder = folder;
    }
  }
}
</script>

<style scoped>

</style>