<template>
  <div class="circle-bar container" v-if="showCalculationHelp">

    <a href="#circle-step-1" id="circle-step-1" :class="getStepClass('step1')" v-if="showCalculationHelp">

      <div class="circle-step-box"></div>
      <div class="circle-step-line flex-fill"></div>
      <div class="circle-step-text ms-3 ms-lg-0" v-html="step1Text"></div>
    </a>

    <a href="#circle-step-2" id="circle-step-2" :class="getStepClass('step2')" v-if="showCalculationHelp">
      <div class="circle-step-box"></div>
      <div class="circle-step-line flex-fill"></div>
      <div class="circle-step-text ms-3 ms-lg-0" v-html="step2Text"></div>
    </a>

    <a href="#circle-step-3" id="circle-step-3" :class="getStepClass('step3')">
      <div class="circle-step-box"></div>
      <div class="circle-step-line flex-fill"></div>
      <div class="circle-step-text ms-3 ms-lg-0" v-html="step3Text"></div>
    </a>

    <a href="#circle-step-4" id="circle-step-4" :class="getStepClass('step4')">
      <div class="circle-step-box"></div>
      <div class="circle-step-line flex-fill"></div>
      <div class="circle-step-text ms-3 ms-lg-0" v-html="step4Text"></div>
    </a>

    <a href="#circle-step-5" id="circle-step-5" :class="getStepClass('step5')">
      <div class="circle-step-box"></div>
      <div class="circle-step-line flex-fill"></div>
      <div class="circle-step-text ms-3 ms-lg-0" v-html="step5Text"></div>
    </a>

    <div class="stopfen"></div>
  </div>
</template>

<script>
export default {
  name: "wizard-circle-bar",
  props: {
    currentStep: {
      type: String,
      default: 'step1'
    },
    showCalculationHelp: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      checkedSteps: {
        'step1': [],
        'step2': [ 'step1' ],
        'step3': [ 'step1', 'step2' ],
        'step4': [ 'step1', 'step2', 'step3' ],
        'step5': [ 'step1', 'step2', 'step3', 'step4' ],
      }
    };
  },
  computed: {
    step1Text() {
      return this.$t(`Sortiment <span class="d-none d-lg-inline-block"><br></span>wählen`);
    },
    step2Text() {
      return this.$t('Verpackungs&shy;mengen <span class=" d-none d-lg-inline-block"><br></span>ermitteln');
    },
    step3Text() {
      return this.$t('Preis&shy;berechnung');
    },
    step4Text() {
      return this.$t('Kontakt&shy;daten <span class="d-none d-lg-inline-block"><br></span>angeben');
    },
    step5Text() {
      return this.$t('Lizenzierung <span class="d-none d-lg-inline-block"><br></span>abschlie&szlig;en');
    }
  },
  methods: {
    getStepClass(step) {
      if(step === this.currentStep) {
        return 'circle-step active';
      }
      if(this.checkedSteps[this.currentStep].indexOf(step) >= 0) {
        return 'circle-step check';
      }
      return 'circle-step disabled';
    }
  }
}
</script>
