<template>
<Field v-model="modelValue" ref="select" class="form-control" @change="updateValue" :disabled="isLoading" :rules="rules" as="select">
  <option value="">{{ $t(`Wählen Sie eine Anrede aus`) }}</option>
  <option v-for="salutation in salutations" :key="salutation.id" :value="salutation.id">{{ salutation.name }}</option>
</Field>
</template>

<script>
import {Field} from 'vee-validate';
export default {
  name: "salutation-select",
  emits: ['update:modelValue'],
  components: { Field },
  props: {
    modelValue: { required: true, default: null },
    rules: {
      type: [Object, String, Function],
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      salutations: []
    }
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getSalutations((salutations) => {
      this.salutations = salutations;
      this.isLoading = false;
    })
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>
