import qs from "qs";
/*const queryToString = function(query) {
    const params = Object.keys(query).reduce((params, key) => {
        const val = query[key];
        if (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') {
            params.set(key, val);
            return params;
        }
        if(typeof val === 'object') {
            return queryToString(val);
        }
        if(val === null) {
            return params;
        }
        const valCopy = val.slice();
        const first = valCopy.shift();
        if (first) {
            params.set(key, first);
            valCopy.forEach((val) => params.append(key, val));
        }
        return params;
    }, new URLSearchParams());
    return params.toString();
}*/
const queryToString = (data) => {
    return qs.stringify(data);
};
export default queryToString;